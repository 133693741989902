import axios from 'axios';
import { fellowActions } from './fellowReducers';
import { toast } from 'react-toastify';
import { useSettings } from '../../components/hooks/useSettings';

export const getFellowById = (id) => async (dispatch) => {
  try {
    dispatch(fellowActions.EditRequest());

    const response = await axios.get(
      process.env.REACT_APP_API + '/fellow/getOne/' + id,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    dispatch(fellowActions.EditSuccess(response?.data));
  } catch (e) {
    dispatch(fellowActions.EditFail(e.message));
  }
};

export const editFellow = (data) => async (dispatch) => {
  try {
    dispatch(fellowActions.EditRequest());

    const response = await axios.put(
      process.env.REACT_APP_API + '/updateMyInfo',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    dispatch(fellowActions.EditSuccess(response?.data));
    // console.log(response?.data);
    // toast.success('Profile updated successfully');
    window.location.reload();
  } catch (e) {
    dispatch(fellowActions.EditFail(e.message));
    toast.error('Failed to update profile');
  }
};

export const getFellows = (filter) => async (dispatch) => {
  try {
    dispatch(fellowActions.getFellowsRequest());

    // Format country and interests arrays as comma-separated strings
    const countryParams = filter.fellow.country
      ? filter.fellow.country.join(',')
      : '';
    const interestsParams = filter.fellow.interests
      ? filter.fellow.interests.join(',')
      : '';
    const expertiseParams = filter.fellow.expertise
      ? filter.fellow.expertise.join(',')
      : '';

      const isFavParams = filter.isFavorite
      ? '1'
      : '0';


    // Construct the query parameters
    const queryParams = {
      country_of_residency: countryParams,
      field_of_interest: interestsParams,
      expertise: expertiseParams,
      page: filter.fellow.page,
      limit: filter.fellow.limit,
      search: filter.search,
      is_favorite: isFavParams
    };

    const response = await axios.get(
      process.env.REACT_APP_API + '/fellow/getAll',
      {
        params: queryParams,
      },
    );

    dispatch(fellowActions.getFellowsSuccess(response?.data));
  } catch (e) {
    dispatch(fellowActions.getFellowsFail(e.message));
  }
};


export const deleteCv = () => async (dispatch) => {
  try {
    dispatch(fellowActions.DeleteCvRequest());
    const response = await axios.put(
      process.env.REACT_APP_API + '/fellow/deleteCV',
    )
    dispatch(fellowActions.DeleteCvSuccess(response?.data));
  } catch (e) {
    dispatch(fellowActions.DeleteCvFail(e.message));
  }
}
