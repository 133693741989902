export const theme = {
  colors: {
    primary: '#74AF85',
    primaryBlue: '#A9CFBC',
    secondary: '#0C3944',
    lightBlue: '#C2E2EB',
    lightGreen: '#ECFFF1',
    white: '#fff',
    black: '#1C1C1C',
    disabled: '#858585',
    pink: '#f06a80',
    danger: '#eb3232',
    dark: '#676767',
    red: '#d95e70',
    border: '#d9d9d9',
    purple: '#dc6de8',
    orange: '#fac360',
    yellow: '#e4b80a',
    darkOrange: '#ee7f1d',
    cyan: '#1fa79d',
    grey: '#e6e6e6',
    darkGrey: '#cac6c6',
    darkerGrey: '#504e4e',
    mediumBlue: '#6d8acc',
    darkerBlue: '#242E6C',
    darkBlue: '#1fa8a0',
    bottomGrey: '#707070',
  },
  fontSizes: {
    xxs: '0.6em',
    xs: '0.8rem',
    s: '1rem',
    m: '1.2rem',
    l: '1.4rem',
    xl: '1.6rem',
    xxl: '1.8rem',
    xxxl: '2rem',
    xxxxl: '2.2rem',
  },
  fontWeights: {
    bold: 'bold',
    regular: 'regular',
    light: 200,
  },
  shadows: {
    default: '0px 0px 6px rgba(0, 0, 0, 0.1) !important',
    filterItem: '0px 5px 6px rgba(0, 0, 0, 0.2) !important',
    CardItem: '0px 5px 6px rgba(0, 0, 0, 0.01) !important',
  },
};
