import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import ProfileBackground from '../../../common/ProfileBackground';
import ProfileCameraContainer from '../../../common/ProfileCameraContainer';
import Form from '../../../common/Providers/Form';
import { useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import SimpleInput from '../../../common/Inputs/SimpleInput';
import TextareaInput from '../../../common/Inputs/TextareaInput';
import SubmitButton from '../../../common/Buttons/SubmitButton';
import SelectInput from '../../../common/Inputs/SelectInput';
import { editCso } from '../../../../redux/cso/csoActions';
import { useDispatch, useSelector } from 'react-redux';
import CheckBoxInput from '../../../common/Inputs/CheckBoxInput';
import { toast } from 'react-toastify';
import { useSettings } from '../../../hooks/useSettings';

const CsoProfileFormContainer = styled.div`
  width: 100%;
  overflow: auto;
  height: 100vh;
  .cover-image-profile {
    width: 100%;
    position: relative;
    margin-bottom: 4.1rem;
    .center-camera {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .logo-container {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      left: 3rem;
      .image-content {
        position: relative;
        width: 150px;
        height: 150px;
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 7;
        }
        img {
          position: absolute;
          width: 150px;
          height: 150px;
          border-radius: 50%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
        .black-background-image {
          position: absolute;
          top: 0;
          left: 0;
          background-color: ${({ theme }) => theme.colors.black};
          width: 150px;
          height: 150px;
          border-radius: 50%;
          opacity: 0.6;
        }
        .logo-camera-container {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 6;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .main-content {
    padding: 0 2rem 2rem 2rem;
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: start;
    .form-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      .submit-button-container {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-bottom: 1rem;
      }
    }
  }
`;
const StyledScrollbarContainer = styled.div`
  width: 100%;
  max-height: 700px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.darkerGrey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.darkGrey};
  }
`;

function isValidUrl(url) {
  const urlPattern = /^(?:(?:https?|ftp):\/\/|www\.)[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(url);
}

const schema = z.object({
  organisation_name: z
    .string()
    .trim()
    .min(1)
    .refine(
      (value) => {
        const words = value.trim().split(/\s+/);
        return words.length <= 10;
      },
      {
        message: 'Organization name must have at most 10 words.',
      },
    ),
  field_of_interest: z.string(),
  organisation_social_media: z.string().trim().min(1).refine(value => isValidUrl(value), {
    message: 'Invalid URL',
  }),
  country_of_operation_id: z.string().trim().min(1),
  number_of_employees: z.string().trim().min(1),
  type_of_organisation: z.string().trim().min(1),
  organisation_logo: z.any(),
  organisation_description: z
    .string()
    .trim()
    .min(1)
    .refine(
      (value) => {
        const words = value.trim().split(/\s+/);
        return words.length <= 200;
      },
      {
        message: 'Organization description must have at most 200 words.',
      },
    ),
  ideal_fellow: z
    .string()
    .trim()
    .min(1)
    .refine(
      (value) => {
        const words = value.trim().split(/\s+/);
        return words.length <= 200;
      },
      {
        message: 'Ideal fellow must have at most 200 words.',
      },
    ),

    motivation_for_fellowship: z
    .string()
    .trim()
    .min(1)
    .refine(
      (value) => {
        const words = value.trim().split(/\s+/);
        return words.length <= 200;
      },
      {
        message: 'Organization name must have at most 200 words.',
      },
    ),

  field_of_work_if_other: z.string().optional().nullable(),
});

const Inputs = ({
  organisation_name,
  organisation_social_media,
  country,
  country_of_operation_id,
  organisation_description,
  ideal_fellow,
  motivation_for_fellowship,
  number_of_employees,
  EmployeesList,
  type_of_organisation,
  typeOrganisationList,
  field_of_work_if_other,
}) => {
  const method = useFormContext();
  const { user } = useSelector((store) => store.authReducer);
  return (
    <>
      <SimpleInput
        type="text"
        name="organisation_name"
        label="Organization Name:"
        value={organisation_name}
        limit={'10 words'}
        placeholder={'Enter organization name'}
        error={method?.formState?.errors?.organisation_name?.message}
        required={true}
        disabled={false}
        errorType={'inline'}
      />
      <SimpleInput
        type="text"
        name="organisation_social_media"
        label="Organization Website:"
        value={organisation_social_media}
        placeholder={'Enter organization website'}
        error={method?.formState?.errors?.organisation_social_media?.message}
        required={true}
        disabled={false}
        errorType={'inline'}
      />
      <SelectInput
        type="text"
        name="country_of_operation_id"
        label="Country of Operation:"
        value={country_of_operation_id}
        placeholder="Select country of operation"
        error={method?.formState?.errors?.country_of_operation_id?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        options={country.map((countryItem) => ({
          label: countryItem.name,
          value: countryItem.id,
        }))}
      />

      <TextareaInput
        type="text"
        name="organisation_description"
        label="Organization Description:"
        value={organisation_description}
        placeholder="Organization Description:"
        error={method?.formState?.errors?.organisation_description?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        limit={'200 words'}
      />

      <TextareaInput
        type="text"
        name="ideal_fellow"
        label="Ideal Fellow:"
        value={ideal_fellow}
        placeholder="Organization Description:"
        error={method?.formState?.errors?.ideal_fellow?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        limit={'200 words'}
      />

      <TextareaInput
        type="text"
        name="motivation_for_fellowship"
        label="Motivation for Fellowship:"
        value={motivation_for_fellowship}
        placeholder="Organization Description:"
        error={method?.formState?.errors?.motivation_for_fellowship?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        limit={"200 words"}
      />
      <SelectInput
        type="text"
        name="number_of_employees"
        label="Number of Employees:"
        value={number_of_employees}
        placeholder="Select number of employees"
        error={method?.formState?.errors?.number_of_employees?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        options={EmployeesList.map((item) => ({
          label: item,
          value: item,
        }))}
      />
      <SelectInput
        type="text"
        name="type_of_organisation"
        label="Type Of Organisation:"
        value={type_of_organisation}
        placeholder="Select type of organisation"
        error={method?.formState?.errors?.type_of_organisation?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        options={typeOrganisationList.map((item) => ({
          label: item,
          value: item,
        }))}
      />
      <CheckBoxInput
        label="Interests"
        name="interests"
        interests={user?.field_of_interest}
        selectedInterests={user?.info?.csos_field_of_interests}
        field_of_work_if_other={field_of_work_if_other}
      />
      <div className="submit-button-container">
        <SubmitButton type="submit" text="Save" />
      </div>
    </>
  );
};

const FileInput = ({ setImageData }) => {
  const method = useFormContext();
  const { setIsFormEdited } = useSettings();
  return (
    <input
      type="file"
      onChange={(e) => {
        setImageData(URL.createObjectURL(e.target.files[0]));
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          method.setValue('organisation_logo', file);
          setIsFormEdited(true);
        } else {
          method.setValue('organisation_logo', null);
          setIsFormEdited(true);
        }
      }}
    />
  );
};

const CsoProfileForm = ({
  organisation_name,
  organisation_social_media,
  country,
  country_of_operation_id,
  organisation_description,
  ideal_fellow,
  organisation_logo,
  number_of_employees,
  EmployeesList,
  typeOrganisationList,
  type_of_organisation,
  fieldOfInterests,
  field_of_work_if_other,
  motivation_for_fellowship
}) => {
  const { user } = useSelector((store) => store.authReducer);
  const dispatch = useDispatch();
  const [otherId, setOtherId] = useState(() => {
    for (let i = 0; i < user?.field_of_interest.length; i++) {
      if (user?.field_of_interest[i].name === 'Other') {
        return user?.field_of_interest[i].id;
      }
    }
  });

  const submit = (data) => {
    if (data?.field_of_work_if_other?.length === 0 &&
      data?.field_of_interest.length === 1 &&
      otherId == data?.field_of_interest[0]
    ) {
      toast.error('Other interest is required');
      return;
    }
    if (data?.field_of_interest.length === 0) {
      toast.error('Please select at least one interest');
    } else {
      dispatch(editCso(data));
    }

    // console.log(data);
  };
  const [imageData, setImageData] = useState(
    process.env.REACT_APP_API + '/user/' + organisation_logo,
  );

  return (
    <CsoProfileFormContainer>
      <Form
        option={{ resolver: zodResolver(schema) }}
        {...useForm()}
        onSubmit={submit}
      >
        <StyledScrollbarContainer>
          <div className="cover-image-profile">
            <ProfileBackground height={'170px'} />
            <div className="logo-container">
              <div className="image-content">
                <img src={imageData} alt="ImageNotFound" />
                <FileInput setImageData={setImageData} />
                <div className="logo-camera-container">
                  <ProfileCameraContainer text={'Upload Picture'} />
                </div>
                <div className="black-background-image" />
              </div>
            </div>
          </div>
          <div className="main-content">
            <h1 className="title">Basic Information</h1>
            <div className="form-container">
              <Inputs
                organisation_description={organisation_description}
                organisation_name={organisation_name}
                organisation_social_media={organisation_social_media}
                country={country}
                country_of_operation_id={country_of_operation_id}
                ideal_fellow={ideal_fellow}
                number_of_employees={number_of_employees}
                EmployeesList={EmployeesList}
                typeOrganisationList={typeOrganisationList}
                type_of_organisation={type_of_organisation}
                fieldOfInterests={user.info.field_of_interest}
                field_of_work_if_other={field_of_work_if_other}
                motivation_for_fellowship={motivation_for_fellowship}
              />
            </div>
          </div>
        </StyledScrollbarContainer>
      </Form>
    </CsoProfileFormContainer>
  );
};

export default CsoProfileForm;
