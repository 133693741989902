import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';
import SVG from '../../SVG';
import FilterIcon from '../../../icons/FilterIcon';
import { useTheme } from '@emotion/react';

const PrimaryButtonContainer = styled.button`
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: 0.8rem 3rem;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  ${({ theme }) =>
    mobile(`
      font-size: ${theme.fontSizes.xs};
      padding: 0.6rem 2rem;
      border-radius: 2rem;
    `)}
`;

const PrimaryButton = ({ text, handleClick, className, type }) => {
  const theme = useTheme();
  return (
    <PrimaryButtonContainer
      className={className}
      type="button"
      onClick={handleClick}
    >
      {type === 'filter-btn' && (
        <SVG
          icon={<FilterIcon />}
          width={'20px'}
          height={'20px'}
          color={theme.colors.primary}
        />
      )}
      <span>{text}</span>
    </PrimaryButtonContainer>
  );
};

export default PrimaryButton;
