import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from './../../hooks/useBreakpoint';
import Header from '../../common/Header';
import SearchCardsWrapper from '../../common/Providers/SearchCardsWrapper';
import InvitationCard from '../../common/InvitationCard';
import BurgerIcon from '../../common/Burger';
import { useTheme } from '@emotion/react';
import PrimaryWrapper from '../../common/Providers/PrimaryWrapper';
import {
  getInvitations,
  getConnections,
} from '../../../redux/connect/connectActions';
import { useAuth } from './../../hooks/useAuth';

const InvitationPageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: 100vh;
  overflow-y: auto;
  ${mobile(`
    padding: 0;
  `)}
  .center-content {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    ${mobile(`
      border-radius: 0;
      padding: 1rem;
    `)}

    .header-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      .left-container {
      }
      .right-container {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        position: relative;

        input {
          width: 100%;
          font-size: ${({ theme }) => theme.fontSizes.m};
          color: ${({ theme }) => theme.colors.secondary};
          padding: 0.2rem 1rem;
          border-radius: 0.5rem;
          border: 2px solid ${({ theme }) => theme.colors.grey};
          outline: none;
        }
        .search-icon {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    p {
      color: ${({ theme }) => theme.colors.secondary};
      width: 100%;
    }
  }
`;

const Invitations = ({ handleSidebarToggle }) => {
  const { isMobile } = useBreakpoint();
  const { user } = useSelector((store) => store.authReducer);
  const [showInvitationsResult, setShowInvitationsResult] = useState('');
  const theme = useTheme();
  const dispatch = useDispatch();
  const { invitations, connections } = useSelector(
    (store) => store.invitationsReducer,
  );
  const [role, setRole] = useState('');
  const { isLoggedIn } = useAuth();
  const [islogedin, setislogedin] = useState(false);

  useEffect(() => {
    dispatch(getInvitations());
    dispatch(getConnections());
  }, []);

  const getrole = () => {
    const role = localStorage.getItem('role');
    setRole(role);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setislogedin(false);
    } else {
      setislogedin(true);
      getrole();
    }
  }, [isLoggedIn]);

  // console.log("connections", connections);
  // console.log("invitations", invitations);

  return (
    <InvitationPageContainer>
      <div className="center-content">
        {isMobile ? (
          <div className="header-container">
            <div className="left-container">
              <BurgerIcon
                handleSidebarToggle={handleSidebarToggle}
                color={theme.colors.black}
                isStroke={true}
                backgroundColor={theme.colors.white}
              />
            </div>
            <div className="right-container">
              <PrimaryWrapper padding={'1rem'} borderNone={false}>
                <Header
                  CssEditSection={'invitations'}
                  TitleName={'Invitations'}
                  SubName={
                    user?.info?.first_name
                      ? user?.info?.first_name + ' ' + user?.info?.last_name
                      : user?.info?.organisation_name
                  }
                />
              </PrimaryWrapper>
            </div>
          </div>
        ) : (
          <Header
            fixHeight={'50px'}
            TitleName={'Invitations'}
            SubName={
              user?.info?.first_name
                ? user?.info?.first_name + ' ' + user?.info?.last_name
                : user?.info?.organisation_name
            }
            active={true}
          />
        )}
        {/* {showInvitationsResult.length > 0 && <p>Results for {showInvitationsResult} in X</p>} */}
        <SearchCardsWrapper>
          <p>Pending Connections</p>
          {invitations?.data &&
            invitations?.data?.map((invitation, index) => {
              return (
                <InvitationCard
                  key={index}
                  id={invitation?.id}
                  status={invitation?.type}
                  name={
                    role == 3
                      ? invitation?.cso?.organisation_name
                      : invitation?.fellow?.first_name +
                        ' ' +
                        invitation?.fellow?.last_name
                  }
                  type={
                    role == 3
                      ? invitation?.cso?.type_of_organisation
                      : invitation?.fellow?.current_occupation
                  }
                  image={
                    role == 3
                      ? process.env.REACT_APP_API +
                        '/user/' +
                        invitation?.cso?.organisation_logo
                      : process.env.REACT_APP_API +
                        '/user/' +
                        invitation?.fellow?.picture
                  }


                  onClick={() => {
                    if(invitation?.cso != undefined ){
                      window.location.href = `/view/cso/${invitation?.cso?.user_id}`
                    }else{
                      window.location.href = `/view/fellow/${invitation?.fellow?.user_id}`
                    }
                  }}

                />
              );
            })}
        </SearchCardsWrapper>
        <SearchCardsWrapper>
          <p>Connection History</p>
          {connections?.data &&
            connections?.data?.map((connection, index) => {
              return (
                <InvitationCard
                  key={index}
                  id={connection?.id}
                  status={connection?.type}
                  onClick={() => {
                    if(connection?.received_cso!= undefined || connection?.cso!= undefined){
                      window.location.href = `/view/cso/${(connection?.received_cso != undefined ? connection?.received_cso?.user_id : connection?.cso?.user_id)}`
                    }else{
                      window.location.href = `/view/fellow/${(connection?.received_fellow != undefined ? connection?.received_fellow?.user_id : connection?.fellow?.user_id)}`
                    }
                  }}
                  name={
                    role == 3
                      ? (connection?.received_cso != undefined ? connection?.received_cso?.organisation_name: connection?.cso?.organisation_name)
                      :(connection?.received_fellow != undefined ? (connection?.received_fellow?.first_name +
                        ' ' +
                        connection?.received_fellow?.last_name): (connection?.fellow?.first_name +
                        ' ' +
                        connection?.fellow?.last_name))
                  }
                  type={
                    role == 3
                      ? (connection?.received_cso != undefined ? connection?.received_cso?.type_of_organisation: connection?.cso?.type_of_organisation)
                      : (connection?.received_fellow != undefined ? connection?.received_fellow?.current_occupation : connection?.fellow?.current_occupation)
                  }
                  image={
                    role == 3
                      ? process.env.REACT_APP_API +
                        '/user/' +
                        (connection?.received_cso != undefined ? connection?.received_cso?.organisation_logo : connection?.cso?.organisation_logo)
                      : process.env.REACT_APP_API +
                        '/user/' +
                        (connection?.received_fellow != undefined ? connection?.received_fellow?.picture : connection?.fellow?.picture)
                  }
                />
              );
            })}
        </SearchCardsWrapper>
      </div>
    </InvitationPageContainer>
  );
};

export default Invitations;
