const LockIcon = () => {
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3018 9.58497C15.3018 9.4545 15.3027 9.35715 15.3018 9.25979C15.2916 8.21456 15.3269 7.16587 15.2628 6.1241C15.0823 3.18806 12.9229 0.648872 10.2049 0.117115C6.81358 -0.5461 3.62041 1.67087 2.86132 5.23454C2.75785 5.72034 2.71934 6.22788 2.70495 6.72702C2.6799 7.57457 2.69799 8.42311 2.69799 9.27165V9.58596C2.38573 9.58596 2.08831 9.58596 1.7909 9.58596C1.30185 9.58497 0.88426 9.77425 0.539051 10.1365C0.192915 10.4992 0.00639153 10.948 0.00546455 11.4639C0.000360489 14.6841 -0.0038147 17.9038 0.00639343 21.124C0.00963974 22.1816 0.818373 22.999 1.81549 22.9995C6.60154 23.0005 11.3876 23.0005 16.1736 22.9995C17.1977 22.9995 17.9934 22.1742 17.9957 21.0865C18.0018 17.891 18.0018 14.696 17.9957 11.5005C17.9939 10.4226 17.2093 9.5998 16.1973 9.58547C15.9054 9.58151 15.6141 9.58497 15.3023 9.58497H15.3018ZM12.568 9.56916H5.4295C5.41929 9.51479 5.40584 9.47526 5.40584 9.43572C5.40769 8.45573 5.39099 7.47524 5.41883 6.49574C5.44713 5.51525 5.81043 4.66473 6.47533 3.99064C7.55921 2.89155 8.84168 2.5718 10.2434 3.12777C11.6521 3.68671 12.4562 4.82139 12.5684 6.42259C12.639 7.43027 12.5889 8.44733 12.5916 9.46043C12.5916 9.49157 12.5782 9.5232 12.568 9.56965V9.56916Z"
        fill="#74AF85"
      />
    </svg>
  );
};

export default LockIcon;
