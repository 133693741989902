import { FormProvider, useForm } from 'react-hook-form'; // Import FormProvider from react-hook-form

const Form = ({ children, onSubmit, option }) => {
  const methods = useForm(option); // Initialize useForm

  return (
    <FormProvider {...methods}>
      {' '}
      {/* Wrap every form with FormProvider */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {' '}
        {/* Pass handleSubmit method from useForm for form submission */}
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
