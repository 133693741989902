import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFav, deleteFav } from '../../../../../redux/fav/favActions';
import PrimaryConnectButton from '../../../../common/Buttons/PrimaryConnectButton';
import { addConnection } from '../../../../../redux/connect/connectActions';
import { mobile } from '../../../../../styles/media';
import HeartContainer from '../../../../common/HeartContainer';
import ItemTag from '../../../../common/ItemTag';
import PrimaryWrapper from '../../../../common/Providers/PrimaryWrapper';
import { Link } from 'react-router-dom';
import { favoriteTextTransform } from '../../../../utils/favoriteTextTransform';

const CardContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  /* justify-content: space-between;  */
  align-items: center;
  gap: 2rem;
  white-space: nowrap;
  ${mobile(`
        flex-wrap:wrap;
        gap:0.6rem;
        justify-content:space-between;
      
        `)}
  .connection_status {
    font-style: italic;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
  }
  .image-container {
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    ${mobile(`
            width: 85px;
            height: 85px;
        `)}
  }
  h4 {
    ${mobile(`
           font-size:0.7rem;
        `)}
  }
  .info-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 1rem;
    .flex {
      display: flex;
      gap: 0.5rem;
      h2 {
        ${mobile(`
           font-size:1rem;
        `)}
      }
      p {
        display: flex;
        align-items: end;
        ${mobile(`
           font-size:0.6rem;
        `)}
      }
      .current-occupation-text {
        ${({ isMargin }) =>
          isMargin
            ? `
            width: 300px;
            white-space: pre-wrap;
        `
            : ''}
      }
    }

    .tag {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      h2 {
        ${mobile(`
           font-size:1rem;
        `)}
      }
      p {
        display: flex;
        align-items: end;
        ${mobile(`
           font-size:0.6rem;
        `)}
      }
      span {
        display: flex;
        align-items: center;
      }
      ${mobile(`
           flex-direction: column;
           text-align: center;

        `)}
    }

    p {
      width: 100%;
    }
  }
  .right-container {
    display: flex;
    gap: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 0;
    margin: 1rem;
    ${mobile(`
      position: relative;
        `)}
  }
  .connect-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    ${mobile(`
          gap: 0.5rem ;
        `)}
  }
`;

function FellowCard({ fellow, connected_user }) {
  const dispatch = useDispatch();
  const [showAllFields, setShowAllFields] = useState(false);
  const [favItem, setFavItem] = useState(fellow?.favorites?.length > 0);
  const { fav, favLoading } = useSelector((state) => state.favReducer);

  const role = localStorage.getItem('role');

  const [active, setActive] = useState(false);
  const [notDisplayConnectButton, setNotDisplayConnectButton] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('');

  useEffect(() => {
    // Only update the state if the loading is complete
    if (!favLoading && fav?.length > 0) {
      setFavItem(true);
    }
  }, [favLoading, fav]);

  useEffect(() => {
    // Only update the state if the loading is complete
    if (fellow?.favorites?.length > 0 > 0) {
      setFavItem(true);
    } else {
      setFavItem(false);
    }
  }, [fellow]);

  useEffect(() => {
    if (connected_user?.length > 0) {
      connected_user.forEach((connection) => {
        if (connection?.id === fellow?.id) {
          setNotDisplayConnectButton(true);
          setConnectionStatus(connection?.type);
        }
      });
    }
  }, [connected_user, fellow?.id]);

  return (
    <Link
      to={`/view/fellow/${fellow?.user_id}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <PrimaryWrapper padding={'2rem'}>
        <CardContainer isMargin={role == 4 ? true : false}>
          <div className="image-container">
            <img
              src={`${process.env.REACT_APP_API}/user/${fellow?.picture}`}
              alt="ImageNotFound"
            />
          </div>
          <div className="info-container">
            <div className="flex">
              <h2>
                {fellow?.first_name} {fellow?.last_name}
              </h2>
              <p className="current-occupation-text">
                {fellow?.current_occupation}
              </p>
            </div>

            <div className="flex">
              <h4>Status: </h4>
              <p>Fellow</p>
            </div>
            <div className="flex">
              <h4>Organization name: </h4>
              <p>{fellow?.organisation_name}</p>
            </div>

            <div className="flex">
              <h4>Country of Residency: </h4>
              <p>{fellow?.country?.name}</p>
            </div>

            <h4>Fields of Interest</h4>
            <div className="tag">
              {fellow?.fellows_field_of_interests
                ?.slice(0, showAllFields ? undefined : 2)
                .map((item, index) => (
                  <>
                    {item?.field_of_interest?.name === 'Other' ? (
                      <ItemTag text={fellow?.field_of_work_if_other} />
                    ) : (
                      <ItemTag
                        key={index}
                        text={item?.field_of_interest?.name}
                      />
                    )}
                  </>
                ))}
              {fellow?.fellows_field_of_interests?.length > 2 &&
                !showAllFields && (
                  <span onClick={() => setShowAllFields(true)}>...</span>
                )}
            </div>
            <h4>Expertise</h4>
            <div className="tag">
              {fellow?.fellows_expertises?.slice(0, 2).map((item, index) => (
                <ItemTag key={index} text={item?.expertise?.name} />
              ))}
              {fellow?.fellows_expertises?.length > 2 && <span>...</span>}
            </div>
          </div>
          <div className="right-container">
            <div className="connect-section">
              {/* {fellow?.connects?.length == 0 && <ConnectButton id={fellow?.id} />}
          {fellow?.connects?.length !== 0 && (
            <div className="connection_status">Connected</div>
          )} */}
              {role == 4 && (
                <>
                  <HeartContainer
                    id={fellow?.id}
                    favorite={favItem ? false : true}
                    onClick={() => {
                      const token = localStorage.getItem('token');
                      if (!favItem) {
                        dispatch(addFav(fellow?.id, token));
                        setFavItem(!favItem);
                      } else {
                        dispatch(deleteFav(fellow?.id, token));
                        setFavItem(!favItem);
                      }
                    }}
                  />
                  {fellow?.connects.length > 0 || active ? (
                    <span className="connection_status">
                      {favoriteTextTransform(fellow?.connects[0]?.type) ||
                        (active && 'Pending')}
                    </span>
                  ) : !notDisplayConnectButton ? (
                    <PrimaryConnectButton
                      id={fellow?.id}
                      label={'Connect'}
                      onClick={() => {
                        if (!fellow?.connects.length > 0) {
                          const token = localStorage.getItem('token');
                          dispatch(addConnection(fellow?.id, token));
                          setActive(true);
                        }
                      }}
                    />
                  ) : (
                    <span className="connection_status">
                      {' '}
                      {connectionStatus === 'Accept'
                        ? 'Connected'
                        : connectionStatus === 'Reject'
                          ? 'Rejected'
                          : connectionStatus}
                    </span>
                  )}
                </>
              )}
            </div>
            {/* {fellow?.connects?.length == 0 && <ConnectButton id={fellow?.id} />}
          {fellow?.connects?.length !== 0 && (
            <div className="connection_status">Connected</div>
          )} */}
          </div>
        </CardContainer>
      </PrimaryWrapper>
    </Link>
  );
}

export default FellowCard;
