import React from 'react';
import Logo from '../../../assets/images/tabadol-logo.png';
import styled from '@emotion/styled';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 200px;
    height: 100px;
    object-fit: cover;
  }
  .line {
    width: 240px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.grey};
    border-radius: 4px;
    margin-top: 2rem;
    position: relative;
  }

  .line::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 30%;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    animation: loading 1.2s infinite linear;
  }

  @keyframes loading {
    0% {
      left: 0%;
    }
    50% {
      left: calc(100% - 30%);
    }
    100% {
      left: 0%;
    }
  }
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <img src={Logo} />
      <div className="line" />
    </LoadingContainer>
  );
};

export default Loading;
