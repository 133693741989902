import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';
import ProfileBackground from '../../../common/ProfileBackground';
import SVG from '../../../common/SVG';
import VerifiedIcon from '../../../icons/VerifiedIcon';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';
import WebsiteIcon from '../../../icons/WebsiteIcon';
import LinkedinIcon from '../../../icons/LinkedinIcon';
import FacebookIcon from '../../../icons/FacebookIcon';
import InstagramIcon from '../../../icons/InstagramIcon';
import HeartContainer from '../../../common/HeartContainer';
import ConnectButton from '../../../common/Buttons/ConnectButton';

const HeaderProfileContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  border-radius: 1rem;
  ${mobile(`
      border-radius: 0;
    `)}
  .main-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 1rem;
    .left-side {
      display: flex;
      justify-content: center;
      align-items: self-start;
      flex-direction: column;
      gap: 1rem;
      .avatar-container {
        position: relative;
        margin-bottom: 4rem;

        ${mobile(`
            margin-bottom:4rem;
            `)}
        img {
          position: absolute;
          top: -8rem;
          width: 200px;
          height: 200px;
          object-fit: cover;

          border-radius: 50%;
          margin-left: 2rem;
          z-index: 1;
          ${mobile(`
                width: 140px;
                height: 140px;
                margin-left: 1rem;
                top: -4rem;
              `)}
        }
      }
      .avatar-container::after {
        content: '';
        position: absolute;
          top: -8rem;
          width: 200px;
          height: 200px;
          border-radius: 50%;
          margin-left: 2rem;
          object-fit: cover;
          background-color: ${({ theme }) => theme.colors.white};

          ${mobile(`
                width: 140px;
                height: 140px;
                margin-left: 1rem;
                top: -4rem;
              `)}
      }
      .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        .title-section {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          ${mobile(`
            width: 170px;     
          `)}
          h1 {
            font-size: ${({ theme }) => theme.fontSizes.xxxxl};
            font-weight: ${({ theme }) => theme.fontWeights.bold};
            margin-left: 2rem;
            ${({ theme }) =>
              mobile(`
                font-size: ${theme.fontSizes.xl};
              `)}
            ${mobile(`
                  margin-left: 1rem;
              `)}
          }
          span {
            ${({ theme }) =>
              mobile(`
                  font-size: ${theme.fontSizes.xs};
                `)}
          }
        }

        .text-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          margin-top: 0.3rem;
          margin-left: 2rem;
          ${mobile(`
              margin-left: 1rem;
            `)}

          span {
            ${({ theme }) =>
              mobile(`
                  font-size: ${theme.fontSizes.xs};
                `)}
          }
        }
        a {
          text-decoration: none;
          color: ${({ theme }) => theme.colors.primary};
          font-style: italic;
          ${({ theme }) =>
            mobile(`
                  font-size: ${theme.fontSizes.xxs};
                `)}
        }
      }
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    margin-right: 2rem;
    gap: 1rem;
    height: 100%;
    ${mobile(`
        margin-right: 1rem;
      `)}
    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .connect-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      ${mobile(`
          gap: 0.5rem ;
        `)}
    }
  }
`;

const HeaderProfile = ({
  websiteUrl,
  instagramUrl,
  linkedinUrl,
  facebookUrl,
  isConnect,
  organisation_name,
  organisation_logo,
  myProfile,
  isVerified,
}) => {
  const theme = useTheme();
  return (
    <HeaderProfileContainer>
      <ProfileBackground height={'250px'} />
      <div className="main-content">
        <div className="left-side">
          <div className="avatar-container">
            <img
              src={process.env.REACT_APP_API + '/user/' + organisation_logo}
              alt="ImageNotFound"
            />
          </div>
          <div className="content">
            <div className="title-section">
              <h1>{organisation_name}</h1>
              {isVerified && (
                <SVG
                  width={'20px'}
                  height={'20px'}
                  icon={<VerifiedIcon />}
                  color={theme.colors.primary}
                />
              )}
            </div>
            <div className="text-wrapper"></div>
          </div>
        </div>
        <div className="right-side">
          <div className="links">
            {websiteUrl && (
              <div>
                {/* <span>Website, Social Media or any other link: </span> */}
                {/* <Link to={websiteUrl} target="_blank">
                  <SVG
                    width={'20px'}
                    height={'20px'}
                    color={theme.colors.primary}
                    icon={<WebsiteIcon />}
                  />
                </Link> */}
              </div>
            )}
            {linkedinUrl && (
              <Link to={linkedinUrl} target="_blank">
                <SVG
                  width={'20px'}
                  height={'20px'}
                  color={theme.colors.primary}
                  icon={<LinkedinIcon />}
                />
              </Link>
            )}
            {facebookUrl && (
              <Link to={facebookUrl}>
                <SVG
                  width={'20px'}
                  height={'20px'}
                  color={theme.colors.primary}
                  icon={<FacebookIcon />}
                />
              </Link>
            )}
            {instagramUrl && (
              <Link to={instagramUrl}>
                <SVG
                  width={'20px'}
                  height={'20px'}
                  color={theme.colors.primary}
                  icon={<InstagramIcon />}
                />
              </Link>
            )}
          </div>
          {!myProfile && (
            <div className="connect-section">
              <HeartContainer />
              <ConnectButton />
            </div>
          )}
        </div>
      </div>
    </HeaderProfileContainer>
  );
};

export default HeaderProfile;
