import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../styles/media';
import PrimaryWrapper from '../Providers/PrimaryWrapper';
import AcceptButton from '../Buttons/AcceptButton';
import RejectButton from '../Buttons/RejectButton';

const SingleCardContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  gap: 2rem;
  ${mobile(`
      flex-direction: column;
    `)}
  .image_container {
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-left: 2rem;
      object-fit: cover;
      ${mobile(`
            width: 110px;
            height: 110px;
        `)}
    }
  }
  .info_container {
  }

  .right_container {
    display: flex;
    gap: 0.5rem;
    position: absolute;
    right: 0;
    ${mobile(`
      position: relative;
      top: 0;
    `)}
  }

  .card-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    white-space:nowrap;
  }
  .flex {
    display: flex;
    gap: 1rem;
  }
  .connection_status {
    font-style: italic;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

function Card(props) {
  // console.log("props",props);
  return (
    <PrimaryWrapper padding={'1rem'}>
      <SingleCardContainer onClick={props.onClick}>
        <div className="image_container">
          <img src={props?.image} alt="ImageNotFound" />
        </div>
        <div className="info_container">
          <div className="card-info">
            <h2>{props?.name}</h2>

            <div className="flex">
              <h4>Current Occupation: </h4>
              <p>{props?.type}</p>
            </div>
          </div>
        </div>
        <div className="right_container">
          {props?.status == 'Pending' && <RejectButton id={props?.id} />}
          {props?.status == 'Pending' && <AcceptButton id={props?.id} />}
          {props?.status !== 'Pending' && (
            <div className="connection_status">
              {props?.status == 'Accept' ? 'Connected' : 'Rejected'}
            </div>
          )}
        </div>
      </SingleCardContainer>
    </PrimaryWrapper>
  );
}

export default Card;
