import styled from '@emotion/styled';
import React, { useState } from 'react';
import ItemTag from '../../../common/ItemTag';
import PrimaryButton from '../../../common/Buttons/PrimaryButton';
import SVG from '../../../common/SVG';
import TrashIcon from '../../../icons/TrashIcon';
import { useTheme } from '@emotion/react';
import { deleteCv } from '../../../../redux/fellow/fellowActions';
import { useDispatch, useSelector } from 'react-redux';

const IntersetedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .item {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin-bottom: 0.3rem;
  }
  div {
    display: flex;
  }
  .list {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  b {
    margin-right: 0.4rem;
  }
  a {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 0.5rem;
  }
  .cv {
    display: flex;
    gap: 1rem;
    .icon {
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
    }
    .icon:hover {
      background-color: ${({ theme }) => theme.colors.red};
      svg {
        path {
          fill: ${({ theme }) => theme.colors.black};
        }
      }
    }
  }
`;

const Interseted = ({
  fellows_field_of_interests,
  IntersetedList,
  firstName,
  CV,
  field_of_work_if_other
}) => {
  const theme = useTheme();
  console.log(CV);
  const dispatch = useDispatch();
  const {deleteCvSuccess} = useSelector((state) => state.fellowReducer);
  console.log("DeleteCvSuccess", deleteCvSuccess);
  const [active, setActive] = useState(false);
  return (
    <IntersetedContainer>
      <div className="item">
        <b>Field of interest</b>
        <div className="list">
        {fellows_field_of_interests &&
            fellows_field_of_interests.map((item) => (
              <>
                {item?.field_of_interest?.name === 'Other' ? (
                  <ItemTag key={item?.id} text={field_of_work_if_other} />
                ) : (<ItemTag key={item?.id} text={item?.field_of_interest?.name} />)}
                
              </>
            ))}
        </div>
      </div>
      <div className="item">
        <b>Expertise</b>
        <div className="list">
          {IntersetedList.map((interestName, index) => (
            <ItemTag key={index} text={interestName?.expertise?.name} />
          ))}
        </div>
      </div>
      {CV !== null && !active && (
        <div className="item">
          <b>Resume</b>
          <div className="cv">
            <a target="_blank" href={`${process.env.REACT_APP_API}/user/${CV}`}>
              <PrimaryButton text={`Download ${firstName}’s CV`} />
            </a>
            <div className="icon" onClick={() => {
                dispatch(deleteCv());
                setActive(true)
              }}>
              <SVG
                width={'20px'}
                height={'20px'}
                icon={<TrashIcon />}
                color={theme.colors.red}
              />
            </div>
          </div>
        </div>
      )}
    </IntersetedContainer>
  );
};

export default Interseted;
