import React from 'react';
import { useNavigate } from 'react-router-dom';
import found from '../../../assets/images/notFound.png';
import './index.css';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="not_found_div">
      <img className="not_found_image" src={found} alt="404" />
      <button className="navigate_button" onClick={() => navigate('/')}>
        GO TO HOME
      </button>
    </div>
  );
};

export default PageNotFound;
