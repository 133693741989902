import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './Register.css';
import register from '../../icons/auth/lock.png';
import pass from '../../icons/auth/pass.png';

import { useDispatch, useSelector } from 'react-redux';
import {
  signupFellow,
  signupOrg,
  signupVerifiedFellow,
  signupVerifiedOrg,
  login,
  loginVerifyOtp,
} from '../../../redux/auth/authactions';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useAuth } from '../../hooks/useAuth';
import Login from './Login';
import Validation from './Validation';

function Register() {
  const dispatch = useDispatch();

  const { id, errorSignUp, OrgLoading, fellowLoading } = useSelector(
    (store) => store.authReducer,
  );

  const [email, setEmail] = useState('');
  const [isFellow, setIsFellow] = useState(true);
  const { page } = useParams();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [otpVal, setOtpVal] = useState(0);
  const navigate = useNavigate();
  const { LoginFunction } = useAuth();

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  //handle submit sign up/in
  const handleSubmit = (e) => {
    e.preventDefault();
    //if email exists
    if (email) {
      //if in the signup page
      if (page === 'signUp') {
        if (isFellow) dispatch(signupFellow(email, navigate));
        //if the sign up as organization send email and navigate to otp verification for organization
        else dispatch(signupOrg(email, navigate));

        //if in the signin page  send email and navigate to otp verification
      } else if (page === 'login') {
        dispatch(login(email, navigate));
      }
    }
  };

  //checking changes in the otp vlaue
  useEffect(() => {
    //if the otp value is equal to 4 digits
    if (otpVal.toString().length === 4) {
      if (page.includes('fellow'))
        dispatch(signupVerifiedFellow(id, otpVal, navigate, LoginFunction));
      //if the page params is organization, dispatch the signup verification for organization
      else if (page.includes('org'))
        dispatch(signupVerifiedOrg(id, otpVal, navigate, LoginFunction));
      //verfication after login, dispatch the signin verification
      else dispatch(loginVerifyOtp(id, otpVal, navigate, LoginFunction));
    }
  }, [otpVal]);

  return (
    <div className="register_main_container">
      <div className="register_card_main">
        <div className="register_card">
          <img
            className={
              page.includes('verification')
                ? 'register_image2'
                : 'register_image'
            }
            src={page.includes('verification') ? pass : register}
          />

          {/*if login page*/}
          {page === 'login' ? (
            <Login
              email={email}
              setEmail={setEmail}
              page={page}
              isFellow={isFellow}
            />
          ) : page === 'signUp' ? (
            <div className="register_input_cont">
              {/*if signup page*/}

              <h1 className="register_title">{t('register.sign_up')}</h1>
              <p className="register_subtitle">
                {t('register.choose')}{' '}
                <span className="green_color">{t('register.create')}</span>
              </p>
              <form onSubmit={(e) => handleSubmit(e)}>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input_field"
                  placeholder={t('register.email_add')}
                />
                {errorSignUp && (
                  <div className="register_error">{errorSignUp}</div>
                )}

                <div className="register_button_cont">
                  <button
                    className="primary_button"
                    onClick={() => setIsFellow(true)}
                  >
                    {fellowLoading ? (
                      <>
                        <AiOutlineLoading3Quarters className="fa-spin" />{' '}
                        {t('register.signing')}
                      </>
                    ) : (
                      t('register.fellow')
                    )}
                  </button>
                  <button
                    className="register_button"
                    onClick={() => setIsFellow(false)}
                  >
                    {OrgLoading ? (
                      <>
                        <AiOutlineLoading3Quarters className="fa-spin" />{' '}
                        {t('register.signing')}
                      </>
                    ) : (
                      t('register.cso')
                    )}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            page.includes('verification') && (
              <Validation email={email} otpVal={otpVal} setOtpVal={setOtpVal} />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Register;
