import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitations: {},
  invitationsLoading: false,
  invitationsError: '',
  reject: {},
  rejectLoading: false,
  rejectError: '',
  accept: {},
  acceptLoading: false,
  acceptError: '',
  connections: {},
  connectionsLoading: false,
  connectionsError: '',
  connect: {},
  isConnected: false,
  connectLoading: false,
  connectError: '',
  disconnect: {},
  disconnectLoading: false,
  disconnectError: '',
  deleteconnectLoading: false,
  deleteconnectError: '',
  deleteconnectSuccess: {},

};

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    invitationsRequest(state) {
      state.invitationsLoading = true;
      state.invitations = {};
      state.invitationsError = '';
    },
    invitationsSuccess(state, action) {
      state.invitationsLoading = false;
      state.invitations = action.payload;
      state.invitationsError = false;
    },
    invitationsFail(state, action) {
      state.invitationsLoading = false;
      state.invitations = {};
      state.invitationsError = action.payload;
    },

    rejectInvitationRequest(state) {
      state.rejectLoading = true;
      state.reject = {};
      state.rejectError = '';
    },
    rejectInvitationSuccess(state, action) {
      state.rejectLoading = false;
      state.reject = action.payload;
      state.rejectError = false;
    },
    rejectInvitationFail(state, action) {
      state.rejectLoading = false;
      state.reject = {};
      state.rejectError = action.payload;
    },

    acceptInvitationRequest(state) {
      state.acceptLoading = true;
      state.accept = {};
      state.acceptError = '';
    },
    acceptInvitationSuccess(state, action) {
      state.acceptLoading = false;
      state.accept = action.payload;
      state.acceptError = false;
    },
    acceptInvitationFail(state, action) {
      state.acceptLoading = false;
      state.accept = {};
      state.acceptError = action.payload;
    },

    connectionsRequest(state) {
      state.connectionsLoading = true;
      state.connections = {};
      state.connectionsError = '';
    },
    connectionsSuccess(state, action) {
      state.connectionsLoading = false;
      state.connections = action.payload;
      state.connectionsError = false;
    },
    connectionsFail(state, action) {
      state.connectionsLoading = false;
      state.connections = {};
      state.connectionsError = action.payload;
    },

    connectRequest(state) {
      state.connectLoading = true;
      state.connect = {};
      state.connectError = '';
    },
    connectSuccess(state, action) {
      state.connectLoading = false;
      state.connect = action.payload;
      state.connectError = false;
      state.isConnected = true;
    },
    connectFail(state, action) {
      state.connectLoading = false;
      state.connect = {};
      state.connectError = action.payload;
      state.isConnected = false;
    },

    disconnectRequest(state) {
      state.disconnectLoading = true;
      state.disconnect = {};
      state.disconnectError = '';
    },
    disconnectSuccess(state, action) {
      state.disconnectLoading = false;
      state.disconnect = action.payload;
      state.disconnectError = false;
    },
    disconnectFail(state, action) {
      state.disconnectLoading = false;
      state.disconnect = {};
      state.disconnectError = action.payload;
    },
    deleteConnectionRequest(state) {
      state.deleteconnectLoading = true;
      state.deleteconnectSuccess = {};
      state.deleteconnectError = '';
    },
    deleteConnectionSuccess(state, action) {
      state.deleteconnectLoading = false;
      state.deleteconnectSuccess = action.payload;
      state.deleteconnectError = false;
    },
    deleteConnectionFail(state, action) {
      state.deleteconnectLoading = false;
      state.deleteconnectSuccess = {};
      state.deleteconnectError = action.payload;
    }
  },
});
export const invitationsActions = invitationsSlice.actions;
export default invitationsSlice;
