import { createContext, useState } from 'react';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [isFormEdited, setIsFormEdited] = useState(false);
  return (
    <SettingsContext.Provider value={{ isFormEdited, setIsFormEdited }}>
      {children}
    </SettingsContext.Provider>
  );
};
