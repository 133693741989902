import axios from 'axios';
import { authActions } from './authreducers';

export const myInfo = () => async (dispatch) => {
  try {
    dispatch(authActions.InfoRequest());
    const response = await axios.get(process.env.REACT_APP_API + '/myInfo');

    dispatch(authActions.InfoSuccess(response?.data?.data));
  } catch (e) {
    console.log(e.response.data.message);
    dispatch(authActions.InfoFail(e.response.data.message));
  }
};

export const login = (email, navigate) => async (dispatch) => {
  try {
    dispatch(authActions.loginRequest());
    const response = await axios.post(process.env.REACT_APP_API + '/login', {
      email,
    });
    dispatch(authActions.loginSuccess(response.data));
    navigate('/auth/verification');
  } catch (e) {
    dispatch(authActions.loginFail(e.response.data.message));
  }
};

export const signupFellow = (email, navigate) => async (dispatch) => {
  try {
    dispatch(authActions.signupFellowRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + '/signup/volunteer',
      {
        email,
      },
    );

    dispatch(authActions.signupFellowSuccess(data));
    navigate('/auth/verification_vol');
  } catch (e) {
    dispatch(authActions.signupFellowFail(e.response.data.message));
  }
};

export const signupOrg = (email, navigate) => async (dispatch) => {
  try {
    dispatch(authActions.signupOrgRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + '/signup/organization',
      {
        email,
      },
    );

    dispatch(authActions.signupOrgSuccess(data));
    navigate('/auth/verification_org');
  } catch (e) {
    dispatch(authActions.signupOrgFail(e.response.data.message));
  }
};
export const signupVerifiedFellow =
  (id, code, navigate, LoginFunction) => async (dispatch) => {
    try {
      code = parseInt(code);
      dispatch(authActions.verifyRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + '/verifiedSignUpVolunteer/' + id,
        {
          code,
        },
      );

      const token = data.token;
      const role = data.user.role;
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('id', data.user.id);

      LoginFunction(token);
      dispatch(authActions.verifySuccess(data.message));
      navigate('/');
    } catch (e) {
      dispatch(authActions.verifyFail(e.response.data.message));
    }
  };

export const signupVerifiedOrg =
  (id, code, navigate, LoginFunction) => async (dispatch) => {
    try {
      code = parseInt(code);
      dispatch(authActions.verifyRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + '/verifiedSignUpOrganization/' + id,
        {
          code,
        },
      );
      const token = data.token;
      const role = data.user.role;
      const email = data.user.email;
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('email', email);
      localStorage.setItem('id', data.user.id);

      LoginFunction(token);
      dispatch(authActions.verifySuccess(data.message));
    } catch (e) {
      dispatch(authActions.verifyFail(e.response.data.message));
    }
  };

export const loginVerifyOtp =
  (id, code, navigate, LoginFunction) => async (dispatch) => {
    try {
      code = parseInt(code);
      dispatch(authActions.verifyLoginRequest());
      const response = await axios.post(
        process.env.REACT_APP_API + '/verify/' + id,
        {
          code,
        },
      );
      const token = response.data.token;
      const role = response.data.data.user.role;
      const email = response.data.data.user.email;
      const UserId = response.data.data.user.id;
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('email', email);
      localStorage.setItem('id', UserId);

      LoginFunction(token);
      if (role === 3) {
        dispatch(authActions.verifyLoginSuccess(response?.data?.data));
        navigate('/fellow_profile');
      } else if (role === 4) {
        dispatch(authActions.verifyLoginSuccess(response?.data?.data));
        navigate('/cso_profile');
      } else {
        navigate('/');
      }
    } catch (e) {
      dispatch(authActions.verifyLoginFail(e.response.data.message));
    }
  };
