import styled from '@emotion/styled';
import React, { useState } from 'react';
import Form from '../../../common/Providers/Form';
import { useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import SubmitButton from '../../../common/Buttons/SubmitButton';
import { useDispatch } from 'react-redux';
import LoadingModel from '../../../common/LoadingModel';
import SimpleInput from '../../../common/Inputs/SimpleInput';
import SelectInput from '../../../common/Inputs/SelectInput';
import { editFellow } from '../../../../redux/fellow/fellowActions';
import TextareaInput from '../../../common/Inputs/TextareaInput';

const EntrepreneurshipFormContainer = styled.div`
  width: 100%;
  .main-content {
    padding: 2rem;
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: start;
    .title {
      padding-bottom: 2rem;
    }
    .form-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 2rem;
      .input-section {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        width: 100%;
        p {
          font-size: ${({ theme }) => theme.fontSizes.l};
          font-weight: ${({ theme }) => theme.fontWeights.bold};
        }
        .list {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 0.3rem;
        }
      }
      .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1rem;
      }
      .submit-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .upload-cv {
        position: relative;
        width: 100%;
        input {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    .error-message {
      width: 100%;
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;
const StyledScrollbarContainer = styled.div`
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.darkerGrey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.darkGrey};
  }
`;
function isValidUrl(url) {
  const urlPattern = /^(?:(?:https?|ftp):\/\/|www\.)[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(url);
}
const schema = z.object({
  entrepreneurship_project: z
  .string()
  .trim()
  .min(1)
  .refine(
    (value) => {
      const words = value.trim().split(/\s+/);
      return words.length <= 200;
    },
    {
      message: 'Project Description must have at most 200 words.',
    },
  ),
  stage_of_idea: z.string().min(1),
  organisation_social_media: z.string().nullable().optional().refine(value => value === '' || isValidUrl(value), {
    message: 'Invalid URL',
  }),

  
});

const Inputs = ({
  entrepreneurship_project,
  stage_of_idea,
  stageOfIdeaList,
  organisation_social_media,
}) => {
  const method = useFormContext();
  return (
    <>
      <TextareaInput
        name={'entrepreneurship_project'}
        type={'text'}
        error={method?.formState?.errors?.entrepreneurship_project?.message}
        placeholder={'Enter Project Description'}
        errorType={'inline'}
        label={'Project Description'}
        value={entrepreneurship_project}
        required={true}
        limit={"200 words"}
      />
      <SelectInput
        label={'Stage of Idea'}
        value={stage_of_idea}
        name={'stage_of_idea'}
        required={true}
        options={stageOfIdeaList.map((countryItem) => ({
          label: countryItem,
          value: countryItem,
        }))}
      />
      <SimpleInput
        name={'organisation_social_media'}
        type={'text'}
        error={method?.formState?.errors?.organisation_social_media?.message}
        placeholder={
          'Enter Organization social media or website or others Link'
        }
        errorType={'inline'}
        label={'Organization social media or website or others'}
        value={organisation_social_media}
        required={true}
      />
      <div className="submit-button-container">
        <SubmitButton text={'Save'} />
      </div>
    </>
  );
};

const EntrepreneurshipForm = ({
  entrepreneurship_project,
  stage_of_idea,
  organisation_social_media,
  stageOfIdeaList,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  const submit = (data) => {
    dispatch(editFellow(data));
  };

  return (
    <EntrepreneurshipFormContainer>
      {isLoading && <LoadingModel />}
      <Form
        option={{ resolver: zodResolver(schema) }}
        {...useForm()}
        onSubmit={submit}
      >
        <StyledScrollbarContainer>
          <div className="main-content">
            <h1 className="title">Entrepreneurship</h1>
            <div className="form-container">
              <Inputs
                entrepreneurship_project={entrepreneurship_project}
                stage_of_idea={stage_of_idea}
                organisation_social_media={organisation_social_media}
                stageOfIdeaList={stageOfIdeaList}
              />
            </div>
          </div>
        </StyledScrollbarContainer>
      </Form>
    </EntrepreneurshipFormContainer>
  );
};

export default EntrepreneurshipForm;
