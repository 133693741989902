import axios from 'axios';
import { invitationsActions } from './connectReducers';
import { toast } from 'react-toastify';

export const getInvitations = () => async (dispatch) => {
  try {
    dispatch(invitationsActions.invitationsRequest());

    const response = await axios.get(
      process.env.REACT_APP_API + '/connect/getInvitations',
    );

    dispatch(invitationsActions.invitationsSuccess(response?.data));
  } catch (e) {
    dispatch(invitationsActions.invitationsFail(e.message));
  }
};

export const rejectInvitation = (id, token) => async (dispatch) => {
  try {
    dispatch(invitationsActions.rejectInvitationRequest());
    let response = await axios.delete(
      process.env.REACT_APP_API + '/connect/reject/' + id,

      { Authorization: 'Bearer ' + token },
    );
    dispatch(invitationsActions.rejectInvitationSuccess(response.data.data));
  } catch (error) {
    dispatch(invitationsActions.rejectInvitationFail(error.message));
  }
};

export const acceptInvitation = (id, token) => async (dispatch) => {
  try {
    dispatch(invitationsActions.acceptInvitationRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + '/connect/accept/' + id,

      { Authorization: 'Bearer ' + token },
    );
    dispatch(invitationsActions.acceptInvitationSuccess(response.data.data));
  } catch (error) {
    dispatch(invitationsActions.acceptInvitationFail(error.message));
  }
};

export const getConnections = () => async (dispatch) => {
  try {
    dispatch(invitationsActions.connectionsRequest());

    const response = await axios.get(
      process.env.REACT_APP_API + '/connect/getConnections',
    );

    dispatch(invitationsActions.connectionsSuccess(response?.data));
  } catch (e) {
    dispatch(invitationsActions.connectionsFail(e.message));
  }
};

export const addConnection = (id, token) => async (dispatch) => {
  try {
    dispatch(invitationsActions.connectRequest());

    const role = localStorage.getItem('role');
    const data = role == 3 ? { cso_id: id } : { fellow_id: id };

    let response = await axios.post(
      process.env.REACT_APP_API + '/connect/create',
      data,

      { Authorization: 'Bearer ' + token },
    );
    dispatch(invitationsActions.connectSuccess(response.data.data));
  } catch (e) {
    toast.error(e?.response?.data?.message)
    dispatch(invitationsActions.connectFail(e.message));
  }
};


export const deleteConnection = (id) => async (dispatch) => {
  try {
    dispatch(invitationsActions.deleteConnectionRequest());
    let response = await axios.delete(
      process.env.REACT_APP_API + '/connect/delete/' + id,
    )
    dispatch(invitationsActions.deleteConnectionSuccess(response.data.data));
  } catch (error) {
    dispatch(invitationsActions.deleteConnectionFail(error.message));

  }
}