import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { mobile } from '../../../../styles/media';
import { useDispatch, useSelector } from 'react-redux';
import { addConnection } from '../../../../redux/connect/connectActions';

const ConnectButtonContainer = styled.div`
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: 0.8rem 3rem;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  ${({ theme }) =>
    mobile(`
      font-size: ${theme.fontSizes.xs};
      width:70%;
      padding: 0.35rem 0.95rem;
      border-radius: 1.9rem;
    `)}
`;

const ConnectButton = ({ id }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { connectLoading, connect } = useSelector(
    (store) => store.invitationsReducer,
  );
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    // Only update the state if the loading is complete
    if (!connectLoading && connect.length > 0) {
      setDisabled(true);
    }
  }, [connectLoading, connect]);

  const handleConnectToggle = () => {
    dispatch(addConnection(id, token));
    setDisabled(!disabled);
  };

  return (
    <>
      {!disabled && (
        <ConnectButtonContainer onClick={handleConnectToggle}>
          Connect
        </ConnectButtonContainer>
      )}
    </>
  );
};

export default ConnectButton;
