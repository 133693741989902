import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from './../../hooks/useBreakpoint';
import SearchHeader from '../../common/SearchHeader';
import BurgerIcon from '../../common/Burger';
import Filters from '../../common/Filters';
import CsoCard from '../../pages/Search/Cards/CsoCard';
import FellowCard from '../../pages/Search/Cards/FellowCard';
import { useTheme } from '@emotion/react';
import PrimaryWrapper from '../../common/Providers/PrimaryWrapper';
import SearchIcon from '../../icons/SearchIcon';
import SVG from '../../common/SVG';
import SearchCardsWrapper from '../../common/Providers/SearchCardsWrapper';
import { getCsos } from '../../../redux/cso/csoActions';
import { getFellows } from '../../../redux/fellow/fellowActions';
import PrimaryButton from '../../common/Buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import BellIcon from '../../icons/BellIcon';
import NotificationPopup from '../../common/Notification';
import {notificationCheck as notificationCheckApi} from '../../../redux/notifications/notificationsActions';

const SearchPageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  overflow-y: auto;
  height: 100vh;
  ${mobile(`
    padding: 0;
  `)}
  .center-content {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    ${mobile(`
      border-radius: 0;
      padding: 1rem;
    `)}

    .header-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      .left-container {
      }
      .right-container {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        position: relative;
        input {
          width: 100%;
          font-size: ${({ theme }) => theme.fontSizes.m};
          color: ${({ theme }) => theme.colors.secondary};
          padding: 0.2rem 1rem;
          border-radius: 0.5rem;
          border: 2px solid ${({ theme }) => theme.colors.grey};
          outline: none;
          ${mobile(`
          width: 90%; 
        `)}
        }
        .search-icon {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          ${mobile(`
           right: 0; 
           top: 35%;
           transform: none;
           margin-right: 80px; 
        `)}
        }
      }
    }
    .filter-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: start;
      gap: 1rem;
    }
    p {
      color: ${({ theme }) => theme.colors.secondary};
      width: 100%;
    }
    .filter-btn {
      width: 140px;
    }
  }
`;

const SearchPage = ({ handleSidebarToggle }) => {
  const { user } = useSelector((store) => store.authReducer);
  const { isMobile } = useBreakpoint();
  const [filterActive, setFilterActive] = useState(false);
  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
  const { cso } = useSelector((store) => store.csoReducer);
  const { fellows } = useSelector((store) => store.fellowReducer);
  const { allFav, allFavLoading } = useSelector((state) => state.favReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const storedFilters = localStorage.getItem('filters');
  let initialFilterState;
  try {
    initialFilterState = storedFilters
      ? JSON.parse(storedFilters)
      : {
        cso: {
          country: [],
          interests: [],
          type_of_organisation: '',
          page: 1,
        },
        fellow: {
          country: [],
          interests: [],
          expertise: [],
          page: 1,
        },
        search: '',
        filterStatus: localStorage.getItem('role') == '4' ? 'fellow' : 'cso',
        isFavorite: false,
      };
  } catch (error) {
    console.error('Error parsing filter state from localStorage:', error);
    // Handle the error or provide a default value
    initialFilterState = {
      cso: {
        country: [],
        interests: [],
        type_of_organisation: '',
        page: 1,
      },
      fellow: {
        country: [],
        interests: [],
        expertise: [],
        page: 1,
      },
      search: '',
      filterStatus: localStorage.getItem('role') == '4' ? 'fellow' : 'cso',
      isFavorite: false,
    };
  }

  const [filter, setFilter] = useState(initialFilterState);

  const [showSearchResult, setShowSearchResult] = useState('');

  const [csoConnections, setCsoConnections] = useState([]);
  const [fellowConnections, setFellowConnections] = useState([]);


  const [showNotificationPopup, setShowNotificationPopup] = useState(false);

  const { notificationCheck } = useSelector(
    (state) => state.notificationReducer,
  );
  const handleSearchChange = (e) => {
    setFilter({
      ...filter,
      search: e,
    });
  };

  useEffect(() => {
    dispatch(notificationCheckApi({ page: 1 }));
  }, [dispatch]);
  
  const handleShowSearchResultChange = () => {
    setShowSearchResult(filter.search);
  };

  const handleBellIconClick = () => {
    setShowNotificationPopup(!showNotificationPopup);
  };


  const theme = useTheme();

  const updateFilters = (filters) => {
    localStorage.setItem('filters', JSON.stringify(filters));
  };

  useEffect(() => {
    // Get filters from local storage when component mounts
    const storedFilters = localStorage.getItem('filters');
    if (storedFilters) {
      setFilter(JSON.parse(storedFilters));
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();

    // Serialize the filters object into query parameters
    Object.entries(filter).forEach(([key, value]) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        // Handle nested objects (e.g., cso, fellow)
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (Array.isArray(subValue) && subValue.length > 0) {
            subValue.forEach((item) => {
              params.append(`${key}[${subKey}][]`, item);
            });
          } else if (subValue !== '') {
            params.append(`${key}[${subKey}]`, subValue);
          }
        });
      } else if (Array.isArray(value) && value.length > 0) {
        // Handle arrays with values
        value.forEach((item) => {
          params.append(key, item);
        });
      } else if (value !== '') {
        // Handle non-empty strings
        params.append(key, value);
      }
    });

    // Update the URL with the serialized parameters
    navigate(`?${params.toString()}`, { replace: true });

    // Update filters in local storage when filter state changes
    updateFilters(filter);
  }, [filter, navigate]);

  useEffect(() => {
    if (filter.filterStatus === 'cso') {
      dispatch(getCsos(filter));
    } else if (filter.filterStatus === 'fellow') {
      dispatch(getFellows(filter));
    } else {
      setActive(false);
    }
  }, [dispatch, filter]);

  useEffect(() => {
    if (cso?.data?.connect != undefined) {
      setCsoConnections(cso?.data?.connect);
    }
  }, [cso]);

  useEffect(() => {
    if (fellows?.data?.connect != undefined) {
      setFellowConnections(fellows?.data?.connect);
    }
  }, [fellows]);


  console.log(fellows);

  return (
    (filter && <SearchPageContainer>
      <div className="center-content">
        {isMobile ? (
          <div className="header-container">
            <div className="left-container">
              <BurgerIcon
                handleSidebarToggle={handleSidebarToggle}
                color={theme.colors.black}
                isStroke={true}
                backgroundColor={theme.colors.white}
              />
            </div>
            <div className="right-container">
              <PrimaryWrapper padding={'1rem'} borderNone={false}>
                <input
                  type="text"
                  placeholder="Search"
                  value={filter.search}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleShowSearchResultChange();
                    }
                  }}
                />
                <div
                  className="search-icon"
                  onClick={handleShowSearchResultChange}
                >
                  <SVG
                    icon={<SearchIcon />}
                    width={'20px'}
                    height={'20px'}
                    color={theme.colors.grey}
                  />
                </div>
                <div className="notification-container">
            <div>
              {notificationCheck?.data &&
                notificationCheck?.data[0]?.is_read === false && (
                  <div className="un-opened-messages" />
                )}
            </div>
            <div>
              <SVG
                icon={<BellIcon />}
                width={'20px'}
                height={'20px'}
                color={theme.colors.secondary}
                onClick={() => handleBellIconClick()}
              />
              <NotificationPopup
                className="notification-popup"
                showNotificationPopup={showNotificationPopup}
                setShowNotificationPopup={setShowNotificationPopup}
              />
            </div>
          </div>
              </PrimaryWrapper>
            </div>
          </div>
        ) : (
          <SearchHeader
            TitleName={'My Profile'}
            SubName={
              localStorage.getItem('role') == '4'
                ? user?.info?.organisation_name
                : user?.info?.first_name + ' ' + user?.info?.last_name
            }
            active={active}
            handleSearchChange={handleSearchChange}
            search={filter.search}
            handleShowSearchResultChange={handleShowSearchResultChange}
          />
        )}
        {/* {showSearchResult.length > 0 && (
          <p>Results for {showSearchResult} in {filter.filterStatus}</p>
        )} */}
        {isMobile ? (
          <div className="filter-container">
            <PrimaryButton
              text="Filters"
              handleClick={() => setFilterActive(!filterActive)}
              className="filter-btn"
              type="filter-btn"
            />
            {filterActive && (
              <Filters
                filterStatus={filter.filterStatus}
                isFavorite={false}
                country={user.country}
                interests={user.field_of_interest}
                types={user.type_of_organisation}
                setFilter={setFilter}
                filter={filter}
                expertise={user.expertise}
              />
            )}
          </div>
        ) : (
          <Filters
            filterStatus={filter.filterStatus}
            isFavorite={false}
            country={user.country}
            interests={user.field_of_interest}
            types={user.type_of_organisation}
            setFilter={setFilter}
            filter={filter}
            expertise={user.expertise}
            resultNumber= {filter.filterStatus === 'cso' ? cso?.data?.data?.length : fellows?.data?.data?.length}
          />
        )}
        <SearchCardsWrapper>
          {
            <>
              {filter.filterStatus === 'cso' &&
                cso?.data?.data?.length > 0 &&
                cso?.data?.data?.map((cso, index) => (
                  <CsoCard
                    key={index}
                    cso={cso}
                    connected_user={csoConnections}
                  />
                ))}
              {filter.filterStatus === 'fellow' &&
                fellows?.data?.data?.length > 0 &&
                fellows?.data?.data?.map((fellow, index) => (
                  <FellowCard
                    key={index}
                    fellow={fellow}
                    connected_user={fellowConnections}
                  />
                ))}
            </>
          }
        </SearchCardsWrapper>
      </div>
    </SearchPageContainer>)
  );
};

export default SearchPage;
