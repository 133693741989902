import styled from '@emotion/styled';
import React from 'react';
import ItemTag from '../../../common/ItemTag';
import PrimaryButton from '../../../common/Buttons/PrimaryButton';

const PreselectionDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  .item {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  div {
    display: flex;
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  b {
    margin-right: 0.4rem;
  }
  .extra-item{
    margin-left: 2rem;
  }
`;

const PreselectionData = ({
  phone_number_contact,
  registration_certificate,
  founderName,
  founderNationality,
  country,
  oldYearBudget,
  nextYearBudget,
  sustainabilityWork,
  preIdentified_fellow,
  multaqaAttendance,
  programsAndGrantExperience,
  first_name_contact,
  last_name_contact,
  role_in_organisation_contact,
  email,
  country_of_residency_contact_id
}) => {
  return (
    <PreselectionDataContainer>
      <div className="item">
        <div>
          <b>Founder Name:</b>
        </div>
        <div>{founderName}</div>
      </div>
      <div className="item">
        <div>
          <b>Founder Nationality:</b>
        </div>
        <div>{founderNationality}</div>
      </div>
      <div className="item">
        <div>
          <b>Country of Registration:</b>
        </div>
        <div>{country}</div>
      </div>
      <div className="item">
        <div>
          <b>2023 Budget:</b>
        </div>
        <div>{oldYearBudget}</div>
      </div>
      <div className="item">
        <div>
          <b>2024 Budget:</b>
        </div>
        <div>{nextYearBudget}</div>
      </div>
      <div className="item">
        <div>
          <b>Sustainability Work:</b>
        </div>
        <div>{sustainabilityWork}</div>
      </div>
      <div className="item">
        <div>
          <b>Pre-Identified CSO:</b>
        </div>
        <div>{preIdentified_fellow}</div>
      </div>
      <div className="item">
        <div>
          <b>Multaqa Attendance:</b>
        </div>
        <div>{multaqaAttendance}</div>
      </div>
      <div className="item">
        <div>
          <b>Registration Certificate:</b>
        </div>
        <a
          href={`${process.env.REACT_APP_API}/user/${registration_certificate}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PrimaryButton text="Download" />
        </a>
      </div>
      <div className="item">
        <div>
          <b>Programs & Experience:</b>
        </div>
        <div>{programsAndGrantExperience}</div>
      </div>
      <div className="item">
        <h2>
          <b>Main Contact:</b>
        </h2>
        <div className="extra-item">
            <b> Full Name:</b>
            <div>{first_name_contact} {last_name_contact}</div>
          </div>
          <div className="extra-item">
            <b> Phone Number:</b>
            <div>{phone_number_contact}</div>
          </div>
          <div className="extra-item">
            <b> Email:</b>
            <div>{email}</div>
          </div>
          <div className="extra-item">
            <b>Role in Organization:</b>
            <div>{role_in_organisation_contact}</div>
          </div>
          <div className="extra-item">
            <b>Country of Residency:</b>
            <div>{country_of_residency_contact_id}</div>
          </div>
      </div>
    </PreselectionDataContainer>
  );
};

export default PreselectionData;
