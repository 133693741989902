const LeftArrow = () => {
  return (
    <svg
      width="40"
      height="23"
      viewBox="0 0 40 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.81895 10.1189C6.06627 10.1189 6.31468 10.1189 6.562 10.1189C17.0551 10.1189 27.5482 10.1189 38.0413 10.1189C39.1678 10.1189 39.8019 10.4967 39.9719 11.2598C40.1495 12.0559 39.4653 12.8125 38.5065 12.8732C38.2711 12.8879 38.0347 12.8815 37.7983 12.8815C27.3412 12.8815 16.8851 12.8815 6.42799 12.8815C6.2188 12.8815 6.0107 12.8815 5.63264 12.8815C5.91592 13.1251 6.08479 13.2731 6.25693 13.4184C9.0548 15.7792 11.8516 18.141 14.6549 20.4973C15.0918 20.865 15.3642 21.2769 15.2694 21.8073C15.1724 22.3525 14.8107 22.7441 14.1886 22.9179C13.5076 23.1091 12.9095 22.9611 12.3985 22.529C10.092 20.5772 7.77898 18.6292 5.46922 16.6802C3.85238 15.3159 2.23555 13.9498 0.617621 12.5864C-0.18317 11.9116 -0.215856 11.1182 0.581667 10.4443C4.51698 7.11542 8.45884 3.7911 12.404 0.471383C13.1372 -0.145492 14.1635 -0.152846 14.8281 0.425416C15.496 1.00552 15.4404 1.83108 14.6843 2.47002C11.8505 4.86489 9.01558 7.25792 6.17631 9.64911C6.03685 9.76678 5.85926 9.85136 5.6991 9.95065C5.73942 10.0067 5.77864 10.0628 5.81895 10.1189Z"
        fill="#0C3944"
      />
    </svg>
  );
};

export default LeftArrow;
