import React from 'react';

const FacebookIcon = () => {
  return (
    <svg
      width="39"
      height="40"
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_62_940)">
        <path
          d="M38.9811 20.0315C38.9811 20.0315 38.9623 20.0315 38.9543 20.0315C38.9543 17.0125 39.0402 13.9907 38.9355 10.9744C38.7395 5.21385 35.3598 0.791075 29.819 0.376268C23.0246 -0.131939 16.147 -0.112709 9.34724 0.34605C4.11789 0.703169 0.657604 4.44742 0.338152 9.78496C-0.0537809 16.3422 -0.137 22.9626 0.257618 29.5171C0.630759 35.7118 4.20111 39.2829 10.2385 39.6785C16.2893 40.0768 22.4045 40.118 28.4553 39.7225C35.3168 39.2719 38.812 35.0634 38.9704 27.9898C39.0294 25.3388 38.9811 22.6824 38.9811 20.0315ZM35.4725 28.5556C35.3544 33.363 32.9894 35.8821 27.6339 36.198C25.4219 36.3298 23.2045 36.3903 20.9844 36.4013V21.9902H24.7534V17.8201H20.971C20.971 17.1553 20.9603 16.5977 20.971 16.0428C21.014 14.3231 21.6555 13.7243 23.3629 13.8286C24.042 13.8698 24.7212 13.9632 25.4487 14.0402C25.6769 12.8095 25.8943 11.631 26.1547 10.2217C24.5413 10.0432 23.0005 9.68882 21.4757 9.74376C17.7362 9.87836 15.6745 11.5376 15.7631 15.6664C15.7792 16.3642 15.7631 17.0619 15.7631 17.8613H12.9149V22.0506H15.7658V36.3243C14.0048 36.2694 12.2465 36.1925 10.4908 36.0881C5.99702 35.8216 3.75548 33.0636 3.58368 28.4073C3.4763 25.5311 3.56489 22.644 3.56489 19.7623C3.5622 19.7623 3.55683 19.7623 3.55415 19.7623C3.55415 17.1086 3.50851 14.455 3.56489 11.8041C3.67227 6.66979 6.23594 3.82658 11.2317 3.69747C17.0329 3.54913 22.8448 3.58759 28.6486 3.73318C32.6323 3.83207 35.349 6.82088 35.4645 11.2656C35.6121 17.0262 35.6121 22.7951 35.4725 28.5529V28.5556Z"
          fill="#74AF85"
        />
      </g>
      <defs>
        <clipPath id="clip0_62_940">
          <rect width="39" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
