import './App.css';
import PageSwitch from './components/router/PageSwitch';
import { BrowserRouter as Router } from 'react-router-dom';
import Store from './redux/store';
import { Provider } from 'react-redux';
import { AuthProvider } from './components/context/AuthContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './translation/i18n';
import { ThemeProvider } from '@emotion/react';
import { theme } from './styles/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SettingsProvider } from './components/context/SettingsContext';

function App() {
  document.body.dir = document.documentElement.lang === 'ar' ? 'rtl' : 'ltr';

  return (
    <SettingsProvider>
      <Provider store={Store}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <I18nextProvider i18n={i18n}>
              <Router>
                <PageSwitch />
              </Router>
            </I18nextProvider>
          </AuthProvider>
          <ToastContainer />
        </ThemeProvider>
      </Provider>
    </SettingsProvider>
  );
}

export default App;
