import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './i18n/en.json';
import translationAr from './i18n/ar.json';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  en: {
    translation: translationEn,
  },
  ar: {
    translation: translationAr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: document.documentElement.lang,
  fallbackLng: 'en',
});

export default i18n;
