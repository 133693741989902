export const dateProfileFormatter = (originalDateString) => {
  // Convert the original date string into a Date object
  const originalDate = new Date(originalDateString);

  // Months array for formatting
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Extract year, month, and day from the Date object
  const year = originalDate.getFullYear();
  const month = months[originalDate.getMonth()];
  const day = originalDate.getDate();

  // Current year
  const currentYear = new Date().getFullYear();

  // Calculate the difference in years
  const differenceInYears = currentYear - year;

  // Format the result
  const formattedDate = `${day} ${month} ${year} (${differenceInYears} year)`;

  return formattedDate;
};
