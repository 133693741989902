import React from 'react';

const FilterIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_159_363)">
        <path
          d="M22.5512 0.0081094C28.7576 0.0081094 34.9639 0.021707 41.1702 -4.91728e-05C42.6878 -0.00548822 43.9525 0.380684 44.6732 1.82747C45.3776 3.24435 44.85 4.4355 43.9797 5.5913C39.7261 11.2343 35.4725 16.8773 31.276 22.5611C30.8082 23.1948 30.5063 24.1031 30.4873 24.8945C30.4002 28.2449 30.4193 31.6008 30.4655 34.9513C30.4873 36.4634 29.8998 37.5185 28.6324 38.3344C25.7414 40.2027 22.883 42.12 20.0355 44.0536C18.8008 44.8912 17.5715 45.4024 16.13 44.5839C14.7675 43.8088 14.4683 42.5578 14.471 41.1137C14.4901 35.8569 14.4411 30.6028 14.5064 25.3459C14.5227 24.0406 14.1474 23.0343 13.3668 22.0091C9.2465 16.6054 5.20232 11.1473 1.10374 5.72727C0.168168 4.48989 -0.422004 3.23619 0.369425 1.72957C1.12278 0.29094 2.43367 -0.00548822 3.93222 -4.91728e-05C10.1386 0.021707 16.3449 0.0081094 22.5512 0.0081094Z"
          fill="#0C3944"
        />
      </g>
      <defs>
        <clipPath id="clip0_159_363">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIcon;
