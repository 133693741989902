import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from './../../hooks/useBreakpoint';
import Header from '../../common/Header';
import HeaderProfile from './FellowContact';
import { dateProfileFormatter } from '../../utils/dateProfileFormatter';
import TextWrapper from '../../common/Providers/TextWrapper';
import BasicInfoFellow from './BasicInfoFellow';
import FellowProfileForm from './FellowProfileForm';
import Interseted from './Interesteds';
import InterestedForm from './InterestedForm';
import Entrepreneurship from './Entrepreneurship';
import EntrepreneurshipForm from './EntrepreneurshipForm';
import PreselectionData from './PreselectionData';
import BurgerIcon from '../../common/Burger';

const FellowProfilePageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  overflow-y: auto;
  ${mobile(`
    padding: 0;
  `)}
  .center-content {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    ${mobile(`
      border-radius: 0;
    `)}
    .burger-container {
      position: absolute;
      top: 0;
    }
  }
`;

const FellowProfilePage = ({ handleSidebarToggle }) => {
  const { user } = useSelector((store) => store.authReducer);
  const { isMobile } = useBreakpoint();
  return (
    <FellowProfilePageContainer>
      <div className="center-content">
        {!isMobile ? (
          <Header
            TitleName={'My Profile'}
            SubName={user?.info?.first_name + ' ' + user?.info?.last_name}
          />
        ) : (
          <div className="burger-container">
            <BurgerIcon handleSidebarToggle={handleSidebarToggle} />
          </div>
        )}
        <HeaderProfile
          profileImage={user?.info?.logo}
          isConnect={false}
          myProfile={true}
          isVerified={false}
          email={user?.info?.email_address}
          picture={user?.info?.picture}
          first_name={user?.info?.first_name}
          last_name={user?.info?.last_name}
          current_occupation={user?.info?.current_occupation}
        />
        <TextWrapper
          text={'Basic Information'}
          isEditable={true}
          FormComponent={
            <FellowProfileForm
              first_name={user?.info?.first_name}
              last_name={user?.info?.last_name}
              linkedIn={user?.info?.linkedIn}
              current_occupation={user?.info?.current_occupation}
              organisation_name={user?.info?.organisation_name}
              email_address={user?.info?.email_address}
              country_of_residency_id={user?.info?.country_of_residency_id}
              availability={user?.info?.availability}
              motivation_for_fellowship={user?.info?.motivation_for_fellowship}
              country={user?.country}
              availabilityList={user?.availability}
              picture={user?.info?.picture}
            />
          }
        >
          <BasicInfoFellow
            country={user?.country.map((item) => {
              if (item.id === user?.info?.country_of_residency_id)
                return item.name;
            })}
            availability={user?.info?.availability}
            motivation_for_fellowship={user?.info?.motivation_for_fellowship}
            organisation_name={user?.info?.organisation_name}
            role = {user?.role == '4' ? 'Cso' : 'Fellow'}
          linkedinUrl={user?.info?.linkedIn}

          />
        </TextWrapper>
        <TextWrapper
          isEditable={true}
          closeIconColor={'black'}
          FormComponent={
            <InterestedForm
              IntersetedList={user?.field_of_interest}
              expertiseList={user?.expertise}
              fellows_expertises={user?.info?.fellows_expertises}
              fellows_field_of_interests={
                user?.info?.fellows_field_of_interests
              }
              CV={user?.info?.CV}
              field_of_work_if_other={user?.info?.field_of_work_if_other}
            />
          }
          text={'Interests & Skills'}
        >
          <Interseted
            fellows_field_of_interests={user?.info?.fellows_field_of_interests}
            CV={user?.info?.CV}
            IntersetedList={user?.info?.fellows_expertises}
            firstName={user?.info?.first_name}
            field_of_work_if_other={user?.info?.field_of_work_if_other}
          />
        </TextWrapper>
        <TextWrapper
          isEditable={true}
          closeIconColor={'black'}
          text={'Entrepreneurship'}
          FormComponent={
            <EntrepreneurshipForm
              stageOfIdeaList={user?.stage_of_idea}
              entrepreneurship_project={user?.info?.entrepreneurship_project}
              stage_of_idea={user?.info?.stage_of_idea}
              organisation_social_media={user?.info?.organisation_social_media}
            />
          }
        >
          <Entrepreneurship
            entrepreneurship_project={user?.info?.entrepreneurship_project}
            stage_of_idea={user?.info?.stage_of_idea}
            organisation_social_media={user?.info?.organisation_social_media}
          />
        </TextWrapper>
        <TextWrapper text={'Private Data'}>
          <PreselectionData
            phone_number={user?.info?.phone_number}
            nationality={user?.info?.nationality}
            pre_identified_CSO={user?.info?.pre_identified_CSO}
            multaqa_attendance={user?.info?.multaqa_attendance}
            programs_and_grant_experience={
              user?.info?.programs_and_grant_experience
            }
            email_address={user?.info?.email_address}
            country={user?.country.map((item) => {
              if (item.id === user?.info?.country_of_residency_id)
                return item.name;
            })}
          />
        </TextWrapper>
        <br />
      </div>
    </FellowProfilePageContainer>
  );
};

export default FellowProfilePage;
