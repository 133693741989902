const BellIcon = () => {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5282 23.205C8.27556 23.205 5.0236 23.2056 1.77101 23.205C0.702652 23.205 -0.0336651 22.5672 0.0011864 21.6727C0.0176616 21.2555 0.217266 20.9111 0.564513 20.6347C0.97386 20.3084 1.37877 19.9771 1.79952 19.6627C1.99406 19.5174 2.06883 19.3608 2.0682 19.1312C2.05932 16.333 2.05996 13.5343 2.06376 10.736C2.06883 6.90483 4.96467 3.561 9.11707 2.58624C9.34138 2.53366 9.56697 2.47429 9.79636 2.45224C10.0207 2.43075 10.0707 2.3352 10.0612 2.15483C10.0473 1.88231 10.0524 1.60865 10.0562 1.33499C10.0663 0.593739 10.674 0.0209794 11.468 0.000624668C12.3107 -0.0208609 12.9596 0.514016 13.0065 1.27845C13.0211 1.51309 13.0084 1.74943 13.0116 1.98521C13.0185 2.50143 12.9564 2.38778 13.457 2.4839C15.4594 2.86781 17.1659 3.72102 18.539 5.0763C20.1479 6.66453 20.9799 8.54226 20.997 10.6835C21.0192 13.4817 21.0103 16.2804 20.9995 19.0787C20.9983 19.3523 21.0876 19.5355 21.3183 19.7051C21.7092 19.9918 22.0831 20.2982 22.4582 20.6024C23.0387 21.0729 23.2135 21.6841 22.9499 22.289C22.6996 22.8646 22.0964 23.205 21.3176 23.2056C18.0549 23.2067 14.7915 23.2056 11.5288 23.2056L11.5282 23.205Z"
        fill="#0C3944"
      />
      <path
        d="M8.29819 24.2443H14.7806C14.7647 25.1975 14.3484 25.9772 13.4961 26.541C12.417 27.2545 11.2416 27.3382 10.0376 26.8078C8.94896 26.3284 8.25446 25.2925 8.29755 24.2448L8.29819 24.2443Z"
        fill="#0C3944"
      />
    </svg>
  );
};

export default BellIcon;
