import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  fav: {},
  favLoading: false,
  favError: '',

  allFav: {},
  allFavLoading: false,
  allFavError: '',
};

const favSlice = createSlice({
  name: 'fav',
  initialState,
  reducers: {
    FavRequest(state) {
      state.favLoading = true;
      state.fav = {};
    },
    FavSuccess(state, action) {
      state.favLoading = false;
      state.fav = action.payload;
      state.favError = false;
    },
    FavFail(state, action) {
      state.favLoading = false;
      state.fav = {};
      state.favError = action.payload;
    },

    FavDeleteRequest(state) {
      state.favLoading = true;
      state.fav = {};
    },
    FavDeleteSuccess(state) {
      state.favLoading = false;
      state.favError = false;
    },
    FavDeleteFail(state, action) {
      state.favLoading = false;
      state.fav = {};
      state.favError = action.payload;
    },

    AllFavRequest(state) {
      state.allFavLoading = true;
      state.allFav = {};
    },
    AllFavSuccess(state, action) {
      state.allFavLoading = false;
      state.allFav = action.payload;
      state.allFavError = false;
    },
    AllFavFail(state, action) {
      state.allFavLoading = false;
      state.allFav = {};
      state.allFavError = action.payload;
    },
  },
});
export const favActions = favSlice.actions;
export default favSlice;
