import styled from '@emotion/styled';
import React from 'react';
import SVG from '../../../common/SVG';
import { useTheme } from '@emotion/react';
import WebsiteIcon from '../../../icons/WebsiteIcon';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCv } from '../../../../redux/fellow/fellowActions';

const EntrepreneurshipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  position: relative;
  .item {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin-bottom: 0.3rem;
  }
  .item-website {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  div {
    display: flex;
  }
  .list {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  b {
    margin-right: 0.4rem;
  }
  .social-media-container {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
    position: absolute;
    right: 0rem;
    bottom: 0rem;
  }
`;

const Entrepreneurship = ({
  entrepreneurship_project,
  stage_of_idea,
  organisation_social_media,
}) => {
  const theme = useTheme();
  return (
    <EntrepreneurshipContainer>
      <div className="item">
        <b>Project Description</b>
        <span>{entrepreneurship_project}</span>
      </div>
      <div className="item">
        <b>Idea Stage</b>
        <span>{stage_of_idea}</span>
      </div>
      {organisation_social_media !== null && organisation_social_media !== '' && (
        <div className="item-website">
          <b>Website, Social Media or any other link</b>
          <a href={organisation_social_media} target="_blank">
            <SVG
              width={'20px'}
              height={'20px'}
              icon={<WebsiteIcon />}
              color={theme.colors.primary}
            />
          </a>
        </div>
      )}

      {/* <div className="social-media-container">
        <a href={organisation_social_media} target='_blank'>
          <SVG
            width={'20px'}
            height={'20px'}
            icon={<WebsiteIcon />}
            color={theme.colors.primary}
          />
        </a>
      </div> */}
    </EntrepreneurshipContainer>
  );
};

export default Entrepreneurship;
