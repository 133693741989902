import React, { useEffect, useState } from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function Footer() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [Role, setRole] = useState('');
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setRole(role);
  }, [role]);

  return <></>;
}

export default Footer;
