import React from 'react';
import HeartIcon from '../../icons/HeartIcon';
import SVG from '../SVG';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from '../../hooks/useBreakpoint';

const HeartWrapper = styled.div`
  width: 45px;
  height: 45px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme, favorite }) =>
    favorite ? 'white' : theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${mobile(`
    width: 35px;
    height: 35px;
  `)}
`;

const HeartContainer = ({ id, favorite, onClick }) => {
  const { isMobile } = useBreakpoint();
  const theme = useTheme();

  return (
    <HeartWrapper
      favorite={favorite}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <SVG
        width={isMobile ? '15px' : '20px'}
        height={isMobile ? '15px' : '20px'}
        color={favorite ? theme.colors.primary : 'white'}
        icon={<HeartIcon />}
      />
    </HeartWrapper>
  );
};

export default HeartContainer;
