import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';
import { useTheme } from '@emotion/react';

const CardsWrapperContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 40px;
  gap: 1rem;
  flex-direction: column;
  max-height: auto;
  min-width: 20px;
  /* overflow-y: auto; */
`;

const CardsWrapper = ({ children }) => {
  const theme = useTheme();
  return <CardsWrapperContainer>{children}</CardsWrapperContainer>;
};

export default CardsWrapper;
