import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  cso: {},
  editLoading: false,
  editError: '',
};

const csoSlice = createSlice({
  name: 'cso',
  initialState,
  reducers: {
    EditRequest(state) {
      state.editLoading = true;
      state.cso = {};
    },
    EditSuccess(state, action) {
      state.editLoading = false;
      state.cso = action.payload;
      state.editError = false;
    },
    EditFail(state, action) {
      state.editLoading = false;
      state.cso = {};
      state.editError = action.payload;
    },

    GetRequest(state) {
      state.editLoading = true;
      state.cso = {};
    },
    GetSuccess(state, action) {
      state.editLoading = false;
      state.cso = action.payload;
      state.editError = false;
    },
    GetFail(state, action) {
      state.editLoading = false;
      state.cso = {};
      state.editError = action.payload;
    },
  },
});
export const csoActions = csoSlice.actions;
export default csoSlice;
