import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../styles/media';

const SVGContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};

    ${({ mobileWidth, mobileHeight, width, height }) =>
      mobile(`
            width:${mobileWidth ? mobileWidth : width};
            height:${mobileHeight ? mobileHeight : height};
        `)};

    path {
      ${({ isStroke, color }) =>
        isStroke ? `stroke: ${color};` : `fill: ${color};`}
    }
  }
`;

const SVG = ({
  width,
  height,
  color,
  mobileWidth,
  mobileHeight,
  icon,
  cursor,
  isStroke,
  backgroundColor,
  onClick,
}) => {
  return (
    <SVGContainer
      onClick={onClick}
      width={width}
      height={height}
      color={color}
      mobileHeight={mobileHeight}
      mobileWidth={mobileWidth}
      cursor={cursor}
      isStroke={isStroke}
      backgroundColor={backgroundColor}
    >
      {icon}
    </SVGContainer>
  );
};

export default SVG;
