import React from 'react';
import styled from '@emotion/styled';
import { mobile } from '../../../styles/media';
import SVG from '../SVG';
import BurgerIcon from '../../icons/BurgerIcon';
import { useTheme } from '@emotion/react';

const BurgerIconContainer = styled.div`
  display: none;
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ theme }) => theme.shadows.default};
  border-radius: 1rem;
  ${mobile(`
    display: block;
    padding: 1rem;
  `)}
`;

const Burger = ({ handleSidebarToggle, color, backgroundColor, isStroke }) => {
  const theme = useTheme();

  return (
    <BurgerIconContainer
      onClick={handleSidebarToggle}
      backgroundColor={backgroundColor}
    >
      <SVG
        icon={<BurgerIcon />}
        width={'30px'}
        height={'30px'}
        color={color}
        isStroke={isStroke}
        backgroundColor={backgroundColor}
      />
    </BurgerIconContainer>
  );
};

export default Burger;
