import styled from '@emotion/styled';
import React from 'react';
import { useSelector } from 'react-redux';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from './../../hooks/useBreakpoint';
import Header from '../../common/Header';

const HomePageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  overflow-y: auto;
  ${mobile(`
    padding: 0;
  `)}
  .center-content {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    ${mobile(`
      border-radius: 0;
    `)}
  }
`;

const HomePage = ({ handleSidebarToggle }) => {
  const { user } = useSelector((store) => store.authReducer);
  const { isMobile } = useBreakpoint();
  const role = localStorage.getItem('role');
  return (
    <HomePageContainer>
      <div className="center-content">
        {!isMobile && (
          <Header
            TitleName={
              role && role === '3'
                ? user?.info?.first_name + ' ' + user?.info?.last_name
                : user?.info?.organisation_name
            }
            SubName={
              role && role === '3'
                ? user?.info?.first_name + ' ' + user?.info?.last_name
                : user?.info?.organisation_name
            }
          />
        )}
      </div>
    </HomePageContainer>
  );
};

export default HomePage;
