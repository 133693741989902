import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { mobile } from '../../../../styles/media';

const PrimaryConnectButtonContainer = styled.div`
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: 0.8rem 3rem;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  ${({ theme }) =>
    mobile(`
      font-size: ${theme.fontSizes.xs};
      padding: 0.6rem 2rem;
      border-radius: 2rem;
    `)}
`;

const PrimaryConnectButton = ({ id, label, onClick }) => {
  return (
    <PrimaryConnectButtonContainer
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {label}
    </PrimaryConnectButtonContainer>
  );
};

export default PrimaryConnectButton;
