import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';
import { useTheme } from '@emotion/react';

const PrimaryWrapperContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.default};
  padding: ${({ padding }) => padding};
  ${mobile(`
       padding:1rem;
        `)}

  ${({ borderNone }) =>
    !borderNone
      ? mobile(`
      border-radius: 1rem;
    `)
      : mobile(`
    border-radius: 0rem;
    `)}

       ${({ isOverflow }) =>
    isOverflow === true &&
    mobile(`
      overflow: auto;
    `)}
`;

const PrimaryWrapper = ({ children, padding, borderNone, isOverflow }) => {
  return (
    <PrimaryWrapperContainer
      padding={padding}
      borderNone={borderNone}
      isOverflow={isOverflow}
    >
      {children}
    </PrimaryWrapperContainer>
  );
};

export default PrimaryWrapper;
