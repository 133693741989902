import axios from 'axios';
import { notificationsActions } from './notificationsReducers';

export const getNotifications = (data) => async (dispatch) => {
  try {
    dispatch(notificationsActions.getNotificationsRequest());
    //data it {page: 1, size: 10} add theme to params and if i did not send one do not add it
    const response = await axios.get(
      process.env.REACT_APP_API + '/notification',
      {
        params: data,
      },
    );
    dispatch(notificationsActions.getNotificationsSuccess(response.data));
  } catch (e) {
    dispatch(
      notificationsActions.getNotificationsFail(e.response.data.message),
    );
  }
};

export const notificationCheck = (data) => async (dispatch) => {
  const token = localStorage.getItem('token');
  try {
    dispatch(notificationsActions.checkNotificationsRequest());
    const response = await axios.get(
      process.env.REACT_APP_API + '/notification',
      {
        params: data,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    dispatch(notificationsActions.checkNotificationsSuccess(response.data));
  } catch (e) {
    dispatch(
      notificationsActions.checkNotificationsFail(e.response.data.message),
    );
  }
};
