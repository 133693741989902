import React from 'react';

const CameraIcon = () => {
  return (
    <svg
      width="79"
      height="63"
      viewBox="0 0 79 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.4721 62.9977C29.0598 62.9977 18.646 63.0038 8.2337 62.9946C3.99566 62.9915 0.728031 60.2261 0.069261 56.1079C0.00137831 55.6805 0.0029211 55.2409 0.0029211 54.8058C-0.000164475 43.1245 -0.00170726 31.4416 0.0029211 19.7602C0.00446389 15.4114 3.49116 11.8758 7.848 11.8282C10.8811 11.7943 13.9158 11.8189 16.9489 11.8143C18.8018 11.8113 20.0437 10.9151 20.6377 9.15186C21.3736 6.97054 22.0987 4.78614 22.8362 2.60636C23.4163 0.895432 24.6628 0.00230583 26.4926 0.00230583C35.1569 -0.000768611 43.8212 -0.000768611 52.487 0.00230583C54.3553 0.00230583 55.5849 0.89697 56.1774 2.66016C56.9025 4.81689 57.6291 6.97515 58.3542 9.13187C58.9559 10.9227 60.1933 11.8097 62.1079 11.8128C64.9882 11.8174 67.8671 11.8159 70.7475 11.8189C75.561 11.8266 79.0014 15.2669 78.9998 20.0431C78.9952 31.6737 79.006 43.3043 78.9998 54.935C78.9983 58.7242 76.4681 61.9539 72.8534 62.7625C72.1082 62.9285 71.326 62.9854 70.5608 62.9869C60.1994 63.0008 49.8381 62.9962 39.4767 62.9962L39.4721 62.9977ZM39.4782 55.1056C50.324 55.1194 59.2413 46.2696 59.246 35.4906C59.2491 24.6025 50.4691 15.8296 39.523 15.7819C28.6509 15.7358 19.8061 24.5348 19.7568 35.4461C19.709 46.1959 28.6309 55.0902 39.4798 55.1041L39.4782 55.1056ZM68.3423 25.182C69.8465 25.1789 71.09 23.9446 71.093 22.4504C71.0961 20.9685 69.848 19.708 68.3577 19.6895C66.8242 19.6711 65.5699 20.9254 65.5791 22.4657C65.5884 23.9553 66.8396 25.1851 68.3423 25.182Z"
        fill="white"
      />
      <path
        d="M39.4501 47.2257C32.8856 47.1919 27.6077 41.8777 27.6447 35.3429C27.6833 28.8697 33.0229 23.6201 39.5489 23.6431C46.0641 23.6662 51.4067 29.005 51.3558 35.4429C51.3049 42.0114 46.0008 47.2595 39.4501 47.2257Z"
        fill="white"
      />
    </svg>
  );
};

export default CameraIcon;
