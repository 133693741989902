import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  fellow: {},
  editLoading: false,
  editError: '',
  fellows: [],
  fellowsLoading: false,
  fellowsError: '',
  deleteCvLoading: false,
  deleteCvError: '',
  deleteCvSuccess: []
};

const fellowSlice = createSlice({
  name: 'fellow',
  initialState,
  reducers: {
    EditRequest(state) {
      state.editLoading = true;
      state.fellow = {};
    },
    EditSuccess(state, action) {
      state.editLoading = false;
      state.fellow = action.payload;
      state.editError = false;
    },
    EditFail(state, action) {
      state.editLoading = false;
      state.fellow = {};
      state.editError = action.payload;
    },
    getFellowsRequest(state) {
      state.fellowsLoading = true;
      state.fellow = {};
      state.fellowsError = false;
    },
    getFellowsSuccess(state, action) {
      state.fellowsLoading = false;
      state.fellows = action.payload;
      state.fellowsError = false;
    },
    getFellowsFail(state, action) {
      state.fellowsLoading = false;
      state.fellows = {};
      state.fellowsError = action.payload;
    },
    DeleteCvRequest(state) {
      state.deleteCvLoading = true;
    },
    DeleteCvSuccess(state, action) {
      state.deleteCvLoading = false;
      state.deleteCvSuccess = action.payload;
      state.deleteCvError = false;
    },
    DeleteCvFail(state, action) {
      state.deleteCvLoading = false;
      state.deleteCvSuccess = [];
      state.deleteCvError = action.payload;
    }
  },
});
export const fellowActions = fellowSlice.actions;
export default fellowSlice;
