import React from 'react';

const VerifiedIcon = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8302e-06 18.4918C0.00449121 8.26187 8.28591 -0.00747512 18.518 5.07075e-06C28.7455 0.00748526 37.015 8.29703 37 18.5269C36.985 28.7568 28.6969 37.0165 18.4633 36.9993C8.25074 36.9828 -0.00448755 28.7075 1.8302e-06 18.4918ZM27.2012 8.6299C22.9138 12.9183 18.619 17.2149 14.2628 21.5721C12.5441 19.8472 10.8419 18.138 9.17555 16.4654C7.85118 17.7924 6.52682 19.1194 5.26305 20.3858C8.27917 23.4085 11.3282 26.4642 14.3241 29.4675C19.9291 23.864 25.5655 18.2292 31.181 12.6153C29.8956 11.3287 28.5772 10.0077 27.2012 8.6299Z"
        fill="#74AF85"
      />
    </svg>
  );
};

export default VerifiedIcon;
