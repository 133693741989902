import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from './../../hooks/useBreakpoint';
import Header from '../../common/Header';
import HeaderProfile from './FellowContact';
import TextWrapper from '../../common/Providers/TextWrapper';
import BasicInfoFellow from './BasicInfoFellow';
import Interested from './Interesteds';
import Entrepreneurship from './Entrepreneurship';
import { useParams } from 'react-router-dom';
import { getFellowById } from '../../../redux/fellow/fellowActions';
import BurgerIcon from '../../common/Burger';

const FellowProfilePageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  overflow-y: auto;
  ${mobile(`
    padding: 0;
  `)}
  .center-content {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    ${mobile(`
      border-radius: 0;
    `)}
    .burger-container {
      position: absolute;
      top: 0;
    }
  }
`;

const FellowProfilePage = ({ handleSidebarToggle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector((store) => store.authReducer);

  useEffect(() => {
    dispatch(getFellowById(id));
  }, [dispatch, id]);

  const { fellow } = useSelector((store) => store.fellowReducer);
  const { isMobile } = useBreakpoint();
  const [notDisplayConnectButton, setNotDisplayConnectButton] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('');
  const [connectionId, setConnectionId] = useState('');
  const [senderId, setSenderId] = useState(null);

  // console.log('fellow', fellow);

  useEffect(() => {
    if (fellow?.data?.connect?.length > 0 && !(fellow?.data?.data.length > 1)) {
      fellow?.data?.connect?.forEach((connection) => {
        if (connection?.id === fellow?.data?.data?.fellows[0]?.id) {
          setNotDisplayConnectButton(true);
          setConnectionStatus(connection?.type);
          setConnectionId(connection?.connect_id);
          setSenderId(connection?.id);
        }
      });
    }
  }, [fellow]);

  return (
    <FellowProfilePageContainer>
      {fellow?.data?.data?.fellows &&
        fellow?.data?.data?.fellows.length > 0 && (
          <div className="center-content">
            {!isMobile ? (
              <Header
                TitleName={
                  fellow?.data?.data?.fellows[0]?.first_name +
                  ' ' +
                  fellow?.data?.data?.fellows[0]?.last_name
                }
                SubName={
                  localStorage.getItem('role') == '4'
                    ? user?.info?.organisation_name
                    : user?.info?.first_name + ' ' + user?.info?.last_name
                }
              />
            ) : (
              <div className="burger-container">
                <BurgerIcon handleSidebarToggle={handleSidebarToggle} />
              </div>
            )}
            <HeaderProfile
              profileImage={fellow?.data?.data?.fellows[0]?.logo}
              isConnect={
                fellow?.data?.data?.fellows[0]?.connects?.length > 0
                  ? true
                  : false
              }
              myProfile={
                localStorage.getItem('role') == fellow?.data?.data?.role
                  ? true
                  : false
              }
              isVerified={false}
              email={fellow?.data?.data?.fellows[0]?.email_address}
              connects={
                fellow?.data?.data?.fellows[0]?.connects?.length > 0 ||
                notDisplayConnectButton
                  ? true
                  : false
              }
              connectsStatus={
                fellow?.data?.data?.fellows[0]?.connects[0]?.type ||
                connectionStatus
              }
              connectionsId={
                fellow?.data?.data?.fellows[0]?.connects[0]?.id || connectionId
              }
              picture={fellow?.data?.data?.fellows[0]?.picture}
              first_name={fellow?.data?.data?.fellows[0]?.first_name}
              last_name={fellow?.data?.data?.fellows[0]?.last_name}
              current_occupation={
                fellow?.data?.data?.fellows[0]?.current_occupation
              }
              id={fellow?.data?.data?.fellows[0]?.id}
              favorite={
                fellow?.data?.data?.fellows[0]?.favorites?.length > 0
                  ? true
                  : false
              }
              favoriteId={fellow?.data?.data?.fellows[0]?.favorites[0]?.id}
              user_id={id}
              sender_id={senderId}
              role={fellow?.data?.data?.role}
              isPending={fellow?.data?.data?.fellows[0]?.connects[0]?.type}
            />
            <TextWrapper text={'Basic Information'}>
              <BasicInfoFellow
                country={fellow?.country?.map((item) => {
                  if (
                    item.id ===
                    fellow?.data?.data?.fellows[0]?.country_of_residency_id
                  )
                    return item.name;
                })}
                availability={fellow?.data?.data?.fellows[0]?.availability}
                motivation_for_fellowship={
                  fellow?.data?.data?.fellows[0]?.motivation_for_fellowship
                }
                role={
                  fellow?.data?.data?.fellows[0]?.role == '4' ? 'Cso' : 'Fellow'
                }
                organisation_name={
                  fellow?.data?.data?.fellows[0]?.organisation_name
                }
                linkedinUrl={fellow?.data?.data?.fellows[0]?.linkedIn}
              />
            </TextWrapper>

            <TextWrapper text={'Interests & Skills'}>
              <Interested
                fellows_field_of_interests={
                  fellow?.data?.data?.fellows[0]?.fellows_field_of_interests
                }
                CV={fellow?.data?.data?.fellows[0]?.CV}
                IntersetedList={
                  fellow?.data?.data?.fellows[0]?.fellows_expertises
                }
                firstName={fellow?.data?.data?.fellows[0]?.first_name}
                fellows_expertises={
                  fellow?.data?.data?.fellows[0]?.fellows_expertises
                }
                field_of_work_if_other={
                  fellow?.data?.data?.fellows[0]?.field_of_work_if_other
                }
              />
            </TextWrapper>

            <TextWrapper text={'Entrepreneurship'}>
              <Entrepreneurship
                entrepreneurship_project={
                  fellow?.data?.data?.fellows[0]?.entrepreneurship_project
                }
                stage_of_idea={fellow?.data?.data?.fellows[0]?.stage_of_idea}
                organisation_social_media={
                  fellow?.data?.data?.fellows[0]?.organisation_social_media
                }
              />
            </TextWrapper>
            <br />
          </div>
        )}
    </FellowProfilePageContainer>
  );
};

export default FellowProfilePage;
