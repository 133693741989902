import React from 'react';

const HeartIcon = () => {
  return (
    <svg
      width="37"
      height="34"
      viewBox="0 0 37 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5054 5.13954C19.8968 2.52791 21.9557 0.91907 24.6745 0.304744C27.8176 -0.405368 30.7499 0.0747409 33.2961 2.28312C35.2065 3.94045 36.3769 6.08438 36.822 8.64398C37.4504 12.2608 36.3697 15.411 34.2888 18.2237C32.4087 20.765 30.149 22.9319 27.8944 25.0895C25.2457 27.6242 22.5477 30.1022 19.8665 32.5998C19.0099 33.398 18.0615 33.4346 17.238 32.6867C13.1172 28.9463 9.00825 25.1912 5.17956 21.1132C4.1823 20.0507 3.24335 18.9232 2.32794 17.7808C1.0263 16.1572 0.365944 14.2362 0.100794 12.1443C-0.173324 9.97788 0.105279 7.8978 1.01508 5.94011C2.44846 2.85547 4.71821 0.790766 7.96279 0.203637C11.2825 -0.39709 14.3426 0.318343 16.8484 2.85015C17.4202 3.42782 17.8406 4.17163 18.3283 4.84095C18.3888 4.92372 18.4348 5.01833 18.506 5.13895L18.5054 5.13954Z"
        fill="white"
      />
    </svg>
  );
};

export default HeartIcon;
