import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../styles/media';

const ProfileBackgroundContainer = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '200px')};
  background: linear-gradient(to right, #0c3944, #74af85);
  border-radius: 15px;
  ${mobile(`
      border-radius: 0;
      height: 170px;
    `)}
`;

const ProfileBackground = ({ height }) => {
  return <ProfileBackgroundContainer height={height} />;
};

export default ProfileBackground;
