import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import Header from '../../common/Header';
import HeaderProfile from './CsoContact';
import BasicInfoCso from './BasicInfoCso';
import { dateProfileFormatter } from '../../utils/dateProfileFormatter';
import TextWrapper from '../../common/Providers/TextWrapper';
import { getCsoById } from '../../../redux/cso/csoActions';
import { useParams } from 'react-router-dom';
import BurgerIcon from '../../common/Burger';

const CsoProfilePageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  overflow-y: auto;
  ${mobile(`
    padding: 0;
  `)}
  .center-content {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    ${mobile(`
      border-radius: 0;
    `)}
    .burger-container {
      position: absolute;
      top: 0;
    }
  }
`;

const CsoProfilePage = ({ handleSidebarToggle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector((store) => store.authReducer);
  const { fav } = useSelector((store) => store.favReducer);
  useEffect(() => {
    dispatch(getCsoById(id));
  }, [dispatch, id]);

  const { cso } = useSelector((store) => store.csoReducer);
  const { isMobile } = useBreakpoint();

  const [notDisplayConnectButton, setNotDisplayConnectButton] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('');
  const [connectionId, setConnectionId] = useState('');
  const [senderId, setSenderId] = useState(null);
  // console.log('cso', cso);

  useEffect(() => {
    if (cso?.data?.connect?.length > 0 && // Check if cso.data.connect is an array with length > 0
      cso?.data?.data?.csos?.length > 0 && // Check if cso.data.data.csos is an array with length > 0
      !(cso?.data?.data?.length > 1) // Check if cso.data.data is an array with length <= 1
    ) {
      cso?.data?.connect?.forEach((connection) => {
        if (connection?.id === cso?.data?.data?.csos[0]?.id) {
          setNotDisplayConnectButton(true);
          setConnectionStatus(connection?.type);
          setConnectionId(connection?.connect_id);
          setSenderId(connection?.id);
        }
      });
    }
  }, [cso]);

  return (
    <CsoProfilePageContainer>
      {cso?.data?.data?.csos && cso?.data?.data?.csos.length > 0 && (
        <div className="center-content">
          {!isMobile ? (
            <Header
              TitleName={cso?.data?.data?.csos[0]?.organisation_name}
              SubName={
                localStorage.getItem('role') == '4'
                  ? user?.info?.organisation_name
                  : user?.info?.first_name + ' ' + user?.info?.last_name
              }
            />
          ) : (
            <div className="burger-container">
              <BurgerIcon handleSidebarToggle={handleSidebarToggle} />
            </div>
          )}
          <HeaderProfile
            // websiteUrl={cso?.data?.data?.csos[0]?.organisation_social_media}
            // profileImage={cso?.data?.data?.csos[0]?.logo}
            isConnect={
              cso?.data?.data?.csos[0]?.connects?.length > 0 ? true : false
            }
            myProfile={
              localStorage.getItem('role') == cso?.data?.data?.role
                ? true
                : false
            }
            isVerified={cso?.data?.data?.isVerified_account}
            organisation_name={cso?.data?.data?.csos[0]?.organisation_name}
            organisation_logo={cso?.data?.data?.csos[0]?.organisation_logo}
            id={cso?.data?.data?.csos[0]?.id}
            favorite={
              cso?.data?.data?.csos[0]?.favorites?.length > 0 ? true : false
            }
            favoriteId={cso?.data?.data?.csos[0]?.favorites[0]?.id}
            email_address_contact={
              cso?.data?.data?.csos[0]?.email_address_contact
            }
            connectStatus={cso?.data?.data?.csos[0]?.connects}
            connectionsId={
              cso?.data?.data?.csos[0]?.connects[0]?.id || connectionId
            }
            connects={
              cso?.data?.data?.csos[0]?.connects?.length > 0 ||
                notDisplayConnectButton
                ? true
                : false
            }
            connectsStatus={
              cso?.data?.data?.csos[0]?.connects[0]?.type || connectionStatus
            }
            user_id={id}
            sender_id={senderId}
            role={cso?.data?.data?.role}
            receivedConnect={
              cso?.data?.connect && cso?.data?.connect[0]?.type === 'Accept'
                ? true
                : false
            }
            isPending={cso?.data?.data?.csos[0]?.connects[0]?.type}
          />
          <TextWrapper text={'Basic Information'}>
            <BasicInfoCso
              websiteUrl={cso?.data?.data?.csos[0]?.organisation_social_media}
              country={cso?.country?.map((item) => {
                if (
                  item.id === cso?.data?.data?.csos[0]?.country_of_operation_id
                )
                  return item.name;
              })}
              date={dateProfileFormatter(cso?.data?.data?.csos[0]?.date_of_creation)}
              employeeNumber={`${cso?.data?.data?.csos[0]?.number_of_employees} employees`}
              description={cso?.data?.data?.csos[0]?.organisation_description}
              ideal_fellow={cso?.data?.data?.csos[0]?.ideal_fellow}
              motivation_for_fellowship={
                cso?.data?.data?.csos[0]?.motivation_for_fellowship
              }
              intrested={cso?.data?.data?.csos[0]?.csos_field_of_interests}
              role={cso?.data?.data?.role == 4 ? 'Cso' : 'Fellow'}
              type={cso?.data?.data?.csos[0]?.type_of_organisation}
            />
          </TextWrapper>
          <br />
        </div>
      )}
    </CsoProfilePageContainer>
  );
};

export default CsoProfilePage;
