import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from '../common/Sidebar';
import Footer from '../common/Footer/Footer';
import Register from '../pages/User/Register';

import FellowProfile from '../pages/FellowProfile';
import CsoProfile from '../pages/CsoProfile';
import ViewCsoProfile from '../pages/ViewCso';
import { useAuth } from '../hooks/useAuth';
import PageNotFound from '../pages/PageNotFound';
import HomePage from '../pages/Home';
import styled from '@emotion/styled';
import { mobile } from '../../styles/media';
import { useBreakpoint } from '../hooks/useBreakpoint';
import ViewFellowProfile from '../pages/ViewFellow';
import SearchPage from '../pages/Search';
import Invitations from '../pages/Invitations';
import Login from '../pages/User/Login';
import LoginPage from '../pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotifications,
  notificationCheck,
} from '../../redux/notifications/notificationsActions';

const LayoutContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 250px auto;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  max-height: 100vh;
  ${mobile(`
      grid-template-columns:auto;
      max-height: 100vh;
  `)}
  .main-content {
  }
`;

const CsoRouter = ({ handleSidebarToggle }) => (
  <Routes>
    <Route
      exact
      path="/"
      element={<SearchPage handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/cso_profile"
      element={<CsoProfile handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/auth/:page"
      element={<Register handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/view/cso/:id"
      element={<ViewCsoProfile handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/view/fellow/:id"
      element={<ViewFellowProfile handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/search"
      element={<SearchPage handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/invitations"
      element={<Invitations handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route exact path="/*" element={<PageNotFound />} />
  </Routes>
);

const FellowRouter = ({ handleSidebarToggle }) => (
  <Routes>
    <Route
      exact
      path="/"
      element={<SearchPage handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/fellow_profile"
      element={<FellowProfile handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/auth/:page"
      element={<Register handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/view/cso/:id"
      element={<ViewCsoProfile handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/view/fellow/:id"
      element={<ViewFellowProfile handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/view/cso"
      element={<ViewCsoProfile handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/search"
      element={<SearchPage handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route
      exact
      path="/invitations"
      element={<Invitations handleSidebarToggle={handleSidebarToggle} />}
    />
    <Route exact path="/*" element={<PageNotFound />} />
  </Routes>
);

const UnAuthenticatedRouter = ({ route }) => (
  <Routes>
    <Route exact path="/auth/:page" element={<Register />} />
    <Route exact path="/*" element={<LoginPage />} />
  </Routes>
);

const PageSwitch = () => {
  const { isLoggedIn, logout } = useAuth();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const {OTPError} = useSelector((state) => state.authReducer);
  useEffect(() => {
    dispatch(notificationCheck({ page: 1 }));
    if(OTPError && OTPError.name === "JsonWebTokenError"){
      logout()
    }
  }, [OTPError, dispatch]);

  console.log(OTPError);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleSidebarToggle = () => {
    setActive(!active);
  };
  const role = localStorage.getItem('role');

  if(location.pathname.includes('/search') || location.pathname === "/"){
    //remove the scroll from the body 
    document.body.style.overflow = 'hidden';
  }

  // console.log(location.pathname);

  return (
    <LayoutContainer>
      <Sidebar active={active} handleSidebarToggle={handleSidebarToggle} />
      <div className="main-content">
        {isLoggedIn ? (
          role === '4' ? (
            <CsoRouter handleSidebarToggle={handleSidebarToggle} />
          ) : (
            role === '3' && (
              <FellowRouter handleSidebarToggle={handleSidebarToggle} />
            )
          )
        ) : (
          <UnAuthenticatedRouter
            handleSidebarToggle={handleSidebarToggle}
            route={location}
          />
        )}
        <Footer />
      </div>
    </LayoutContainer>
  );
};

export default PageSwitch;
