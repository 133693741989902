import styled from '@emotion/styled';
import React from 'react';
import ItemTag from '../../../common/ItemTag';
import SVG from '../../../common/SVG';
import LinkedinIcon from '../../../icons/LinkedinIcon';
import { useTheme } from '@emotion/react';

const BasicInfoFellowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .item {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  div {
    display: flex;
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .underline{
    display: flex;
    flex-direction: column;
  }
  b {
    margin-right: 0.4rem;
  }
`;

const BasicInfoFellow = ({
  country,
  availability,
  motivation_for_fellowship,
  organisation_name,
  role,
  linkedinUrl
}) => {
  console.log(motivation_for_fellowship);
  const motivations = motivation_for_fellowship.split('\n');
  const theme = useTheme()
  return (
    <BasicInfoFellowContainer>
      <div>
        <b>Status: </b>
        <span>{role}</span>
      </div>
      <div>
        <b>Organization name: </b>
        <span>{organisation_name}</span>
      </div>
      <div>
        <b>Country of Residency:</b>
        <span>{country}</span>
      </div>
      <div>
        <b>Availability:</b>
        <span>{availability}</span>
      </div>
      <div className="item">
        <div>
          <b>Motivation for fellowship: </b>
        </div>
        <div className="underline">
        {motivations.map((motivation, index) => (
            <p key={index}>{motivation}</p>
          ))}
        </div>
      </div>

      {linkedinUrl !== null && linkedinUrl.length > 0 && (
              <div>
              <b>Linkedin:</b>
              <a href={linkedinUrl} target="_blank">
                <SVG 
                  width={'18px'}
                  height={'18px'}
                  icon={<LinkedinIcon />}
                  color={theme.colors.primary}
            />
              </a>
            </div>
      )}

    </BasicInfoFellowContainer>
  );
};

export default BasicInfoFellow;
