import { combineReducers } from 'redux';
import authSlice from './auth/authreducers';
import csoSlice from './cso/csoReducers';
import fellowSlice from './fellow/fellowReducers';
import invitationsSlice from './connect/connectReducers';
import favSlice from './fav/favReducers';
import notificationsSlice from './notifications/notificationsReducers';

const RootReducer = combineReducers({
  authReducer: authSlice.reducer,
  csoReducer: csoSlice.reducer,
  fellowReducer: fellowSlice.reducer,
  invitationsReducer: invitationsSlice.reducer,
  favReducer: favSlice.reducer,
  notificationReducer: notificationsSlice.reducer,
});

export default RootReducer;
