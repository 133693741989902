import styled from '@emotion/styled';
import React from 'react';
import { mobile } from '../../../../styles/media';

const ConfirmModelContainer = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .main-content {
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem 2rem 1rem 2rem;
    width: 600px;
    gap: 2rem;
    border-radius: 1rem;
    ${mobile(`
      width: 90%;
    padding:1.5rem 1rem 1rem 1em;

    `)}
    p {
      font-size: ${({ theme }) => theme.fontSizes.m};
      line-height: 2rem;
      font-weight: 500;
        ${({theme}) => mobile(`font-size: ${theme.fontSizes.s};`)}
    }
    .button-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      flex-wrap: wrap;
      gap: 1rem;
      ${mobile(`
                gap: 0.5rem;
            `)}
      button {
        padding: 0.5rem 2rem;
        border-radius: 0.5rem;
        font-size: ${({ theme }) => theme.fontSizes.s};

        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.primary};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        white-space: nowrap;
        ${mobile(`
            padding: 0.5rem;
            width: 100%;
        `)}
        ${({ theme }) =>
          mobile(`
          font-size: ${theme.fontSizes.xs};
        `)}
      }
    }
  }
  .background {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.6;
  }
`;

const ConfirmModel = ({ setPopupActive, setActive }) => {
  return (
    <ConfirmModelContainer>
      <div className="main-content">
        <p>
          You have unsaved changes. If you leave now, your changes will not be
          saved.
        </p>
        <div className="button-container">
          <button
            onClick={() => {
              setPopupActive(false);
              setActive(false);
            }}
          >
            Yes, leave without saving
          </button>
          <button
            onClick={() => {
              setPopupActive(false);
            }}
          >
            No, continue editing
          </button>
        </div>
      </div>
      <div className="background" onClick={() => setPopupActive(false)} />
    </ConfirmModelContainer>
  );
};

export default ConfirmModel;
