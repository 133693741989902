import styled from '@emotion/styled';
import { mobile } from '../../../styles/media';

const ItemTagContainer = styled.div`
  cursor: pointer;
  padding: 0.5rem 0.7rem;
  border-radius: 1rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  width: fit-content;
  ${({ theme }) =>
    mobile(`
        padding: 0.5rem 0.5rem;
        font-size:${theme.fontSizes.xs};
    `)}
  ${({ active, theme }) =>
    active && `outline: 2px solid ${theme.colors.primary};`}
`;

const ItemTag = ({ text, active, onClick }) => {
  return (
    <ItemTagContainer active={active} onClick={onClick}>
      {text}
    </ItemTagContainer>
  );
};

export default ItemTag;
