import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { useSettings } from '../../../hooks/useSettings';
import { useState } from 'react';

const SimpleInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  label {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      border: none;
      outline: none;
      color: ${({ theme }) => theme.colors.secondary};
      width: 100%;
      padding-right: 0.6rem;
      color: ${({ error, theme }) => (error ? theme.colors.red : '')};
    }
    input::placeholder {
      color: ${({ placeholderColor, error }) =>
        error ? placeholderColor : ''};
    }
    p {
      color: ${({ theme }) => theme.colors.border};
    }
    .limit-text {
      color: ${({ error, theme }) =>
      error ? theme.colors.red : theme.colors.border};
      white-space: nowrap;
      position: absolute;
      top: 0;
      right: 0.3rem;
    }
  }

  .error-message {
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    transform: translateY(-0.3rem);
  }

  .line {
    width: 100%;
    height: 1px;
    border: 1px solid
      ${({ error, theme }) => (error ? theme.colors.red : theme.colors.border)};
    transform: translateY(-0.5rem);
    border-radius: 1px;
  }
`;
const SimpleInput = ({
  type,
  label,
  disabled,
  name,
  limit,
  value,
  placeholder,
  required,
  errorType,
  error,
}) => {
  const { register } = useFormContext();
  const theme = useTheme();
  const { setIsFormEdited } = useSettings();

  const [count, setCount] = useState(howMuchWords(value));

  function howMuchWords(text) {
    return text && text.trim().split(/\s+/).length;
  }
  console.log('error', error);
  return (
    <SimpleInputContainer placeholderColor={theme.colors.red} error={error}>
      <label htmlFor={name}>{label}</label>
      <div className="input-container">
        <input
          type={type}
          id={name}
          defaultValue={error ? '' : value}
          disabled={disabled}
          {...register(name, { required })}
          placeholder={errorType === 'placeholder' ? error : placeholder}
          onChange={(e) => {
            setIsFormEdited(true);
            setCount(howMuchWords(e.target.value)); // Pass the input value to howMuchWords
          }}
        />
        {limit && (
          <p className="limit-text">
            {count.length === 0 ? 0 : count} words /{limit}
          </p>
        )}
      </div>
      <div className="line" />
      {errorType === 'inline' && <p className="error-message">{error}</p>}
    </SimpleInputContainer>
  );
};

export default SimpleInput;