import axios from 'axios';
import { csoActions } from './csoReducers';
import { toast } from 'react-toastify';

export const getCsoById = (id) => async (dispatch) => {
  try {
    dispatch(csoActions.EditRequest());

    const response = await axios.get(
      process.env.REACT_APP_API + '/cso/getOne/' + id,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    dispatch(csoActions.EditSuccess(response?.data));
  } catch (e) {
    dispatch(csoActions.EditFail(e.message));
  }
};

export const editCso = (formData) => async (dispatch) => {
  try {
    dispatch(csoActions.EditRequest());

    // Construct FormData object
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    const response = await axios.put(
      process.env.REACT_APP_API + '/updateMyInfo',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    dispatch(csoActions.EditSuccess(response?.data));
    // toast.success('Profile updated successfully');
    window.location.reload();
  } catch (e) {
    dispatch(csoActions.EditFail(e.message));
    toast.error('Failed to update profile');
  }
};

export const getCsos = (filter) => async (dispatch) => {
  try {
    dispatch(csoActions.GetRequest());

    // Format country and interests arrays as comma-separated strings
    const countryParams = filter.cso.country
      ? filter.cso.country.join(',')
      : '';
    const interestsParams = filter.cso.interests
      ? filter.cso.interests.join(',')
      : '';

      const isFavParams = filter.isFavorite
      ? '1'
      : '0';
    // Construct the query parameters
    const queryParams = {
      country_of_operation: countryParams,
      field_of_interest: interestsParams,
      type_of_organisation: filter.cso.type_of_organisation[0],
      search: filter.search,
      page: filter.cso.page, // Add page parameter
      limit: filter.cso.limit, // Add limit parameter
      is_favorite: isFavParams
    };

    const response = await axios.get(
      process.env.REACT_APP_API + '/cso/getAll',
      {
        params: queryParams,
      },
    );

    dispatch(csoActions.GetSuccess(response?.data));
  } catch (e) {
    dispatch(csoActions.GetFail(e.message));
  }
};
