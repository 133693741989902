import styled from '@emotion/styled';
import React, { useState } from 'react';
import ProfileBackground from '../../../common/ProfileBackground';
import ProfileCameraContainer from '../../../common/ProfileCameraContainer';
import Form from '../../../common/Providers/Form';
import { useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import SimpleInput from '../../../common/Inputs/SimpleInput';
import TextareaInput from '../../../common/Inputs/TextareaInput';
import SubmitButton from '../../../common/Buttons/SubmitButton';
import SelectInput from '../../../common/Inputs/SelectInput';
import { editFellow } from '../../../../redux/fellow/fellowActions';
import { useDispatch } from 'react-redux';
import { useSettings } from '../../../hooks/useSettings';

const FellowProfileFormContainer = styled.div`
  width: 100%;
  .cover-image-profile {
    width: 100%;
    position: relative;
    margin-bottom: 4.1rem;
    .center-camera {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .logo-container {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      left: 3rem;
      .image-content {
        position: relative;
        width: 150px;
        height: 150px;
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 7;
        }
        img {
          position: absolute;
          width: 150px;
          height: 150px;
          border-radius: 50%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
        .black-background-image {
          position: absolute;
          top: 0;
          left: 0;
          background-color: ${({ theme }) => theme.colors.black};
          width: 150px;
          height: 150px;
          border-radius: 50%;
          opacity: 0.6;
        }
        .logo-camera-container {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 6;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .main-content {
    padding: 0 2rem;
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: start;
    .form-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 2rem;
      .submit-button-container {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-bottom: 1rem;
      }
    }
  }
`;
const StyledScrollbarContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.darkerGrey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.darkGrey};
  }
`;

function isValidUrl(url) {
  const urlPattern = /^(?:(?:https?|ftp):\/\/|www\.)[^\s/$.?#].[^\s]*$/i;

  return urlPattern.test(url);
}

const schema = z.object({
  first_name: z.string().min(1),
  last_name: z.string().min(1),
  linkedIn: z.string().nullable().optional().refine(value => value === '' || isValidUrl(value), {
    message: 'Invalid URL',
  }),
  current_occupation: z
  .string()
  .trim()
  .min(1)
  .refine(
    (value) => {
      const words = value.trim().split(/\s+/);
      return words.length <= 10;
    },
    {
      message: 'Current occupation must have at most 10 words.',
    },
  ),
  organisation_name: z
  .string()
  .trim()
  .min(1)
  .refine(
    (value) => {
      const words = value.trim().split(/\s+/);
      return words.length <= 10;
    },
    {
      message: 'Organization name must have at most 10 words.',
    },
  ),
  country_of_residency_id: z.string().trim().min(1),
  availability: z.string().min(1), // You might want to specify a format or enum for this field
  motivation_for_fellowship: z
  .string()
  .trim()
  .min(1)
  .refine(
    (value) => {
      const words = value.trim().split(/\s+/);
      return words.length <= 200;
    },
    {
      message: 'Organization name must have at most 200 words.',
    },
  ),
  picture: z.any(),
});
const Inputs = ({
  first_name,
  last_name,
  linkedIn,
  current_occupation,
  organisation_name,
  country_of_residency_id,
  country,
  availability,
  availabilityList,
  motivation_for_fellowship,
}) => {
  const method = useFormContext();
  return (
    <>
      <SimpleInput
        type="text"
        name="first_name"
        label="First Name:"
        value={first_name}
        placeholder={'Enter First Name'}
        error={method?.formState?.errors?.first_name?.message}
        required={true}
        disabled={false}
        errorType={'inline'}
      />
      <SimpleInput
        type="text"
        name="last_name"
        label="Last Name:"
        value={last_name}
        placeholder={'Enter Last Name'}
        error={method?.formState?.errors?.last_name?.message}
        required={true}
        disabled={false}
        errorType={'inline'}
      />
      <SimpleInput
        type="text"
        name="linkedIn"
        label="Linkedin Link:"
        value={linkedIn}
        placeholder={'Enter linkedIn Url'}
        error={method?.formState?.errors?.linkedIn?.message}
        required={true}
        disabled={false}
        errorType={'inline'}
      />
      <SimpleInput
        type="text"
        name="current_occupation"
        label="Current Occupation:"
        value={current_occupation}
        placeholder={'Enter Current Occupation'}
        error={method?.formState?.errors?.current_occupation?.message}
        required={true}
        disabled={false}
        errorType={'inline'}
        limit={'10 words'}
      />
      <SimpleInput
        type="text"
        name="organisation_name"
        label="Organization Name:"
        value={organisation_name}
        placeholder={'Enter Organization Name'}
        error={method?.formState?.errors?.organisation_name?.message}
        required={true}
        disabled={false}
        errorType={'inline'}
        limit={'10 words'}
      />
      <SelectInput
        type="text"
        name="country_of_residency_id"
        label="Country Of Residency:"
        value={country_of_residency_id}
        placeholder="Select country of operation"
        error={method?.formState?.errors?.country_of_residency_id?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        options={country.map((countryItem) => ({
          label: countryItem.name,
          value: countryItem.id,
        }))}
      />
      <SelectInput
        type="text"
        name="availability"
        label="Availability:"
        value={availability}
        placeholder="Select country of operation"
        error={method?.formState?.errors?.availability?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        options={availabilityList.map((item) => ({
          label: item,
          value: item,
        }))}
      />
      <TextareaInput
        type="text"
        name="motivation_for_fellowship"
        label="Motivation for Fellowship:"
        value={motivation_for_fellowship}
        placeholder="Organization Description:"
        error={method?.formState?.errors?.motivation_for_fellowship?.message}
        required={true}
        disabled={false}
        errorType="placeholder"
        limit={"200 words"}
      />
      <div className="submit-button-container">
        <SubmitButton type="submit" text="Save" />
      </div>
    </>
  );
};

const FileInput = ({setImageData}) => {
  const method = useFormContext();
  const {setIsFormEdited} = useSettings();
  return (
    <input
      type="file"
      onChange={(e) => {
        setImageData(URL.createObjectURL(e.target.files[0]));
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          method.setValue('picture', file);
          setIsFormEdited(true);
        } else {
          method.setValue('picture', null);
          setIsFormEdited(true);
        }
      }}
    />
  );
};

const FellowProfileForm = ({
  first_name,
  last_name,
  linkedIn,
  current_occupation,
  organisation_name,
  country_of_residency_id,
  availability,
  motivation_for_fellowship,
  country,
  availabilityList,
  picture,
}) => {
  const dispatch = useDispatch();
  const submit = (data) => {
    dispatch(editFellow(data));
  };

  const [imageData, setImageData] = useState(process.env.REACT_APP_API + '/user/' + picture);


  return (
    <FellowProfileFormContainer>
      <Form
        option={{ resolver: zodResolver(schema) }}
        {...useForm()}
        onSubmit={submit}
      >
        <StyledScrollbarContainer>
          <div className="cover-image-profile">
            <ProfileBackground height={'170px'} />
            <div className="logo-container">
              <div className="image-content">
                <img
                  src={imageData}
                  alt="ImageNotFound"
                />
                <FileInput setImageData={setImageData} />
                <div className="logo-camera-container">
                  <ProfileCameraContainer text={'Upload Picture'} />
                </div>
                <div className="black-background-image" />
              </div>
            </div>
          </div>
          <div className="main-content">
            <h1 className="title">Basic Information</h1>
            <div className="form-container">
              <Inputs
                first_name={first_name}
                last_name={last_name}
                linkedIn={linkedIn}
                current_occupation={current_occupation}
                organisation_name={organisation_name}
                country_of_residency_id={country_of_residency_id}
                availability={availability}
                motivation_for_fellowship={motivation_for_fellowship}
                country={country}
                availabilityList={availabilityList}
              />
            </div>
          </div>
        </StyledScrollbarContainer>
      </Form>
    </FellowProfileFormContainer>
  );
};

export default FellowProfileForm;
