import styled from '@emotion/styled';

export const FellowProfileFormContainer = styled.div`
  width: 100%;
  .main-content {
    padding: 2rem;
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: start;
    max-height: 100vh;
    .title {
      padding-bottom: 2rem;
    }
    .form-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 2rem;
      .input-section {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        width: 100%;
        p {
          font-size: ${({ theme }) => theme.fontSizes.l};
          font-weight: ${({ theme }) => theme.fontWeights.bold};
        }
        .list {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 0.3rem;
        flex-wrap: wrap;  
        }
      }
      .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1rem;
      }
      .submit-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .upload-cv {
        position: relative;
        width: 100%;
        input {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    .error-message {
      width: 100%;
      color: ${({ theme }) => theme.colors.red};
    }
    .success {
      color: ${({ theme }) => theme.colors.primary};
      width: 100%;
    }
  }
`;
export const StyledScrollbarContainer = styled.div`
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.darkerGrey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.darkGrey};
  }
`;
