import styled from '@emotion/styled';
import React from 'react';
import SVG from '../SVG';
import { useTheme } from '@emotion/react';
import LoadingSpinner from '../../icons/LoadingSpinner';

const LoadingModelContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  .background {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: 0.6;
  }
  .spinner-icon-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const LoadingModel = () => {
  const theme = useTheme();
  return (
    <LoadingModelContainer>
      <div className="background" />
      <div className="spinner-icon-container">
        <SVG
          width={'100px'}
          height={'100px'}
          icon={<LoadingSpinner />}
          color={theme.colors.primary}
        />
      </div>
    </LoadingModelContainer>
  );
};

export default LoadingModel;
