import styled from '@emotion/styled';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SVG from '../SVG';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import LeftArrow from '../../icons/LeftArrow';
import BellIcon from '../../icons/BellIcon';
import ToggleIcon from '../../icons/ToggleIcon';
import { useAuth } from '../../hooks/useAuth';
import { mobile } from '../../../styles/media';
import NotificationPopup from '../Notification';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useDispatch, useSelector } from 'react-redux';

import { notificationCheck as notificationCheckApi } from '../../../redux/notifications/notificationsActions';

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 50px;
  ${({ CssEditSection }) =>
    CssEditSection === 'invitations' &&
    mobile(`
      margin-bottom: 0;
  `)}

  ${mobile(`
      height: 100%;
    `)}
  .back-navigation {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 50px;
    ${({ CssEditSection }) =>
      CssEditSection === 'invitations' &&
      mobile(`
      width: 0 !important ;
  `)}
    box-shadow: ${({ theme }) => theme.shadows.default};
    cursor: pointer;
    ${mobile(`
        box-shadow: none !important 
    `)}
  }
  .main-container {
    ${({ fixHeight }) =>
      fixHeight ? `height:${fixHeight} !important;` : 'height: 100%;'}
    width: 100%;
    box-shadow: ${({ theme }) => theme.shadows.default};
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    ${({ CssEditSection }) =>
      CssEditSection === 'invitations' &&
      mobile(`
      padding: 0;
  `)}
    ${mobile(`
      box-shadow: none !important
    `)}
    .left-side {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      p {
        font-size: ${({ theme }) => theme.fontSizes.m};
      }
    }
    .right-side {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .notification-container {
        position: relative;

        .un-opened-messages {
          height: 8px;
          width: 8px;
          background-color: ${({ theme }) => theme.colors.primary};
          border-radius: 50%;
          position: absolute;
          top: -0.1rem;
          right: 0;
        }
      }

      .toggle {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        p {
          font-weight: ${({ theme }) => theme.fontWeights.bold};
          font-size: ${({ theme }) => theme.fontSizes.xs};
        }
        .toggle-icon-container {
          cursor: pointer;
          position: relative;
          transition: transform 0.1s ease;
          transform: ${({ showProfileOptions }) =>
            showProfileOptions ? 'rotate(180deg)' : 'rotate(0)'};
        }
        .profile-options {
          position: absolute;
          z-index:1;
          right: -2rem;
          top: 1rem;
          width: 117px;
          visibility: ${({ showProfileOptions }) =>
            showProfileOptions ? 'visible' : 'hidden'};
          background-color: ${({ theme }) => theme.colors.white};
          border-radius: 0.5rem;
          box-shadow: ${({ theme }) => theme.shadows.default};
          li {
            list-style: none;
            a {
              padding: 0 0.5rem;
              margin: 1rem;
              display: block;
              text-decoration: none;
              color: ${({ theme }) => theme.colors.black};
              font-weight: ${({ theme }) => theme.fontWeights.bold};
              font-size: ${({ theme }) => theme.fontSizes.xs};
            }
          }
        }
      }
    }
  }
`;

const Header = ({ TitleName, SubName, active, fixHeight, CssEditSection }) => {
  const theme = useTheme();

  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const { notificationCheck } = useSelector(
    (state) => state.notificationReducer,
  );

  // console.log("notificationCheck", notificationCheck);


  const { logout } = useAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowProfileOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    dispatch(notificationCheckApi({ page: 1 }));
  }, [dispatch]);

  const { isMobile } = useBreakpoint();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleProfileToggle = () => {
    setShowProfileOptions(!showProfileOptions);
  };

  const handleBellIconClick = () => {
    setShowNotificationPopup(!showNotificationPopup);
  };

  const role = localStorage.getItem('role');
  let link = '';
  switch (role) {
    case '4':
      link = '/cso_profile';
      break;
    case '3':
      link = '/fellow_profile';
      break;
    default:
      link = '#';
  }

  return (
    <HeaderContainer
      fixHeight={fixHeight}
      showProfileOptions={showProfileOptions}
      showNotificationPopup={showNotificationPopup}
      CssEditSection={CssEditSection}
    >
      {isMobile && CssEditSection === 'invitations' ? (
        <></>
      ) : (
        <div className="back-navigation" onClick={handleGoBack}>
          <SVG
            icon={<LeftArrow />}
            width={'20px'}
            height={'20px'}
            color={theme.colors.secondary}
          />
        </div>
      )}
      <div className="main-container">
        <div className="left-side">
          <p>{TitleName}</p>
        </div>
        <div className="right-side">
          <div className="notification-container">
            <div>
            {notificationCheck?.data &&
                  notificationCheck?.data[0]?.is_read === false && (
                    <div className="un-opened-messages" />
                  )}
            </div>
            <div>
              <SVG
                icon={<BellIcon />}
                width={'20px'}
                height={'20px'}
                color={theme.colors.secondary}
                onClick={() => handleBellIconClick()}
              />
              <NotificationPopup
                className="notification-popup"
                showNotificationPopup={showNotificationPopup}
                setShowNotificationPopup={setShowNotificationPopup}
              />
            </div>
          </div>

          <div className="toggle">
            <p>{SubName}</p>
            <div
              onClick={handleProfileToggle}
              className="toggle-icon-container"
            >
              <SVG
                width={'12px'}
                height={'12px'}
                color={theme.colors.secondary}
                icon={<ToggleIcon />}
              />
            </div>
            {showProfileOptions && (
              <div className="profile-options" ref={ref}>
                <ul>
                  <li>
                    <Link to={link}>My profile </Link>
                  </li>
                  <li onClick={logout}>
                    <a href="/auth/login">Log Out</a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
