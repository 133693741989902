import React from 'react';

const TrashIcon = () => {
  return (
    <svg
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250 375C250 361.2 261.2 350 275 350C288.8 350 300 361.2 300 375V675C300 688.825 288.8 700 275 700C261.2 700 250 688.825 250 675V375ZM375 375C375 361.2 386.2 350 400 350C413.8 350 425 361.2 425 375V675C425 688.825 413.8 700 400 700C386.2 700 375 688.825 375 675V375ZM500 375C500 361.2 511.2 350 525 350C538.8 350 550 361.2 550 375V675C550 688.825 538.8 700 525 700C511.2 700 500 688.825 500 675V375ZM125 700C125 755.225 169.775 800 225 800H575C630.225 800 675 755.225 675 700V300H125V700ZM475 100H325V75C325 61.175 336.2 50 350 50H450C463.8 50 475 61.175 475 75V100ZM675 100H525V50C525 22.4 502.6 0 475 0H325C297.4 0 275 22.4 275 50V100H125C97.4 100 75 122.4 75 150V200C75 227.6 97.3748 249.975 124.975 250H675.05C702.625 249.975 725 227.6 725 200V150C725 122.4 702.6 100 675 100Z"
        fill="black"
      />
    </svg>
  );
};

export default TrashIcon;
