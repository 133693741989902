import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../../redux/notifications/notificationsActions';
import SVG from '../SVG';
import ConnectIcon from '../../icons/ConnectIcon';
import { useTheme } from '@emotion/react';
import HeartIcon from '../../icons/HeartIcon';
import { notificationsActions } from '../../../redux/notifications/notificationsReducers';
import PrimaryButton from '../Buttons/PrimaryButton';
import { mobile } from '../../../styles/media';

const PopupContainer = styled.div`
  padding: 1rem;
  position: absolute;
  right: -1rem;
  top: 1.3rem;
  width: 450px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.default};
  z-index: 99;
  ${({ showNotificationPopup }) =>
    showNotificationPopup ? 'display: block;' : 'display: none;'}

  ${mobile(`
    width: 300px;
    right: 1rem;
    padding: 1rem;
    top: 3.1rem;
    `)}
  .popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;

    h3 {
      position: relative;
      margin-bottom: 1.5rem;
    }
    h3::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1rem;
      border-radius: 1rem;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.primary};
    }

    ul {
      margin-top: 1rem;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background: #888;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      ::-webkit-scrollbar-button {
        display: none;
      }
      li {
        font-size: ${({ theme }) => theme.fontSizes.s};
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 0.3rem;
        b {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        .ball {
          width: 5px;
          height: 5px;
          background-color: ${({ theme }) => theme.colors.primary};
          border-radius: 50%;
        }
        ${({ theme }) =>
          mobile(`
          font-size: ${theme.fontSizes.xxs};
          margin-bottom: 0.5rem;
          `)}
        .left {
          display: flex;
          gap: 0.3rem;
          text-decoration: none;
          color: ${({ theme }) => theme.colors.secondary};
          span {
            color: ${({ theme }) => theme.colors.black};
          }
        }
      }
    }
    .load-more-button {
      width: 190px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }
  }
`;

function NotificationPopup({ showNotificationPopup, setShowNotificationPopup }) {
  const { notifications } = useSelector((state) => state.notificationReducer);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
  });
  const [currentData, setCurrentData] = useState([]);

  // console.log(notifications);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowNotificationPopup(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);


  useEffect(() => {
    if (showNotificationPopup === false) {
      // Reset data and filter when closing the popup
      setCurrentData([]);
      setFilter({
        page: 1,
        limit: 5,
      });
      dispatch(notificationsActions.reset());
    } else {
      // Fetch notifications when opening the popup
      setCurrentData([]);
      dispatch(getNotifications(filter));
    }
  }, [dispatch, showNotificationPopup, filter.page]);

  useEffect(() => {
    // Update current data when notifications change
    if (notifications?.data) {
      setCurrentData((prevData) => [...prevData, ...notifications.data]);
    }
  }, [notifications]);

  const loadMoreNotifications = () => {
    // Load more notifications by incrementing the page number
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: prevFilter.page + 1,
    }));
  };

  const role = localStorage.getItem('role');

// console.log("Current data:", currentData);

  const theme = useTheme();
  return (
    <PopupContainer showNotificationPopup={showNotificationPopup} ref={ref}>
      <div className="popup" >
        <h3>Notifications:</h3>
        <ul>
          {currentData?.map((item, index) => {
            if (item?.type === 'connect') {
              return (
                <li key={index}>
                  <a
                    className="left"
                    href={
                      item?.ref === 'cso'
                        ? `/view/fellow/${item?.fellow?.user_id}`
                        : `/view/cso/${item?.cso?.user_id}`
                    }
                  >
                    <b>
                      <div className="ball" />
                      <span>{role == 4 ? `${item?.fellow?.first_name + ' ' + item?.fellow?.last_name }` : `${item?.cso?.organisation_name}`}</span>
                    </b>
                    {item?.status === 'Pending' ? 'requested a connection' : item.status === "Accept" ? 'just accepted your connection request' : 'just rejected your connection request'}
                  </a>
                </li>
              );
            } else if (item?.type === 'favorite') {
              return (
                <li key={index}>
                  <a
                    className="left"
                    href={
                      item?.ref !== 'fellow'
                        ? `/view/fellow/${item?.fellow?.user_id}`
                        : `/view/cso/${item?.cso?.user_id}`
                    }
                  >
                    <b>
                      <div className="ball" />
                      <span>{role == 4 ? `${item?.fellow?.first_name + ' ' + item?.fellow?.last_name }` : `${item?.cso?.organisation_name}`}</span>
                    </b>
                    <span>just added you to favorites</span>
                  </a>
                </li>
              );
            }
          })}
        </ul>
        {notifications?.pageInfo?.totalPages !== filter.page &&
          filter.page < notifications?.pageInfo?.totalPages && (
            <div className="load-more-button">
              <PrimaryButton
                handleClick={() => loadMoreNotifications()}
                text="Load more ..."
              />
            </div>
          )}
      </div>
    </PopupContainer>
  );
}

export default NotificationPopup;
