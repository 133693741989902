import React from 'react';

const InstagramIcon = () => {
  return (
    <svg
      width="39"
      height="40"
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.981 20.0322C38.981 22.6859 39.032 25.3396 38.9703 27.9905C38.8092 35.0642 35.3167 39.2727 28.4552 39.7232C22.4044 40.1188 16.2892 40.0776 10.2384 39.6792C4.20098 39.2837 0.630637 35.7125 0.257496 29.5178C-0.137122 22.9633 -0.053903 16.3429 0.33803 9.78568C0.657482 4.44814 4.11777 0.703888 9.3498 0.349517C16.1496 -0.109243 23.0272 -0.131219 29.8216 0.379735C35.3623 0.794541 38.7394 5.21732 38.938 10.9779C39.0401 13.9942 38.9568 17.016 38.9568 20.035C38.9649 20.035 38.9756 20.035 38.9837 20.035L38.981 20.0322ZM3.5594 19.7685C3.5594 19.7685 3.56745 19.7685 3.57013 19.7685C3.57013 22.6502 3.48423 25.5346 3.58892 28.4135C3.76073 33.0725 6.00226 35.8279 10.4961 36.0943C16.1952 36.435 21.94 36.5366 27.6364 36.2015C32.9919 35.8855 35.3596 33.3665 35.4751 28.5591C35.6147 22.7985 35.6147 17.0297 35.467 11.2718C35.3543 6.82709 32.6349 3.83829 28.6511 3.73939C22.8473 3.5938 17.0354 3.55534 11.2343 3.70368C6.2385 3.83279 3.67483 6.67601 3.56745 11.8103C3.51376 14.4612 3.55671 17.1148 3.55671 19.7685H3.5594Z"
        fill="#74AF85"
      />
      <path
        d="M19.5483 9.68317C25.1508 9.7024 29.5936 14.2708 29.5748 19.9929C29.556 25.6766 25.0004 30.2779 19.4489 30.223C13.9001 30.1653 9.41439 25.498 9.45734 19.8198C9.4976 14.2296 14.0424 9.66394 19.5456 9.68317H19.5483ZM19.5805 13.2681C15.9618 13.2461 12.9982 16.2349 12.9794 19.927C12.9606 23.5916 15.9564 26.6463 19.551 26.6298C23.1159 26.6133 26.0393 23.6547 26.0769 20.0259C26.1172 16.3283 23.1965 13.2901 19.5778 13.2681H19.5805Z"
        fill="#74AF85"
      />
      <path
        d="M32.2727 9.25204C32.2781 10.6366 31.2714 11.6557 29.9158 11.6337C28.646 11.6145 27.6554 10.5871 27.6474 9.27951C27.6393 7.97465 28.6165 6.93351 29.8889 6.89231C31.2258 6.8511 32.2674 7.88125 32.2727 9.25204Z"
        fill="#74AF85"
      />
    </svg>
  );
};

export default InstagramIcon;
