import React from 'react';

const EditIcon = () => {
  return (
    <svg
      width="39"
      height="48"
      viewBox="0 0 39 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_37_2650)">
        <path
          d="M21.1511 7.77883C25.381 10.0249 29.5737 12.2514 33.8212 14.5073C32.1257 17.7504 30.4773 20.9068 28.8249 24.0612C27.5646 26.4689 26.3454 28.8983 25.0125 31.2626C24.6342 31.9345 24.0148 32.5847 23.3484 32.9551C20.9532 34.287 18.4932 35.5007 16.0529 36.7479C14.381 37.601 13.0422 36.9173 12.8109 35.0574C12.466 32.2931 12.1386 29.5248 11.874 26.7526C11.8152 26.132 11.8916 25.3991 12.17 24.8572C15.0337 19.301 17.9503 13.7724 20.8552 8.23593C20.9277 8.09801 21.0257 7.97191 21.1551 7.77686L21.1511 7.77883Z"
          fill="#C2CDD0"
        />
        <path
          d="M19.5638 45.137C25.2715 45.137 30.9813 45.137 36.6891 45.139C37.0811 45.139 37.4829 45.0996 37.8593 45.1803C38.5806 45.336 39.004 45.8227 39 46.5753C38.9961 47.2767 38.6159 47.7516 37.9318 47.9249C37.6515 47.9959 37.3516 47.9959 37.0596 47.9959C25.348 47.9978 13.6384 47.9959 1.92682 47.9959C1.73081 47.9959 1.53284 48.0136 1.34075 47.9841C0.523394 47.8639 -0.00583178 47.3949 4.85049e-05 46.5576C0.00592879 45.7241 0.537114 45.2493 1.35447 45.1449C1.67593 45.1035 2.00719 45.135 2.33256 45.135C8.07564 45.135 13.8207 45.135 19.5638 45.135V45.137Z"
          fill="#C2CDD0"
        />
        <path
          d="M35.1347 12.0168C30.8401 9.73522 26.667 7.51865 22.396 5.25086C23.0899 3.96426 23.6799 2.68949 24.4325 1.52112C25.3479 0.0985721 27.2355 -0.40779 28.7761 0.356679C31.0557 1.48762 33.3098 2.6757 35.5267 3.92289C37.1261 4.82331 37.722 6.77192 36.9634 8.45257C36.4283 9.64065 35.7756 10.7755 35.1347 12.0168Z"
          fill="#C2CDD0"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_2650">
          <rect width="39" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
