import React from 'react';

const CloseIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2574 24.9168C17.8526 26.293 16.5855 27.5133 15.3421 28.7573C10.669 33.4308 6.00109 38.1108 1.33455 42.7922C-0.418052 44.5506 -0.441861 47.1378 1.26973 48.8129C2.95223 50.4601 5.50507 50.3834 7.24841 48.6436C13.0181 42.8848 18.7904 37.1286 24.5628 31.3723C24.7017 31.2335 24.8498 31.1053 25.0786 30.8938C25.8723 31.7081 26.6434 32.5146 27.4317 33.3039C32.5533 38.4334 37.6801 43.5577 42.8017 48.6873C44.4961 50.3848 47.1019 50.4469 48.7328 48.8208C50.4232 47.1352 50.4232 44.5558 48.7182 42.8451C42.9803 37.0849 37.237 31.33 31.4925 25.5765C31.306 25.3901 31.0758 25.2486 30.81 25.0424C32.6459 23.2206 34.3826 21.5046 36.1114 19.7793C40.3057 15.591 44.4974 11.4014 48.6852 7.20788C50.3862 5.50376 50.4364 2.91386 48.8161 1.27848C47.1402 -0.413746 44.5781 -0.433575 42.8414 1.29567C37.0624 7.04527 31.3033 12.816 25.5403 18.5815C25.3855 18.7362 25.2598 18.9226 25.0244 19.2121C23.7546 17.9072 22.5734 16.6672 21.3631 15.4562C16.6423 10.7351 11.9163 6.02068 7.1836 1.31153C5.46142 -0.401848 2.86493 -0.412426 1.20624 1.26129C-0.429957 2.91254 -0.40747 5.47071 1.30545 7.18673C7.06059 12.9601 12.829 18.7203 18.596 24.4805C18.7494 24.6338 18.9637 24.7277 19.2574 24.9207V24.9168Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseIcon;
