import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { editCso } from '../../../../redux/cso/csoActions';
import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';
import { FaCheck } from 'react-icons/fa6';
import SimpleInput from '../SimpleInput';
const CheckBoxContainer = styled.div`
  .checkbox-label {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  .interests-container {
    display: flex;
    margin-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 2px solid #d9d9d9;
  }
  /* for the customized checkbox */
  input[type='checkbox'] {
    display: none;
  }
  .check {
    color: white;
    transform: translate(110%, -15%);
    width: 14px;
    height: 14px;
    z-index: 100;
  }
  input[type='checkbox'] + label::before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }

  input:checked + label::before {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .interest {
    width: 200px;
    padding-bottom: 0.3rem;
    ${mobile(`
    width:150px
  `)}
  }
`;

const OtherInput = styled.input`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  outline: none;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  margin-top: 10px;
  resize: vertical;
  border-radius: 0.4rem;
  &::placeholder{
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

const CheckBoxInput = ({
  label,
  name,
  interests,
  selectedInterests,
  field_of_work_if_other,
}) => {
  const [updatedSelectedInterests, setUpdatedSelectedInterests] =
    React.useState(
      selectedInterests.map((interest) => interest?.field_of_interest?.id),
    );
  const dispatch = useDispatch();
  const method = useFormContext();
  const checkboxRefs = useRef([]);


  console.log(
    method.formState.errors
  );

  const stringForUpdated = updatedSelectedInterests
    .sort((a, b) => a - b)
    .map((id) => id.toString())
    .join(',');

  useEffect(() => {
    method.setValue('field_of_interest', stringForUpdated);
  }, [method, selectedInterests, stringForUpdated]);

  const handleCheckboxChange = (event, interestId) => {
    if (event.target.checked) {
      setUpdatedSelectedInterests((prevInterests) => [
        ...prevInterests,
        interestId,
      ]);
    } else {
      setUpdatedSelectedInterests((prevInterests) =>
        prevInterests.filter((id) => id !== interestId),
      );
    }
  };

  const handleIconClick = (index) => {
    const checkbox = checkboxRefs.current[index];
    if (checkbox) {
      checkbox.checked = !checkbox.checked;
      handleCheckboxChange({ target: checkbox }, parseInt(checkbox.value));
    }
  };

  const [isOtherChecked, setIsOtherChecked] = useState(() => {
    if (field_of_work_if_other !== null && field_of_work_if_other.length > 0) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <CheckBoxContainer>
      <div className="checkbox-container">
        <label htmlFor={name} className="checkbox-label">
          {label}:
        </label>
        <div className="interests-container">
          {interests.map((interest, index) => (
            <>
              {interest?.name === 'Other' ? (
                <>
                  <div key={index} className="interest">
                    <FaCheck
                      className="check"
                      onClick={() => {
                        handleIconClick(index);
                        setIsOtherChecked(!isOtherChecked);
                      }}
                    />
                    <input
                      ref={(el) => (checkboxRefs.current[index] = el)}
                      type="checkbox"
                      id={`${interest?.name}-${index}`}
                      name={`${interest?.name}-${index}`}
                      value={interest?.id}
                      checked={updatedSelectedInterests.some(
                        (item) => item === interest?.id,
                      )}
                      onChange={(event) => {
                        handleCheckboxChange(
                          event,
                          parseInt(event.target.value),
                        );
                        setIsOtherChecked(!isOtherChecked);
                      }}
                    />
                    <label htmlFor={`${interest?.name}-${index}`}>
                      &#160;&#160;{interest?.name}
                    </label>
                    {isOtherChecked && (
                    <OtherInput
                      type={'text'}
                      defaultValue={field_of_work_if_other}
                      placeholder="Please Specify:"
                      {...method.register('field_of_work_if_other', {
                        required: true,
                      })}
                    />
                  )}
                  </div>

                </>
              ) : (
                <div key={index} className="interest">
                  <FaCheck
                    className="check"
                    onClick={() => handleIconClick(index)}
                  />
                  <input
                    ref={(el) => (checkboxRefs.current[index] = el)}
                    type="checkbox"
                    id={`${interest?.name}-${index}`}
                    name={`${interest?.name}-${index}`}
                    value={interest?.id}
                    checked={updatedSelectedInterests.some(
                      (item) => item === interest?.id,
                    )}
                    onChange={(event) =>
                      handleCheckboxChange(event, parseInt(event.target.value))
                    }
                  />
                  <label htmlFor={`${interest?.name}-${index}`}>
                    &#160;&#160;{interest?.name}
                  </label>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </CheckBoxContainer>
  );
};

export default CheckBoxInput;
