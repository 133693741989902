import styled from '@emotion/styled';
import React, { useState } from 'react';
import { mobile } from '../../../../styles/media';
import SVG from '../../SVG';
import EditIcon from '../../../icons/EditIcon';
import ProfileModel from '../ProfileModel';
import { useTheme } from '@emotion/react';
import { toast } from 'react-toastify';
import { useSettings } from '../../../hooks/useSettings';
import ConfirmModel from '../ConfirmModel';

const TextWrapperContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 2rem;
  position: relative;
  ${mobile(`
      border-radius: 0;
    `)}
  h1 {
    color: ${({ theme }) => theme.colors.darkGrey} !important;
    font-weight: 500;
    margin-bottom: 1rem;
    ${({ theme }) =>
      mobile(`
          font-size: ${theme.fontSizes.xxl};
        `)}
  }
  .editIconContainer {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const TextWrapper = ({
  children,
  text,
  isEditable,
  FormComponent,
  closeIconColor,
}) => {
  const [active, setActive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const { isFormEdited, setIsFormEdited } = useSettings();
  const [isReturn, setIsReturn ] = useState(true);
  const handleModelStatus = (type) => {
    if (type === 'update') {
      window.location.reload();
    } else if (type === 'closed') {
      if (isFormEdited) {
        setPopupActive(true);
      }else{
        setActive(!active);
      }
    }else{
      setActive(!active);
    }
    

  };
  const theme = useTheme();
  return (
    <TextWrapperContainer>
      <h1>{text}</h1>
      {children}
      {isEditable && (
        <div
          className="editIconContainer"
          onClick={() => {
            handleModelStatus('');
            setIsFormEdited(false);
          }}
        >
          <SVG width={'25px'} height={'25px'} icon={<EditIcon />} color="" />
        </div>
      )}
      {popupActive && (
        <ConfirmModel setPopupActive={setPopupActive} setActive={setActive}/>
      )}
      {active && (
        <ProfileModel
          handleModelStatus={handleModelStatus}
          closeIconColor={closeIconColor ? closeIconColor : theme.colors.white}
          // isEditing={isEditing}
          // setIsEditing={setIsEditing}
          >
          {FormComponent}
        </ProfileModel>
      )}
    </TextWrapperContainer>
  );
};

export default TextWrapper;