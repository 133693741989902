import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  notifications: {},
  loading: false,
  error: '',
  notificationCheck: {},
  notificationCheckLoading: false,
  notificationCheckError: '',
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotificationsRequest(state) {
      state.loading = true;
      state.notifications = {};
      state.error = false;
    },
    getNotificationsSuccess(state, action) {
      state.loading = false;
      state.notifications = action.payload;
      state.error = false;
    },
    getNotificationsFail(state, action) {
      state.loading = false;
      state.notifications = {};
      state.error = action.payload;
    },

    checkNotificationsRequest(state) {
      state.notificationCheckLoading = true;
      state.notificationCheck = {};
      state.notificationCheckError = false;
    },
    checkNotificationsSuccess(state, action) {
      state.notificationCheckLoading = false;
      state.notificationCheck = action.payload;
      state.notificationCheckError = false;
    },
    checkNotificationsFail(state, action) {
      state.notificationCheckLoading = false;
      state.notificationCheck = {};
      state.notificationCheckError = action.payload;
    },
    reset(state) {
      state.notificationCheck = {};
      state.notificationCheckLoading = false;
      state.notificationCheckError = '';
    },
  },
});
export const notificationsActions = notificationsSlice.actions;
export default notificationsSlice;
