import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { mobile } from '../../../../../styles/media';
import PrimaryWrapper from '../../../../common/Providers/PrimaryWrapper';
import HeartContainer from '../../../../common/HeartContainer';
import ItemTag from '../../../../common/ItemTag';
import { addFav, deleteFav } from '../../../../../redux/fav/favActions';
import PrimaryConnectButton from '../../../../common/Buttons/PrimaryConnectButton';
import { addConnection } from '../../../../../redux/connect/connectActions';
import { Link } from 'react-router-dom';
import { favoriteTextTransform } from '../../../../utils/favoriteTextTransform';

const CardContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  white-space: nowrap;
  ${mobile(`
        flex-wrap:wrap;
        gap:0.6rem;
        justify-content:space-between;
      
        `)}

  .connection_status {
    font-style: italic;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
  }
  .image-container {
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    ${mobile(`
            width: 85px;
            height: 85px;
        `)}
  }
  h4 {
    ${mobile(`
           font-size:0.7rem;
        `)}
  }
  .info-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .flex {
      display: flex;
      gap: 0.5rem;
      h2 {
        ${mobile(`
           font-size:1rem;
        `)}
      }
      p {
        display: flex;
        align-items: end;
        ${mobile(`
           font-size:0.6rem;
        `)}
      }
    }
    .tag {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      h2 {
        ${mobile(`
           font-size:1rem;
        `)}
      }
      p {
        /* display: flex; */

        ${mobile(`
           font-size:0.6rem;
        `)}
      }
      ${mobile(`
           flex-direction: column;
           text-align: center;
        `)}
    }
    p {
      width: 100%;
    }
    .connection_status {
      font-style: italic;
      color: ${({ theme }) => theme.colors.primary};
      display: flex;
      align-items: center;
    }
  }
  .right-container {
    display: flex;
    gap: 0.5rem;
    position: absolute;
    top: 1.2rem;
    right: 0;
    margin: 1rem;
    ${mobile(`
      position: relative;
        `)}
  }
  .connect-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    ${mobile(`
          gap: 0.5rem ;
        `)}
  }
`;
function CsoCard({ cso , connected_user }) {
  const dispatch = useDispatch();
  const [showAllFields, setShowAllFields] = useState(false);

  const [favItem, setFavItem] = useState(cso?.favorites?.length > 0);
  const { fav, favLoading } = useSelector((state) => state.favReducer);
  const [active, setActive] = useState(false);

  const { connect } = useSelector((state) => state.invitationsReducer);

  const role = localStorage.getItem('role');
  const [notDisplayConnectButton, setNotDisplayConnectButton] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('');
  console.log(cso);

  useEffect(() => {
    // Only update the state if the loading is complete
    if (!favLoading && fav.length > 0) {
      setFavItem(true);
    }
  }, [favLoading, fav]);


  useEffect(() => {
    // Only update the state if the loading is complete
    if (cso?.favorites?.length > 0 > 0) {
      setFavItem(true);
    }else{
      setFavItem(false);
    }
  }, [cso]);


  useEffect(() => {
    if (connected_user?.length > 0) {
      connected_user.forEach((connection) => {
        if (connection?.id === cso?.id) {
          setNotDisplayConnectButton(true);
          setConnectionStatus(connection?.type)
        }
      });
    }
  }, [connected_user, cso?.id]); 


  return (
    <Link
      to={`/view/cso/${cso?.user_id}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <PrimaryWrapper padding={'2rem'}>
        <CardContainer>
          <div className="image-container">
            <img
              src={`${process.env.REACT_APP_API}/user/${cso?.organisation_logo}`}
              alt="ImageNotFound"
            />
          </div>
          <div className="info-container">
            <h2>{cso?.organisation_name}</h2>

            <div className="flex">
            <h4>Status: </h4>
            <p>CSO</p>
          </div>

            {/* <div className="flex">
              <h4>Type: </h4>
              <p>{cso?.type_of_organisation}</p>
            </div> */}
            

            <h4>Focus Area</h4>
            <div className="tag">
              {cso?.csos_field_of_interests?.slice(0, 3).map((item, key) => (
                <>
                  {item?.field_of_interest?.name === 'Other' ? <ItemTag key={key} text={cso?.field_of_work_if_other} /> : <ItemTag key={key} text={item?.field_of_interest?.name} />}
                </>
              ))}
              {cso?.csos_field_of_interests?.length > 2 && !showAllFields && (
                <span onClick={() => setShowAllFields(true)}>...</span>
              )}
            </div>

            <h4>Country of Operation</h4>
            <div className="tag">
              <ItemTag text={cso?.countryOfOperation?.name} />
            </div>
          </div>
          <div className="right-container">
            <div className="connect-section">
              {/* <HeartContainer
              id={cso?.id}
              favorite={cso?.favorites?.length > 0 || favItem ? false : true}
              onClick={() => {
                if (!favItem) {
                  const token = localStorage.getItem('token');
                  dispatch(addFav(cso?.id, token));
                  setFavItem(!favItem);
                }
              }}
            /> */}

              {/* {cso?.connects?.length == 0 && <ConnectButton id={cso?.id} />}
          {cso?.connects?.length !== 0 && (
            <div className="connection_status">Connected</div>
          )} */}
              {role == 3 && (
                <>
                  <HeartContainer
                    id={cso?.id}
                    favorite={favItem ? false : true}
                    onClick={() => {
                      const token = localStorage.getItem('token');
                      if (!favItem) {
                        dispatch(addFav(cso?.id, token));
                        setFavItem(!favItem);
                      } else {
                        dispatch(deleteFav(cso?.id, token));
                        setFavItem(!favItem);
                      }
                    }}
                  />
                  {cso?.connects.length > 0 || active ? (
                    <span className="connection_status">
                      {favoriteTextTransform(cso?.connects[0]?.type) ||
                        (active && 'Pending')}
                    </span>
                  ) : (
                    (!notDisplayConnectButton ? (<PrimaryConnectButton
                      id={cso?.id}
                      label={'Connect'}
                      onClick={() => {
                        if (!cso?.connects.length > 0) {
                          const token = localStorage.getItem('token');
                          dispatch(addConnection(cso?.id, token));
                          setActive(true);
                        }
                      }}
                    />) : <span className="connection_status"> {connectionStatus === 'Accept' ? 'Connected' : connectionStatus === 'Reject' ? 'Rejected' : connectionStatus}</span>)
                  )}
                </>
              )}
            </div>
          </div>
        </CardContainer>
      </PrimaryWrapper>
    </Link>
  );
}

export default CsoCard;
