import { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { myInfo } from '../../redux/auth/authactions';
import Loading from '../common/Loading';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { InfoLoading } = useSelector((store) => store.authReducer);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp > currentTime) {
        setIsLoggedIn(true);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        dispatch(myInfo());
      } else {
        delete axios.defaults.headers.common['Authorization'];
        logout();
      }
    }
  }, []);

  const LoginFunction = (token) => {
    // Set token in local storage
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Clear token from local storage
    delete axios.defaults.headers.common['Authorization'];
    localStorage.clear();
    setIsLoggedIn(false);
    window.location.href = '/';
  };

  if (InfoLoading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, LoginFunction, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
