import styled from '@emotion/styled';
import React from 'react';
import SVG from '../../SVG';
import { useTheme } from '@emotion/react';
import CloseIcon from './../../../icons/CloseIcon';
import { mobile } from '../../../../styles/media';

const ProfileModelContainer = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  .main-content {
    width: 70%;
    min-height: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-radius: 1.5rem;
    ${mobile(`
      width: 100%;
    `)}

    .main-content {
      scrollbar-width: thin;
      scrollbar-color: ${({ theme }) => theme.colors.gray};
      ${({ theme }) => theme.colors.lightGray};

      ${mobile(`
          height: 100vh;
        `)}
      .main-content::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.colors.lightGray};
      }

      .main-content::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.gray};
        border-radius: 10px;
      }

      .main-content::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.colors.darkGray};
      }
      .close-icon-container {
        z-index: 5;
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
      .main-content::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.colors.darkGray};
      }
      ${mobile(`
    border-radius: 0;
      width: 100%;
    `)}
    }
  }
  .close-icon-container {
    z-index: 5;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .black-background {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.6;
  }
`;

const ProfileModel = ({ children, handleModelStatus, closeIconColor }) => {
  const theme = useTheme();
  return (
    <ProfileModelContainer>
      <div className="main-content">
        <div className="close-icon-container" onClick={() => handleModelStatus('closed')}>
          <SVG
            width={'30px'}
            height={'30px'}
            color={closeIconColor ? closeIconColor : theme.colors.white}
            icon={<CloseIcon />}
          />
        </div>
        {children}
      </div>
      <div className="black-background" onClick={() => handleModelStatus('closed')} />
    </ProfileModelContainer>
  );
};

export default ProfileModel;
