import styled from '@emotion/styled';
import React, { useEffect, useState, useRef } from 'react';
import SVG from '../../../common/SVG';
import { useTheme } from '@emotion/react';
import ToggleIcon from '../../../icons/ToggleIcon';
import { mobile } from '../../../../styles/media';
import TrashIcon from '../../../icons/TrashIcon';

const FilterItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${mobile(`
    flex-direction:column;
    gap: 0.5rem;
    width:100%;
  `)}
  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    cursor: pointer;
    span {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }

  ul {
    position: absolute;
    left: 0;
    top: 2rem;
    background-color: ${({ theme }) => theme.colors.white};
    list-style: none;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.shadows.filterItem};
    display: flex;
    flex-direction: column;
    z-index: 1;
    min-width: 10px;
    ${mobile(`
        top: 100%;
        position: relative;
        left: 0;
        width: 100%;
        border-radius: 0;
        padding: 0.5rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
    `)}
    li {
      display: flex;
      justify-content: start;
      gap: 0.5rem;
      white-space: nowrap;
    }
    .clear{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      span{
        color: ${({theme}) => theme.colors.red};
      }
    }
  }
`;

const FilterItem = ({
  options,
  label,
  type,
  filter,
  setFilter,
  filterProp,
  selectedValuesSaved
}) => {
  const theme = useTheme();
  // console.log('selectedValuesSavedselectedValuesSaved : ' , selectedValuesSaved)
  const [selectedOptions, setSelectedOptions] = useState(selectedValuesSaved);

  const listItemRef = useRef(null);


  useEffect(() => {
    setSelectedOptions(selectedValuesSaved);
  }, [selectedValuesSaved]);

  // console.log("selectedOptions:", selectedOptions);

  const [active, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!active);
  };

  useEffect(() => {
    setActive(false);
  }, [filter.filterStatus]);

  const handleOptionToggle = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (type === 'radio') {
        // For radio buttons, replace the selected options with the clicked option
        return [option];
      } else {
        // For checkboxes, toggle the selection status
        if (prevSelectedOptions.includes(option)) {
          // Deselect the option if it was previously selected
          return prevSelectedOptions.filter((item) => item !== option);
        } else {
          // Select the option if it was not previously selected
          return [...prevSelectedOptions, option];
        }
      }
    });
  };

  const handleClickOutside = (event) => {
    if (listItemRef.current && !listItemRef.current.contains(event.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if (active) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [active]);
  
  useEffect(() => {
    switch (filterProp) {
      case 'country':
        if (filter.filterStatus === 'cso') {
          setFilter((prevFilter) => ({
            ...prevFilter,
            cso: {
              ...prevFilter.cso,
              country: selectedOptions,
            },
          }));
        } else {
          setFilter((prevFilter) => ({
            ...prevFilter,
            fellow: {
              ...prevFilter.fellow,
              country: selectedOptions,
            },
          }));
        }

        break;
      case 'interests':
        if (filter.filterStatus === 'cso') {
          setFilter((prevFilter) => ({
            ...prevFilter,
            cso: {
              ...prevFilter.cso,
              interests: selectedOptions,
            },
          }));
        } else {
          setFilter((prevFilter) => ({
            ...prevFilter,
            fellow: {
              ...prevFilter.fellow,
              interests: selectedOptions,
            },
          }));
        }
        break;
      case 'type_of_organisation':
        if (filter.filterStatus === 'cso') {
          setFilter((prevFilter) => ({
            ...prevFilter,
            cso: {
              ...prevFilter.cso,
              type_of_organisation: selectedOptions,
            },
          }));
        } else {
          setFilter((prevFilter) => ({
            ...prevFilter,
            fellow: {
              ...prevFilter.fellow,
              expertise: selectedOptions,
            },
          }));
        }
        break;

      case 'expertise':
        if (filter.filterStatus === 'cso') {
          setFilter((prevFilter) => ({
            ...prevFilter,
            cso: {
              ...prevFilter.cso,
              expertise: selectedOptions,
            },
          }));
        } else {
          setFilter((prevFilter) => ({
            ...prevFilter,
            fellow: {
              ...prevFilter.fellow,
              expertise: selectedOptions,
            },
          }));
        }
        break;

      default:
        break;
    }
  }, [selectedOptions]);

  return (
    <FilterItemContainer ref={listItemRef}>
      <div className="center-container" onClick={handleToggle}>
        <span>{label}</span>
        <SVG
          width={'10px'}
          height={'10px'}
          color={theme.colors.secondary}
          icon={<ToggleIcon />}
        />
      </div>

      {active && (
        <ul>
          {type === 'radio' && selectedOptions.length > 0 && (
            <li onClick={() => setSelectedOptions([])} className='clear'>
              <span>Clear</span>
              <SVG
                width={'15px'}
                height={'15px'}
                color={theme.colors.red}
                icon={<TrashIcon />}
              />
            </li>
          )}
          {options.map((option, index) => (
            <li key={index}>
              <input
                type={type === 'radio' ? 'radio' : 'checkbox'} // Use radio type if type is "radio"
                checked={selectedOptions.includes(
                  type === 'radio' ? option : option.id,
                ) }
                onChange={() =>
                  handleOptionToggle(type === 'radio' ? option : option.id)
                }
              />
              {type === 'radio' ? option : option.name}
            </li>
          ))}
        </ul>
      )}
    </FilterItemContainer>
  );
};

export default FilterItem;
