import axios from 'axios';
import { favActions } from './favReducers';

export const addFav = (id, token) => async (dispatch) => {
  try {
    dispatch(favActions.FavRequest());

    const role = localStorage.getItem('role');
    const data = role == 3 ? { cso_id: id } : { fellow_id: id };

    let response = await axios.post(
      process.env.REACT_APP_API + '/favorite/create',
      data,
      { Authorization: 'Bearer ' + token },
    );

    dispatch(favActions.FavSuccess(response.data.data));
  } catch (e) {
    dispatch(favActions.FavFail(e.message));
  }
};

export const deleteFav = (id, token) => async (dispatch) => {
  try {
    dispatch(favActions.FavDeleteRequest());

    const role = localStorage.getItem('role');
    const data = role == 3 ? { cso_id: id } : { fellow_id: id };

    let response = await axios.delete(
      process.env.REACT_APP_API + '/favorite/delete',
      { data: data, headers: { Authorization: 'Bearer ' + token } },
    );

    dispatch(favActions.FavDeleteSuccess(response.data.data));
  } catch (e) {
    dispatch(favActions.FavDeleteFail(e.message));
  }
};

export const getAllFavorites = (filter) => async (dispatch) => {
  try {
    dispatch(favActions.AllFavRequest());

    const countryParams = filter.fellow.country
      ? filter.fellow.country.join(',')
      : '';
    const interestsParams = filter.fellow.interests
      ? filter.fellow.interests.join(',')
      : '';
    const expertiseParams = filter.fellow.expertise
      ? filter.fellow.expertise.join(',')
      : '';

    const queryParams = {
      country_of_residency: countryParams,
      field_of_interest: interestsParams,
      expertise: expertiseParams,
      page: filter.fellow.page,
      limit: filter.fellow.limit,
      search: filter.search,
    };

    const response = await axios.get(
      process.env.REACT_APP_API + '/favorite/getByUser',
      {
        params: queryParams,
      },
    );

    dispatch(favActions.AllFavSuccess(response.data.data));
    dispatch(favActions.FavSuccess(response.data));
  } catch (e) {
    dispatch(favActions.AllFavFail(e.message));
  }
};
