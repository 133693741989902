import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { mobile } from '../../../styles/media';
import { useBreakpoint } from './../../hooks/useBreakpoint';
import Header from '../../common/Header';
import HeaderProfile from './CsoContact';
import BasicInfoCso from './BasicInfoCso';
import { dateProfileFormatter } from '../../utils/dateProfileFormatter';
import TextWrapper from '../../common/Providers/TextWrapper';
import PreselectionData from './PreselectionData';
import CsoProfileForm from './CsoProfileForm';
import BurgerIcon from '../../common/Burger';

const CsoProfilePageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  overflow-y: auto;

  ${mobile(`
    padding: 0;
  `)}
  .center-content {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    ${mobile(`
      border-radius: 0;
    `)}
    .burger-container {
      position: absolute;
      top: 0;
    }
  }
`;

const CsoProfilePage = ({ handleSidebarToggle }) => {
  const { user } = useSelector((store) => store.authReducer);
  const { isMobile } = useBreakpoint();
  const [active, setActive] = useState(true);
  console.log("user :", user);

  return (
    <CsoProfilePageContainer>
      <div className="center-content">
        {!isMobile ? (
          <Header
            TitleName={'My Profile'}
            SubName={user?.info?.organisation_name}
            active={active}
          />
        ) : (
          <div className="burger-container">
            <BurgerIcon handleSidebarToggle={handleSidebarToggle} />
          </div>
        )}
        <HeaderProfile
          websiteUrl={user?.info?.organisation_social_media}
          isConnect={false}
          myProfile={true}
          isVerified={user?.user?.isVerified_account}
          organisation_logo={user?.info?.organisation_logo}
          organisation_name={user?.info?.organisation_name}
        />
        <TextWrapper
          text={'Basic Information'}
          isEditable={true}
          FormComponent={
            <CsoProfileForm
              organisation_name={user?.info?.organisation_name}
              organisation_social_media={user?.info?.organisation_social_media}
              country={user?.country}
              country_of_operation_id={user?.info?.country_of_operation_id}
              ideal_fellow={user?.info?.ideal_fellow}
              organisation_description={user?.info?.organisation_description}
              organisation_logo={user?.info?.organisation_logo}
              number_of_employees={user?.info?.number_of_employees}
              EmployeesList={user?.number_of_employees}
              typeOrganisationList={user?.type_of_organisation}
              type_of_organisation={user?.info?.type_of_organisation}
              field_of_work_if_other={user?.info?.field_of_work_if_other}
              motivation_for_fellowship={user?.info?.motivation_for_fellowship}
            />
          }
        >
          <BasicInfoCso
            country={user?.country.map((item) => {
              if (item.id === user?.info?.country_of_operation_id)
                return item.name;
            })}
            date={dateProfileFormatter(user?.info?.date_of_creation)}
            employeeNumber={`${user?.info?.number_of_employees} employees`}
            description={user?.info?.organisation_description}
            intrested={user?.info?.csos_field_of_interests}
            ideal_fellow={user?.info?.ideal_fellow}
            motivation_for_fellowship={user?.info?.motivation_for_fellowship}
            websiteUrl={user?.info?.organisation_social_media}
            type={user?.info?.type_of_organisation}
            otherInterest={user?.info?.field_of_work_if_other}
          />
        </TextWrapper>
        <TextWrapper text={'Private Data'}>
          <PreselectionData
            founderName={user?.info?.founder_name}
            founderNationality={user?.info?.founder_nationality}
            country={user?.country.map((item) => {
              if (item.id === user?.info?.country_of_registration_id)
                return item.name;
            })}
            oldYearBudget={user?.info?.old_year_budget}
            nextYearBudget={user?.info?.next_year_budget}
            sustainabilityWork={user?.info?.sustainability_work}
            registration_certificate={user?.info?.registration_certificate}
            preIdentified_fellow={user?.info?.pre_identified_fellow}
            first_name_contact={user?.info?.first_name_contact}
            last_name_contact={user?.info?.last_name_contact}
            email={user?.info?.email_address_contact}
            multaqaAttendance={
              user?.info?.multaqa_attendance
                ? 'Yes, Attended'
                : 'No, Did not attend'
            }
            programsAndGrantExperience={
              user?.info?.programs_and_grant_experience
            }
            role_in_organisation_contact={user?.info?.role_in_organisation_contact}
            country_of_residency_contact_id={user?.country.map((item) => {
              if (item.id === user?.info?.country_of_residency_contact_id)
                return item.name;
            })}
            phone_number_contact={user?.info?.phone_number_contact}
          />
        </TextWrapper>
        <br />
      </div>
    </CsoProfilePageContainer>
  );
};

export default CsoProfilePage;
