import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  signupFellow,
  signupOrg,
  login,
} from '../../../../redux/auth/authactions';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';

const LoginContainer = styled.div`
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  ${mobile(`
    width: 100%;
  `)}

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.s};
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  input {
    font-size: ${({ theme }) => theme.fontSizes.m};
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    outline: none;
  }

  button {
    font-size: ${({ theme }) => theme.fontSizes.m};
    background-color: ${({ theme }) => theme.colors.primary};
    border: none;
    color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryBlue};
    }
  }
`;

const Login = ({ setEmail, email, page, isFellow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { errorLogin, loginLoading, isAuthenticated } = useSelector(
    (store) => store.authReducer,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    //if email exists
    if (email) {
      //if in the signup page
      if (page === 'signUp') {
        if (isFellow) dispatch(signupFellow(email, navigate));
        //if the sign up as organization send email and navigate to otp verification for organization
        else dispatch(signupOrg(email, navigate));

        //if in the signin page  send email and navigate to otp verification
      } else if (page === 'login') {
        dispatch(login(email, navigate));
      }
    }
  };

  return (
    <LoginContainer>
      <h1>{t('register.sign_in')}</h1>
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          // className="input_field"
          placeholder={t('register.email_add')}
        />
        {errorLogin && <div className="register_error">{errorLogin}</div>}
        <button>
          {loginLoading ? (
            <>
              <AiOutlineLoading3Quarters className="fa-spin" />{' '}
              {t('register.logging')}
            </>
          ) : (
            t('register.sign_in')
          )}
        </button>
        <p>
          {t('register.receive')}{' '}
          <span className="green-color">{t('register.email')}</span>
        </p>
      </form>
      {/* {errorLogin && (
        <>
          <div className="divider_line"></div>
          <p className="register_note">{t("register.account")}</p>
          <div className="register_button_create_cont">
            <button
              className="register_button"
              onClick={() => navigate("/auth/signUp")}
            >
              {t("register.new")}
            </button>
          </div>
        </>
      )} */}
    </LoginContainer>
  );
};

export default Login;
