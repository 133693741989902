import styled from '@emotion/styled';
import { mobile } from '../../../../styles/media';

const SubmitButtonContainer = styled.button`
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: 0.8rem 3rem;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) =>
    mobile(`
      font-size: ${theme.fontSizes.xs};
      padding: 0.6rem 2rem;
      border-radius: 2rem;
    `)}
`;

const SubmitButton = ({ text }) => {
  return <SubmitButtonContainer type="submit">{text}</SubmitButtonContainer>;
};

export default SubmitButton;
