import styled from '@emotion/styled';
import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@emotion/react';
import PrimaryWrapper from '../Providers/PrimaryWrapper';
import SVG from '../SVG';
import HeartIcon from './../../icons/HeartIcon';
import EmptyHeart from './../../icons/EmptyHeart';
import FilterItem from '../../pages/Search/FilterItem';
import { mobile } from '../../../styles/media';

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  position: relative;
  ${mobile(`
    font-size:13px;
    flex-direction:column;
  `)}
  .favorite-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    span {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
  .status-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      width: 40px;
      border: 2px solid ${({ theme }) => theme.colors.primary};
      position: relative;
      height: 18px;
      border-radius: 2rem;
      background-color: ${({ theme }) => theme.colors.primary};
      .ball {
        position: absolute;
        transition: all 0.1s ease-in-out;
        top: 50%;
        transform: translateY(-50%);
        left: ${({ filterStatus }) =>
          filterStatus === 'cso' ? '-0.2rem' : 'calc(100% - 1rem)'};
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
  }
  &::after {
    ${props => props.resultNumber && `content: '${props.resultNumber} results';`}
    position: absolute;
    right: 0;
    top: 0;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

function Filters({
  filterStatus,
  isFavorite,
  country,
  interests,
  types,
  filter,
  expertise,
  setFilter,
  setFilterStatus,
  resultNumber
}) {
  const theme = useTheme();
//  console.log('filterfilterfilterfilter: ' , filter)
  return (
    <PrimaryWrapper padding={'1rem'} overflow={true}>
      <FilterContainer resultNumber={resultNumber} filterStatus={filterStatus}>
        <div
          className="favorite-filter"
          onClick={() =>
            setFilter({
              ...filter,
              isFavorite: !filter.isFavorite,
            })
          }
        >
          {filter.isFavorite ? (
            <SVG
              width={'22px'}
              height={'22px'}
              icon={<HeartIcon />}
              color={theme.colors.secondary}
            />
          ) : (
            <SVG width={'22px'} height={'22px'} icon={<EmptyHeart />} />
          )}
          <span>Favorites</span>
        </div>

        {filter.filterStatus === 'cso' ? (
          <>
            <FilterItem
              label="Country of Operations"
              options={country}
              filterProp={'country'}
              filter={filter}
              setFilter={setFilter}
              selectedValuesSaved={filter.filterStatus === 'cso' ? filter?.cso['country']  : filter?.fellow['country']}

            />
            <FilterItem
              label="Focus Area"
              options={interests}
              filterProp={'interests'}
              filter={filter}
              setFilter={setFilter}
              selectedValuesSaved={filter.filterStatus === 'cso' ? filter?.cso['interests']  : filter?.fellow['interests']}


            />
            <FilterItem
              label="Types"
              options={types}
              type={'radio'}
              filter={filter}
              setFilter={setFilter}
              filterProp={'type_of_organisation'}
              selectedValuesSaved={filter.filterStatus === 'cso' ? filter?.cso['type_of_organisation']  : filter?.fellow['type_of_organisation']}

            />
          </>
        ) : (
          <>
            <FilterItem
              label="Country of Residency"
              options={country}
              filterProp={'country'}
              filter={filter}
              setFilter={setFilter}
              selectedValuesSaved={filter.filterStatus === 'cso'? filter?.cso['country'] : filter?.fellow['country'] }

            />
            <FilterItem
              label="Interest"
              options={interests}
              filterProp={'interests'}
              filter={filter}
              setFilter={setFilter}
              selectedValuesSaved={filter.filterStatus === 'cso' ? filter?.cso['interests']  : filter?.fellow['interests']}

            />
            <FilterItem
              label="Expertise"
              options={expertise}
              filterProp={'expertise'}
              filter={filter}
              setFilter={setFilter}
              selectedValuesSaved={filter.filterStatus === 'cso' ? filter?.cso['expertise'] : filter?.fellow['expertise']}

            />
          </>
        )}

        <div className="status-switch">
          <div className={'cso'}>CSO</div>
          <div className="toggle">
            <div
              className="ball"
              onClick={() =>
                setFilter({
                  ...filter,
                  filterStatus:
                    filterStatus === 'cso'
                      ? 'fellow'
                      : filterStatus === 'fellow'
                        ? 'cso'
                        : 'cso',
                })
              }
            />
          </div>
          <div className={'fellow'}>Fellow</div>
        </div>
            {/* <span>{resultNumber}</span> */}
      </FilterContainer>
    </PrimaryWrapper>
  );
}

export default Filters;
