export const mobile = (styles) => `
  @media (max-width: 768px) {
    ${styles}
  }
`;

export const desktop = (styles) => `
  @media (min-width: 769px) and (max-width: 1300px) {
    ${styles}
  }
`;

export const desktopLarge = (styles) => `
  @media (min-width: 1301px) {
    ${styles}
  }
`;
