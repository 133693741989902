import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OTPInput from 'otp-input-react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { login } from '../../../../redux/auth/authactions';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const ValidationContainer = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    text-align: start;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }

  button {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem 0%.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.s};
    transition: 0.3s;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryBlue};
    }
  }
  .register_sent {
    color: ${({ theme }) => theme.colors.bottomGrey};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    text-align: center;
  }
`;

const Validation = ({ email, otpVal, setOtpVal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { errorLogin, loginLoading, OTPLoading, response, OTPError } =
    useSelector((store) => store.authReducer);

  return (
    <ValidationContainer>
      <h1>{t('register.sign_in')}</h1>
      <p>
        {t('register.otp_Msg')}{' '}
        <span className="green_color">{t('register.email')}</span>
      </p>
      <div className="register_otp_cont">
        <OTPInput
          value={otpVal}
          onChange={setOtpVal}
          OTPLength={4}
          otpType="number"
          inputStyles={{
            width: '20%',
            textAlign: 'center',
            marginRight: '20px',
            borderWidth: '0px 0px 1.5px 0px',
            fontSize: '1.5rem',
            borderBottomColor: 'var(--bottom-grey)',
          }}
        />
        {(OTPError || errorLogin) && (
          <div
            className="register_error"
            style={{ marginTop: '1rem', marginBottom: '-1rem' }}
          >
            {OTPError ? OTPError : errorLogin}
          </div>
        )}
      </div>

      {(OTPLoading || loginLoading) && (
        <AiOutlineLoading3Quarters
          style={{
            margin: 'auto',
            color: 'var(--primary)',
            justifyContent: 'center',
            display: 'flex',
            marginBottom: '1rem',
          }}
          className="fa-spin"
        />
      )}

      <p>{t('register.otp_qst')} </p>
      <button
        onClick={() => {
          dispatch(login(email, navigate));
          setOtpVal('');
        }}
      >
        {t('register.otp_resend')}{' '}
      </button>
      {response && (
        <div className="register_sent">{t('register.otp_is_res')}</div>
      )}
    </ValidationContainer>
  );
};

export default Validation;
