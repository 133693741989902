import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import Form from '../../../common/Providers/Form';
import { useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import SubmitButton from '../../../common/Buttons/SubmitButton';
// import { editFellow } from "../../../../redux/Fellow/Fellowactions";
import { useDispatch } from 'react-redux';
import PrimaryButton from '../../../common/Buttons/PrimaryButton';
import ItemTag from '../../../common/ItemTag';
import { myInfo } from '../../../../redux/auth/authactions';
import LoadingModel from '../../../common/LoadingModel';
import { editFellow } from '../../../../redux/fellow/fellowActions';
import {
  FellowProfileFormContainer,
  StyledScrollbarContainer,
} from './common/styled';
import { useSettings } from '../../../hooks/useSettings';
import { toast } from 'react-toastify';

const OtherInput = styled.input`
  width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  outline: none;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  margin-top: 10px;
  resize: vertical;
  border-radius: 0.4rem;
  &::placeholder {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

const schema = z.object({
  fellows_field_of_interests: z.array(z.number()).nonempty(),
  fellows_expertises: z.array(z.number()).nonempty(),
  CV: z
    .any()
    .refine(
      (value) => {
        if (!(value instanceof File)) return false;

        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const extension = value.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(extension)) return false;

        return true;
      },
      {
        message: 'CV file must be a PDF, DOC, or DOCX file.',
      },
    )
    .optional(),
  field_of_work_if_other: z.string().optional().nullable(),
});
const Inputs = ({
  IntersetedList,
  fellows_field_of_interests,
  CV,
  fellows_expertises,
  expertiseList,
  field_of_work_if_other,
  isOtherChecked,
  setIsOtherChecked,
}) => {
  const method = useFormContext();

  const { setIsFormEdited } = useSettings();

  const [value, setValue] = useState(null);

  // Initialize selectedInterests with initialSelectedInterests
  const initialSelectedInterests = fellows_field_of_interests.map(
    (entry) => entry.field_of_interest_id,
  );

  const initialSelectedExpertise = fellows_expertises.map(
    (entry) => entry.expertise_id,
  );

  const [selectedInterests, setSelectedInterests] = useState(
    initialSelectedInterests,
  );

  const [selectedExpertise, setSelectedExpertise] = useState(
    initialSelectedExpertise,
  );

  useEffect(() => {
    method.setValue('fellows_field_of_interests', selectedInterests);
    method.setValue('fellows_expertises', selectedExpertise);
  }, [selectedInterests, selectedExpertise]);
  const handleInterestClick = (itemId) => {
    setSelectedInterests((prevInterests) => {
      if (prevInterests.includes(itemId)) {
        return prevInterests.filter((interest) => interest !== itemId);
      } else {
        return [...prevInterests, itemId];
      }
    });
  };

  const handleExpertiseClick = (itemId) => {
    setSelectedExpertise((prevInterests) => {
      if (prevInterests.includes(itemId)) {
        // If already selected, remove it
        return prevInterests.filter((interest) => interest !== itemId);
      } else {
        // If not selected, add it
        return [...prevInterests, itemId];
      }
    });
  };

  return (
    <>
      <div className="input-section">
        <p>Field of interest</p>
        <div className="list">
          {IntersetedList.map((item) => (
            <>
              {item.name === 'Other' ? (
                <>
                  <ItemTag
                    key={item.id}
                    text={item.name}
                    onClick={() => {
                      handleInterestClick(item.id);
                      setIsFormEdited(true);
                      setIsOtherChecked(!isOtherChecked);
                    }}
                    active={selectedInterests.includes(item.id) ? true : false}
                  />
                  {isOtherChecked && (
                    <OtherInput
                      type={'text'}
                      defaultValue={field_of_work_if_other}
                      placeholder="Please Specify:"
                      {...method.register('field_of_work_if_other', {
                        required: true,
                      })}
                    />
                  )}
                </>
              ) : (
                <ItemTag
                  key={item.id}
                  text={item.name}
                  onClick={() => {
                    handleInterestClick(item.id);
                    setIsFormEdited(true);
                  }}
                  active={selectedInterests.includes(item.id) ? true : false}
                />
              )}
            </>
          ))}
        </div>
      </div>
      <p className="error-message">
        {method?.formState?.errors?.fellows_field_of_interests?.message}
      </p>

      <div className="input-section">
        <p>Expertise</p>
        <div className="list">
          {expertiseList.map((item) => (
            <ItemTag
              key={item.id}
              text={item.name}
              onClick={() => {
                handleExpertiseClick(item.id);
                setIsFormEdited(true);
              }}
              active={selectedExpertise.includes(item.id) ? true : false}
            />
          ))}
        </div>
      </div>
      <p className="error-message">
        {method?.formState?.errors?.fellows_expertises?.message}
      </p>

      {value && <p className="success">CV is Uploaded Successfully</p>}

      <div className="button-container">
        <div className="upload-cv">
          <PrimaryButton text={'Upload your CV'} />
          <input
            type="file"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                const allowedExtensions = ['pdf', 'doc', 'docx'];
                const extension = file.name.split('.').pop().toLowerCase();

                if (!allowedExtensions.includes(extension)) {
                  alert('CV file must be a PDF, DOC, or DOCX file.');
                  // Reset the input field
                  e.target.value = '';
                  return;
                }

                // File validation passed, set the value
                method.setValue('CV', file);
                setValue(file);
              } else {
                // No file selected, reset the value
                method.setValue('CV', null);
                setValue(null);
              }
            }}
          />
        </div>
        <div className="submit-button-container">
          <SubmitButton text={'Save'} />
        </div>
      </div>
      <p className="error-message">{method?.formState?.errors?.CV?.message}</p>
    </>
  );
};

const FellowProfileForm = ({
  fellows_field_of_interests,
  IntersetedList,
  fellows_expertises,
  expertiseList,
  field_of_work_if_other,
  CV,
}) => {
  const { formState: { errors }, setError } = useForm({
    resolver: zodResolver(schema)
  });

  const dispatch = useDispatch();

  const [isOtherChecked, setIsOtherChecked] = useState(() => {
    if (field_of_work_if_other !== null && field_of_work_if_other.length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const [otherId, setOtherId] = useState(() => {
    for (let i = 0; i < IntersetedList.length; i++) {
      if (IntersetedList[i].name === 'Other') {
        return IntersetedList[i].id;
      }
    }
  });

  const submit = (data) => {
    const InterstedString = data.fellows_field_of_interests
      .sort((a, b) => a - b)
      .map((id) => id.toString())
      .join(',');

    const ExpertiseString = data.fellows_expertises
      .sort((a, b) => a - b)
      .map((id) => id.toString())
      .join(',');

      if (
        data?.field_of_work_if_other?.length === 0 &&
        data?.fellows_field_of_interests?.length === 1 &&
        otherId == data?.fellows_field_of_interests[0]
      ) {
        toast.error('Other interest is required');
        return;
      }

    const formDataWithInterests = {
      CV: data.CV,
      field_of_interest:
        data.fellows_field_of_interests.length === 0 ? '' : InterstedString,
      fellow_expertise:
        data.fellows_expertises.length === 0 ? '' : ExpertiseString,
      field_of_work_if_other: data.field_of_work_if_other,
    };

    console.log(formDataWithInterests);

    dispatch(editFellow(formDataWithInterests));
  };

  return (
    <FellowProfileFormContainer>
      <Form
        option={{ resolver: zodResolver(schema) }}
        {...useForm()}
        onSubmit={submit}
      >
        <StyledScrollbarContainer>
          <div className="main-content">
            <h1 className="title">Interests & Skills</h1>
            <div className="form-container">
              <Inputs
                fellows_field_of_interests={fellows_field_of_interests}
                IntersetedList={IntersetedList}
                CV={CV}
                expertiseList={expertiseList}
                fellows_expertises={fellows_expertises}
                field_of_work_if_other={field_of_work_if_other}
                isOtherChecked={isOtherChecked}
                setIsOtherChecked={setIsOtherChecked}
              />
            </div>
          </div>
        </StyledScrollbarContainer>
      </Form>
    </FellowProfileFormContainer>
  );
};

export default FellowProfileForm;
