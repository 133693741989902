import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { mobile } from '../../../../styles/media';
import { useDispatch, useSelector } from 'react-redux';
import {
  rejectInvitation,
  getInvitations,
  getConnections,
} from '../../../../redux/connect/connectActions';
import { getCsoById } from '../../../../redux/cso/csoActions';
import { getFellowById } from '../../../../redux/fellow/fellowActions';

const RejectButtonContainer = styled.div`
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: 0.8rem 3rem;
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  ${({ theme }) =>
    mobile(`
      font-size: ${theme.fontSizes.xs};
      padding: 0.6rem 2rem;
      border-radius: 2rem;
    `)}
`;

const RejectButton = (props) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { rejectLoading } = useSelector((store) => store.invitationsReducer);
  const [reject, setReject] = useState(false);

  useEffect(() => {
    if (!rejectLoading && reject) {
      dispatch(getInvitations());
      dispatch(getConnections());
      if (props.cso_id != undefined) {
        dispatch(getCsoById(props.cso_id));
      }
      if (props.fellow_id != undefined) {
        dispatch(getFellowById(props.fellow_id));
      }
      setReject(false);
    }
  }, [rejectLoading, reject]);

  return (
    <RejectButtonContainer
      onClick={(event) => {
        event.stopPropagation();
        dispatch(rejectInvitation(props.id, token));
        setReject(true);
      }}
    >
      Reject Connection Request
    </RejectButtonContainer>
  );
};

export default RejectButton;
