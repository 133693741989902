export const countriesData = [
  {
    name: 'Afghanistan',
    phoneCode: '+93',
    flagImageUrl: 'https://flagcdn.com/af.png',
  },
  {
    name: 'Albania',
    phoneCode: '+355',
    flagImageUrl: 'https://flagcdn.com/16x12/al.png',
  },
  {
    name: 'Bahrain',
    phoneCode: '+973',
    flagImageUrl: 'https://flagcdn.com/16x12/bh.png',
  },
  {
    name: 'Cyprus',
    phoneCode: '+357',
    flagImageUrl: 'https://flagcdn.com/16x12/cy.png',
  },
  {
    name: 'Egypt',
    phoneCode: '+20',
    flagImageUrl: 'https://flagcdn.com/16x12/eg.png',
  },
  {
    name: 'Iran',
    phoneCode: '+98',
    flagImageUrl: 'https://flagcdn.com/16x12/ir.png',
  },
  {
    name: 'Iraq',
    phoneCode: '+964',
    flagImageUrl: 'https://flagcdn.com/16x12/iq.png',
  },
  {
    name: 'Israel',
    phoneCode: '+972',
    flagImageUrl: 'https://flagcdn.com/16x12/il.png',
  },
  {
    name: 'Jordan',
    phoneCode: '+962',
    flagImageUrl: 'https://flagcdn.com/16x12/jo.png',
  },
  {
    name: 'Kuwait',
    phoneCode: '+965',
    flagImageUrl: 'https://flagcdn.com/16x12/kw.png',
  },
  {
    name: 'Lebanon',
    phoneCode: '+961',
    flagImageUrl: 'https://flagcdn.com/w20/lb.jpg',
  },
  {
    name: 'Oman',
    phoneCode: '+968',
    flagImageUrl: 'https://flagcdn.com/16x12/om.png',
  },
  {
    name: 'Palestine',
    phoneCode: '+970',
    flagImageUrl: 'https://flagcdn.com/16x12/ps.png',
  },
  {
    name: 'Qatar',
    phoneCode: '+974',
    flagImageUrl: 'https://flagcdn.com/16x12/qa.png',
  },
  {
    name: 'Saudi Arabia',
    phoneCode: '+966',
    flagImageUrl: 'https://flagcdn.com/16x12/sa.png',
  },
  {
    name: 'Syria',
    phoneCode: '+963',
    flagImageUrl: 'https://flagcdn.com/16x12/sy.png',
  },
  {
    name: 'Turkey',
    phoneCode: '+90',
    flagImageUrl: 'https://flagcdn.com/16x12/tr.png',
  },
  {
    name: 'United Arab Emirates',
    phoneCode: '+971',
    flagImageUrl: 'https://flagcdn.com/16x12/ae.png',
  },
  {
    name: 'Yemen',
    phoneCode: '+967',
    flagImageUrl: 'https://flagcdn.com/16x12/ye.png',
  },
];
