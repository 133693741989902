import styled from '@emotion/styled';
import React from 'react';
import ItemTag from '../../../common/ItemTag';
import WebsiteIcon from '../../../icons/WebsiteIcon';
import SVG from '../../../common/SVG';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const BasicInfoCsoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .item {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  div {
    display: flex;
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .underline{
    display: flex;
    flex-direction: column;
  }
  b {
    margin-right: 0.4rem;
  }
`;

const BasicInfoCso = ({
  country,
  date,
  employeeNumber,
  description,
  intrested,
  ideal_fellow,
  motivation_for_fellowship,
  role,
  websiteUrl,
  type
}) => {
  const theme = useTheme()
  console.log(motivation_for_fellowship);
  const motivations = motivation_for_fellowship.split('\n');
  return (
    <BasicInfoCsoContainer>
      <div>
        <b>Status: </b>
        <span>CSO</span>
      </div>
      <div>
        <b>Type: </b>
        <span>{type}</span>
      </div>
      <div>
        <b>Country of Operation:</b>
        <span>{country}</span>
      </div>
      <div>
        <b>Date of Creation: </b>
        <span>{date}</span>
      </div>

      <div>
        <b>Number of Employees: </b>
        <span>{employeeNumber}</span>
      </div>
      <div className="item">
        <div>
          <b>CSO Description: </b>
        </div>
        <div>{description}</div>
      </div>
      <div className="item">
        <div>
          <b>Focus area: </b>
        </div>
        <div className="list">
          {intrested &&
            intrested.map((item) => (
              <ItemTag
                key={item?.field_of_interest?.id}
                text={item?.field_of_interest?.name}
              />
            ))}
        </div>
      </div>
      <div className="item">
        <div>
          <b>Ideal Fellow: </b>
        </div>
        <div>{ideal_fellow}</div>
      </div>
      <div className="item">
        <div>
          <b>Fellowship Motivation: </b>
        </div>
        <div className="underline">
        {motivations.map((motivation, index) => (
            <p key={index}>{motivation}</p>
          ))}
        </div>
      </div>
      {websiteUrl !== null && websiteUrl.length > 0 && (
        <div>
          <b>Website, Social Media or any other link: </b>
          <Link to={websiteUrl} target="_blank">
            <SVG
              width={'20px'}
              height={'20px'}
              color={theme.colors.primary}
              icon={<WebsiteIcon />}
            />
          </Link>
        </div>
      )}
    </BasicInfoCsoContainer>
  );
};

export default BasicInfoCso;
