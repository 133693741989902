import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import register from '../../icons/auth/lock.png';
import pass from '../../icons/auth/pass.png';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import Login from '../User/Login';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [isFellow, setIsFellow] = useState(true);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="register_main_container">
      <div className="register_card_main">
        <div className="register_card">
          <img className={'register_image'} src={register} />
          <Login
            email={email}
            setEmail={setEmail}
            isFellow={isFellow}
            page={'login'}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
