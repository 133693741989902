const BurgerIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L14 1" stroke="white" strokeLinecap="round" />
      <path d="M1 5.5H14" stroke="white" strokeLinecap="round" />
      <path d="M1 10.5H14" stroke="white" strokeLinecap="round" />
    </svg>
  );
};
export default BurgerIcon;
