import styled from '@emotion/styled';
import React, { useState } from 'react';
import LogoImage from '../../../assets/images/tabadol-logo.png';
import { useBreakpoint } from './../../hooks/useBreakpoint';
import { mobile } from '../../../styles/media';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import Image1 from '../../../assets/images/logo - makesense.png';
import Image2 from '../../../assets/images/Asfari Foundation - Logo.png';
import SVG from '../../common/SVG'
import LogoIcon from '../../icons/LogoIcon';

const SidebarContainer = styled.div`
  width: 100%;
  height: 100vh;
  transition: all 0.3 ease-in-out;
  z-index: 2;
  ${mobile(`
    position:absolute;
    top:0;
    display: flex;
    align-items:center;
    height:100vh;  
  `)}
  ${({ active }) =>
    active
      ? mobile(`
    left:0;
  `)
      : mobile(`
    left:-100%;
  `)}
  .sidebar-content {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-direction: column;
    ${mobile(`
      width: 50%;
    `)}
    .top-side {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2rem;
      img {
        width: 170px;
        object-fit: cover;
      }
    }
    .main-side {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 2rem;
      margin-top: 2rem;
      gap: 1rem;

      a {
        font-size: ${({ theme }) => theme.fontSizes.l};
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};
      }
      .active {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      }
    }
    .footer {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: end;
      padding-bottom: 2rem;
      ${mobile(`
        padding-bottom: 1rem;
      `)}
      .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
        flex-direction: column;
        ${mobile(`
          padding: 0 1rem;
        `)}
        .links {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
        }
        .image-container {
          width: 48%;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          ${mobile(`
            width:80%;
            height: 35px;
          `)}
          img {
            width: 100%;
            height: 100%;
            /* object-fit: cover; */
            object-fit: contain;
            ${mobile(`
              width: 80%;
            `)}
          }
        }
        .contact {
          white-space: nowrap;
          font-size: ${({ theme }) => theme.fontSizes.xs};
          padding: 1rem 0rem;
          font-weight: ${({ theme }) => theme.fontWeights.bold};
          color: ${({ theme }) => theme.colors.black};
          display: flex;
          align-items: center;
          gap: 0.2rem;
          ${({ theme }) =>
            mobile(`
            font-size:${theme.fontSizes.xxs};
          `)}
          a {
            color: ${({ theme }) => theme.colors.primary};
            width:100%;
            white-space: nowrap;
            text-decoration: none;
          }
        }
      }
    }
  }
  .black-background {
    ${mobile(`
      width:50%; 
      height:100%;
      background-color: rgba(0,0,0,.6);
    `)}
    ${({ active }) =>
      active
        ? mobile(`
        display:block;
      `)
        : mobile(`
        display:none;
      `)};
  }
`;

const Sidebar = ({ active, handleSidebarToggle }) => {
  const { isLoggedIn, logout } = useAuth();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [activeLink, setActiveLink] = useState(null);
  const { isMobile } = useBreakpoint();

  const role = localStorage.getItem('role');
  let link = '';
  switch (role) {
    case '4':
      link = '/cso_profile';
      break;
    case '3':
      link = '/fellow_profile';
      break;
    default:
      link = '#';
  }

  return (
    <SidebarContainer active={active} handleSidebarToggle={handleSidebarToggle}>
      <div className="sidebar-content">
        <a href="/" className="top-side">
          {/* <img src={LogoImage} alt="Logo" /> */}
          <SVG 
            width={"90%"}
            height={"100px"}
            icon={<LogoIcon />}
          />
        </a>
        {isLoggedIn ? (
          <div className="main-side">
            {isMobile && (
              <a href={link} className={currentUrl == { link } ? 'active' : ''}>
                My Profile
              </a>
            )}
            <a
              href="/search"
              className={currentUrl === '/search' ? 'active' : ''}
            >
              Search
            </a>
            <a
              href="/invitations"
              className={currentUrl === '/invitations' ? 'active' : ''}
            >
              Invitations
            </a>
            {isMobile && (
              <a
                href="/auth/login"
                className={currentUrl === '/auth/login' ? 'active' : ''}
                onClick={logout}
              >
                Log Out
              </a>
            )}
          </div>
        ) : (
          <div className="main-side">
            <a
              href="/auth/login"
              className={currentUrl === '/auth/login' ? 'active' : ''}
            >
              Login
            </a>
          </div>
        )}
        <div className="footer">
          <div className="content">
            <div className="links">
              <div className='image-container'>
                <img src={Image1} />
              </div>
              <div className='image-container'>
                <img src={Image2} />
              </div>
            </div>
            <div className="contact">
              Contact us:{' '}
              <a href="mailto:tabadol@makesense.org">tabadol@makesense.org</a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="black-background"
        onClick={() => {
          setActiveLink(null);
          handleSidebarToggle();
        }}
      />
    </SidebarContainer>
  );
};

export default Sidebar;
