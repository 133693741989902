import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import ToggleIcon from '../../../icons/ToggleIcon';
import SVG from '../../SVG';
import { useSettings } from '../../../hooks/useSettings';

const SelectInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  label {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  .input-container {
    display: flex;
    align-items: center;
    position: relative;

    select {
      border: none;
      outline: none;
      color: ${({ theme }) => theme.colors.secondary};
      width: 100%;
      padding-right: 2rem;
      appearance: none;
      background: none;
      cursor: pointer;
    }
    select::placeholder {
      color: ${({ placeholderColor }) => placeholderColor};
    }
    p {
      color: ${({ theme }) => theme.colors.border};
    }
  }
  .icon {
    z-index: -100;
    position: absolute;
    top: 60%;
    right: 0;
  }

  .line {
    width: 100%;
    height: 1px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    transform: translateY(-0.5rem);
    border-radius: 1px;
  }
`;

const SelectInput = ({
  label,
  disabled,
  name,
  options,
  value,
  placeholder,
  required,
  errorType,
  error,
}) => {
  const { register } = useFormContext();
  const selectRef = useRef(null);
  const { setIsFormEdited } = useSettings();

  // Function to open the select dropdown
  const showDropdown = (element) => {
    const event = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    element.dispatchEvent(event);
  };

  // Event handler for clicking the icon
  const handleClick = () => {
    const selectElement = selectRef.current.querySelector('select');
    showDropdown(selectElement);
  };

  return (
    <SelectInputContainer
      placeholderColor={errorType === 'placeholder' ? 'red' : ''}
    >
      <label htmlFor={name}>{label}</label>
      <div className="input-container" ref={selectRef}>
        {/* Select Input */}
        <select
          id={name}
          defaultValue={value}
          disabled={disabled}
          {...register(name, { required })}
          placeholder={errorType === 'placeholder' ? error : placeholder}
          onChange={() => setIsFormEdited(true)}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {/* Display error message if applicable */}
        {errorType === 'inline' && <p>{error}</p>}
      </div>
      <div className="icon" onClick={handleClick}>
        <SVG
          width={'12px'}
          height={'12px'}
          color={({ theme }) => theme.colors.darkerGrey}
          icon={<ToggleIcon />}
        />
      </div>
      {/* Line separator */}
      <div className="line" />
    </SelectInputContainer>
  );
};

export default SelectInput;
