import styled from '@emotion/styled';
import React from 'react';
import { countriesData } from '../../../utils/countriesData';
import SVG from '../../../common/SVG';
import ToggleIcon from '../../../icons/ToggleIcon';

const PreselectionDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  .item {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  div {
    display: flex;
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  b {
    margin-right: 0.4rem;
  }
  .phone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
    img {
      height: 15px;
      width: 15px;
      object-fit: cover;
    }
    p {
      position: relative;
      margin-left: 0.1rem;
    }
    p::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0.5px;
      background-color: ${({ theme }) => theme.colors.border};
    }
  }
`;

const PreselectionData = ({
  phone_number,
  nationality,
  pre_identified_CSO,
  multaqa_attendance,
  programs_and_grant_experience,
  country,
  email_address,
}) => {
  return (
    <PreselectionDataContainer>
      <div className="item">
        <div className="">
          <b>Phone Number:</b>
        </div>

        <div className="phone-container">
          {countriesData.map((item, index) => {
            if (item.name === country[0]) {
              return (
                <div key={index}>
                  <img src={item.flagImageUrl} />
                  <span>{item.phoneCode}</span>
                  <SVG
                    width={'10px'}
                    height={'10px'}
                    icon={<ToggleIcon />}
                    color={''}
                  />
                </div>
              );
            }
          })}
          <p>{phone_number}</p>
        </div>
      </div>
      <div className="item">
        <div>
          <b>Nationality:</b>
        </div>
        <div>{nationality}</div>
      </div>
      <div className="item">
        <div>
          <b>Pre-Identified CSO:</b>
        </div>
        <div>{pre_identified_CSO}</div>
      </div>
      <div className="item">
        <div>
          <b>Multaqa Attendance:</b>
        </div>
        <div>{multaqa_attendance ? 'Yes, Attended' : 'No, Did Not Attend'}</div>
      </div>
      <div className="item">
        <div>
          <b>Programs & Experience:</b>
        </div>
        <div>{programs_and_grant_experience}</div>
      </div>
      <div className="item">
        <div>
          <b>Email Address:</b>
        </div>
        <div>{email_address}</div>
      </div>
    </PreselectionDataContainer>
  );
};

export default PreselectionData;
