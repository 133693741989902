import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  user: {},
  isAuthenticated: false,
  loginLoading: false,
  errorLogin: '',
  errorSignUp: '',
  response: '',
  OTPResponse: '',
  OTPError: '',
  OrgLoading: false,
  FellowLoading: false,
  OTPLoading: false,
  InfoLoading: false,
};

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    loginRequest(state) {
      state.loginLoading = true;
      state.errorLogin = '';
      state.response = '';
      state.errorSignUp = '';
      state.OTPError = '';
    },
    loginSuccess(state, action) {
      state.loginLoading = false;
      state.response = action.payload.message;
      state.id = action.payload.id;
      state.errorLogin = '';
    },
    loginFail(state, action) {
      state.loginLoading = false;
      state.errorLogin = action.payload;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = {};
    },

    signupOrgRequest(state) {
      state.OrgLoading = true;
      state.errorSignUp = '';
      state.response = '';
      state.OTPError = '';
      state.errorLogin = '';
    },
    signupOrgSuccess(state, action) {
      state.OrgLoading = false;
      state.response = action.payload.message;
      state.id = action.payload.id;
      state.errorSignUp = '';
    },
    signupOrgFail(state, action) {
      state.OrgLoading = false;
      state.errorSignUp = action.payload;
      state.response = '';
    },
    signupFellowRequest(state) {
      state.FellowLoading = true;
      state.errorSignUp = '';
      state.response = '';
      state.OTPError = '';
      state.errorLogin = '';
    },
    signupFellowSuccess(state, action) {
      state.FellowLoading = false;
      state.response = action.payload.message;
      state.id = action.payload.id;
      state.errorSignUp = '';
    },
    signupFellowFail(state, action) {
      state.FellowLoading = false;
      state.errorSignUp = action.payload;
      state.response = '';
    },
    verifyRequest(state) {
      state.OTPLoading = true;
      state.OTPError = '';
      state.OTPResponse = '';
      state.errorLogin = '';
      state.errorSignUp = '';
    },

    verifySuccess(state, action) {
      state.OTPLoading = false;
      state.OTPResponse = action.payload;
      state.OTPError = '';
    },

    verifyFail(state, action) {
      state.OTPLoading = false;
      state.OTPError = action.payload;
      state.OTPResponse = '';
    },

    verifyLoginRequest(state, action) {
      state.isAuthenticated = false;
      state.user = {};
      state.OTPLoading = true;
      state.OTPResponse = '';
      state.OTPError = '';
      state.errorLogin = '';
      state.errorSignUp = '';
    },
    verifyLoginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.OTPLoading = false;
      state.OTPResponse = action.payload.message;
      state.OTPError = '';
    },

    verifyLoginFail(state, action) {
      state.isAuthenticated = false;
      state.user = {};
      state.OTPLoading = false;
      state.OTPResponse = '';
      state.OTPError = action.payload;
    },

    InfoRequest(state, action) {
      state.isAuthenticated = false;
      state.user = {};
      state.InfoLoading = true;
    },
    InfoSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.InfoLoading = false;
    },

    InfoFail(state, action) {
      state.isAuthenticated = false;
      state.user = {};
      state.InfoLoading = false;

      state.OTPError = action.payload;
    },
  },
});
export const authActions = userSlice.actions;
export default userSlice;
