import styled from '@emotion/styled';
import React from 'react';
import SVG from '../SVG';
import { useTheme } from '@emotion/react';
import CameraIcon from '../../icons/CameraIcon';

const ProfileCameraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ProfileCameraContainer = ({ text }) => {
  const theme = useTheme();
  return (
    <ProfileCameraWrapper>
      <SVG
        width={'40px'}
        height={'40px'}
        color={theme.colors.white}
        icon={<CameraIcon />}
      />
      <p>{text}</p>
    </ProfileCameraWrapper>
  );
};

export default ProfileCameraContainer;
